import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { UserBasicInfo } from '../../../custom_types/profile-page';
import { UI_ROUTES, USER_ROLES_TEXT } from '../../../shared/utils/constants';

const UsersTableColumns: any[] = [
  {
    title: '用户姓名',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: '公司',
    key: 'company',
    dataIndex: 'company'
  },
  {
    title: '邮箱',
    key: 'email',
    dataIndex: 'email'
  },
  {
    title: '联系手机',
    key: 'phone',
    dataIndex: 'phone'
  },
  {
    title: '用户权限',
    key: 'role',
    dataIndex: 'role',
    render: (role: string): string => {
      return USER_ROLES_TEXT[role];
    }
  },
  {
    title: '操作',
    key: 'operations',
    fixed: 'right',
    render: (text: string, record: UserBasicInfo): ReactElement => {
      return (
        <Link to={`${UI_ROUTES.USERS}${UI_ROUTES.EDIT}/${record.id}`}>
          编辑
        </Link>
      );
    }
  }
];

export default UsersTableColumns;
