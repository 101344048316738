import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { Checkbox, Form, Input, Modal, Result, Select, Space, Switch } from 'antd';
import { CreateWarehouseCarrierData, WarehouseCarrier } from '../../../../custom_types/wms-page';
import { selectCarriers } from '../../../../redux/carriersSlice';
import { Carrier, Facility, Service } from '../../../../custom_types/carrier-page';
import { CARRIERS } from '../../../../shared/utils/constants';
import {
  createWarehouseCarrierHandler,
  selectShowWarehouseCarrierModal,
  selectWarehouseCarrierModalLoading,
  setShowWarehouseCarrierModal,
  updateWarehouseCarrierHandler
} from '../../../../redux/warehouseCarrierSlice';

interface WarehouseCarrierModalProps {
  warehouseId: string;
  warehouseCarrier: WarehouseCarrier | undefined;
}

const { Option } = Select;

const WarehouseCarrierModal = ({ warehouseId, warehouseCarrier }: WarehouseCarrierModalProps) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const carriers = useSelector(selectCarriers);
  const loading = useSelector(selectWarehouseCarrierModalLoading);
  const [curCarrier, setCurCarrier] = useState<Carrier | undefined>(undefined);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [useThirdpary, setUseThirdparty] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const showModal = useSelector(selectShowWarehouseCarrierModal);

  useEffect(() => {
    if (warehouseCarrier && carriers) {
      const findCarrier = carriers.find((ele) => ele.id === warehouseCarrier.carrierRef);
      const carrierIndex = carriers.findIndex((ele) => ele.id === warehouseCarrier.carrierRef);
      setCurCarrier(findCarrier);
      setIsActive(warehouseCarrier.active);
      setIsDefault(warehouseCarrier.isDefault);
      setUseThirdparty(warehouseCarrier.thirdpartyPrice);

      const serviceIndex = [];
      for (let i = 0; i < warehouseCarrier.services.length; i += 1) {
        const service = warehouseCarrier.services[i];
        const index = findCarrier!.services.findIndex((ele) => ele.key === service.key);
        serviceIndex.push(index);
      }

      form.setFieldsValue({
        name: warehouseCarrier.name,
        description: warehouseCarrier.description.join(','),
        regions: warehouseCarrier.regions,
        connectedAccount: carrierIndex,
        services: serviceIndex
      });

      if (warehouseCarrier.carrier === CARRIERS.DHL_ECOM) {
        const facilityIndex = [];
        for (let i = 0; i < warehouseCarrier.facilities!.length; i += 1) {
          const facility = warehouseCarrier.facilities![i];
          const index = findCarrier!.facilities!.findIndex((ele) => ele.pickup === facility.pickup);
          facilityIndex.push(index);
        }

        form.setFieldsValue({
          facilities: facilityIndex
        });
      }
    } else {
      setCurCarrier(undefined);
      setIsActive(true);
      setIsDefault(false);
      setUseThirdparty(false);
    }
  }, [dispatch, carriers, warehouseCarrier, form]);

  const generatePlatformCarrierData = (carrier: Carrier, values: any) => {
    console.log('generate warehouse carrier data');
    console.log(values);
    const services = values.services.map((index: number) => carrier.services[index]);
    let facilities: Facility[] = [];
    if (carrier.carrierName === CARRIERS.DHL_ECOM) {
      facilities = values.facilities.map((index: number) => carrier.facilities![index]);
    }
    const data: CreateWarehouseCarrierData = {
      carrier: carrier.carrierName,
      name: values.name,
      description: values.description ? values.description.split(',') : [],
      active: true,
      isSystem: true,
      isDefault,
      refAccountName: carrier.accountName,
      services,
      facilities,
      regions: values.regions,
      thirdpartyPrice: useThirdpary,
      carrierRef: carrier.id,
      warehouseRef: warehouseId
    };

    return data;
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('OK Clicked Handler');
        if (curCarrier) {
          const data = generatePlatformCarrierData(curCarrier, values);
          console.log('created Data');
          console.log(data);
          dispatch(createWarehouseCarrierHandler(data));
          form.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        if (warehouseCarrier && curCarrier) {
          const data = generatePlatformCarrierData(curCarrier, values);
          const result: WarehouseCarrier = {
            id: warehouseCarrier.id,
            ...data,
            isDefault,
            thirdpartyPrice: useThirdpary,
            active: isActive
          };
          dispatch(updateWarehouseCarrierHandler(result));
          form.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const carrierSelectedHandler = (index: number) => {
    setCurCarrier(carriers[index]);
  };

  const closeModalHandler = () => {
    dispatch(setShowWarehouseCarrierModal(false));
    form.resetFields();
  };

  return (
    <Modal
      width={600}
      bodyStyle={{
        minHeight: '300px',
        maxHeight: '1000px',
        overflowY: 'auto',
        margin: '10px 50px'
      }}
      visible={showModal}
      centered
      closable={false}
      okText={warehouseCarrier ? '保存设置' : '添加设置'}
      cancelText="取消"
      title={warehouseCarrier ? '编辑物流设置' : '添加物流设置'}
      onCancel={closeModalHandler}
      onOk={warehouseCarrier ? updateClickedHandler : okClickedHandler}
      okButtonProps={{
        disabled: !(carriers && carriers.length > 0),
        loading
      }}
    >
      {carriers && carriers.length > 0 ? (
        <Form form={form} layout="vertical">
          <Form.Item
            label="账号名称"
            name="name"
            rules={[{ required: true, message: '账号名称必须填！' }]}
          >
            <Input placeholder="账号名称" disabled={!isActive} />
          </Form.Item>
          <Form.Item
            label="选择要关联的账号"
            name="connectedAccount"
            rules={[{ required: true, message: '请选择要关联的账号！' }]}
          >
            <Select placeholder="关联账号" onChange={carrierSelectedHandler} disabled={!isActive}>
              {carriers.map((item: Carrier, index: number) => {
                return (
                  <Option key={item.id} value={index}>
                    {item.accountName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {curCarrier && curCarrier.services && (
            <Form.Item
              label="授权服务"
              name="services"
              rules={[{ required: true, message: '至少选择一个服务！' }]}
            >
              <Select
                mode="multiple"
                placeholder="授权服务"
                disabled={!isActive}
                optionLabelProp="label"
              >
                {curCarrier.services.map((service: Service, index: number) => {
                  return (
                    <Option key={service.key} value={index} label={service.key}>
                      {service.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          {curCarrier && curCarrier.facilities && curCarrier.facilities.length > 0 && (
            <Form.Item
              label="操作中心"
              name="facilities"
              rules={[{ required: true, message: '至少选择一个操作中心！' }]}
            >
              <Select
                mode="multiple"
                placeholder="操作中心"
                disabled={!isActive}
                optionLabelProp="label"
              >
                {curCarrier.facilities.map((item: Facility, index: number) => {
                  return (
                    <Option key={item.facility} value={index} label={item.facility}>
                      {item.facility}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          {curCarrier && curCarrier.regions && curCarrier.regions.length > 0 && (
            <Form.Item
              label="服务范围"
              name="regions"
              rules={[{ required: true, message: '至少选择一个服务范围！' }]}
            >
              <Select
                mode="multiple"
                placeholder="服务范围"
                disabled={!isActive}
                optionLabelProp="label"
              >
                {curCarrier.regions.map((item: string) => {
                  return (
                    <Option key={item} value={item} label={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="说明" name="description">
            <Input.TextArea autoSize placeholder="账号相关备注" disabled={!isActive} />
          </Form.Item>
          <Space size="large">
            <Form.Item name="isDefault">
              <Checkbox
                checked={isDefault}
                onClick={() => setIsDefault(!isDefault)}
                disabled={!isActive}
              >
                默认使用
              </Checkbox>
            </Form.Item>
            <Form.Item name="thirdpartyPrice">
              <Checkbox
                checked={useThirdpary}
                onClick={() => setUseThirdparty(!useThirdpary)}
                disabled={!isActive}
              >
                三方价格
              </Checkbox>
            </Form.Item>
            {warehouseCarrier && (
              <Form.Item>
                <Switch
                  checkedChildren="启用"
                  unCheckedChildren="停用"
                  checked={isActive}
                  onClick={() => setIsActive(!isActive)}
                />
              </Form.Item>
            )}
          </Space>
        </Form>
      ) : (
        <Result status="warning" title="未找到可以关联的物流账号，请先创建物流账号" />
      )}
    </Modal>
  );
};

export default WarehouseCarrierModal;
