import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import ActionButton from 'antd/lib/modal/ActionButton';
import axios from '../shared/utils/axios.base';
import { Carrier, CarrierUpdateData } from '../custom_types/carrier-page';
import {
  AppThunk,
  PlatformCarriersState,
  RootState
} from '../custom_types/redux-types';
import errorHandler from '../shared/components/errorHandler';
import { BIZ_TYPES, SERVER_ROUTES } from '../shared/utils/constants';
import {
  PlatformCarrier,
  PlatformCarrierUpdate
} from '../custom_types/platform-carriers-tyes';

const initialState: PlatformCarriersState = {
  labelCarriers: [],
  loading: false,
  showModal: false,
  modalLoading: false
};

export const platformCarriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    setLabelCarriers: (state, action: PayloadAction<PlatformCarrier[]>) => {
      state.labelCarriers = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalLoading = action.payload;
    }
  }
});

export const {
  setLabelCarriers,
  setLoading,
  setShowModal,
  setModalLoading
} = platformCarriersSlice.actions;

export const fetchPlatformCarriersHandler = (bizType: number): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.PLATFORM_CARRIERS}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        },
        params: { bizType }
      })
      .then((response) => {
        const carriers = response.data;
        if (bizType === BIZ_TYPES.LABEL) {
          dispatch(setLabelCarriers(carriers));
        }
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }
};

export const createPlatformCarrierHandler = (
  data: PlatformCarrierUpdate
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    console.log('Send request to backend');
    dispatch(setModalLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.PLATFORM_CARRIERS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(setShowModal(false));
        dispatch(fetchPlatformCarriersHandler(data.bizType));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setModalLoading(false)));
  }
};

export const updatePlatformCarrierHandler = (
  data: PlatformCarrier
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.PLATFORM_CARRIERS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(setLoading(false));
        dispatch(setShowModal(false));
        dispatch(fetchPlatformCarriersHandler(data.bizType));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const deletePlatformCarrierHandler = (
  id: string,
  bizType: number
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.PLATFORM_CARRIERS}/${id}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(setLoading(false));
        dispatch(fetchPlatformCarriersHandler(bizType));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const selectLabelCarriers = (state: RootState): PlatformCarrier[] =>
  state.platformCarriers.labelCarriers;
export const selectPlatformCarriersLoading = (state: RootState): boolean =>
  state.platformCarriers.loading;
export const selectModalVisible = (state: RootState): boolean =>
  state.platformCarriers.showModal;
export const selectModalLoading = (state: RootState): boolean =>
  state.platformCarriers.modalLoading;

export default platformCarriersSlice.reducer;
