import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import axios from '../shared/utils/axios.base';
import { HwcPartnerWarehouseState } from '../custom_types/overseawarehouse/hwc-partner';
import {
  AppThunk,
  HwcPartnerWarehouse,
  HwcPartnerWarehouseData,
  RootState
} from '../custom_types/redux-types';
import { SERVER_ROUTES } from '../shared/utils/constants';
import errorHandler from '../shared/components/errorHandler';

const initialState: HwcPartnerWarehouseState = {
  allHwcParnerWarehouses: [],
  hwcPartnerWarehouse: [],
  loading: false,
  showModal: false,
  modalLoading: false,
  showEditModal: false
};

export const hwcPartnerWarehouseSlice = createSlice({
  name: 'hwcPartnerWarehouse',
  initialState,
  reducers: {
    setAllHwcPartnerWarehouses: (state, action: PayloadAction<HwcPartnerWarehouse[]>) => {
      state.allHwcParnerWarehouses = action.payload;
    },
    setHwcPartnerWarehouses: (state, action: PayloadAction<HwcPartnerWarehouse[]>) => {
      state.hwcPartnerWarehouse = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalLoading = action.payload;
    },
    setShowEditModal: (state, action: PayloadAction<boolean>) => {
      state.showEditModal = action.payload;
    }
  }
});

export const {
  setAllHwcPartnerWarehouses,
  setHwcPartnerWarehouses,
  setLoading,
  setShowModal,
  setModalLoading,
  setShowEditModal
} = hwcPartnerWarehouseSlice.actions;

export const fetchAllHwcPartnerWarehousesHandler = (): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PARTNER_WAREHOUSES}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => dispatch(setAllHwcPartnerWarehouses(response.data)))
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const fetchHwcPartnerWarehouseHandler = (hwcPartnerId: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PARTNER_WAREHOUSES}/${hwcPartnerId}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => dispatch(setHwcPartnerWarehouses(response.data)))
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const createHwcPartnerWarehouseHandler = (data: HwcPartnerWarehouseData): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setModalLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PARTNER_WAREHOUSES}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(setShowModal(false));
        dispatch(fetchHwcPartnerWarehouseHandler(data.hwcPartnerRef));
      })
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setModalLoading(false)));
  }
};

export const updateHwcPartnerWarehouseHandler = (
  id: string,
  data: HwcPartnerWarehouse
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setModalLoading(true));
    axios
      .put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PARTNER_WAREHOUSES}/${id}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(setShowModal(false));
        dispatch(fetchHwcPartnerWarehouseHandler(data.hwcPartnerRef));
      })
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setModalLoading(false)));
  }
};

export const deleteHwcPartnerWarehouseHandler = (hwcPartnerRef: string, id: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setModalLoading(true));
    axios
      .delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PARTNER_WAREHOUSES}/${id}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(setShowModal(false));
        dispatch(fetchHwcPartnerWarehouseHandler(hwcPartnerRef));
      })
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setModalLoading(false)));
  }
};

export const selectAllHwcPartnerWarehouses = (state: RootState): HwcPartnerWarehouse[] =>
  state.hwcPartnerWarehouses.allHwcParnerWarehouses;
export const selectHwcPartnerWarehouses = (state: RootState): HwcPartnerWarehouse[] =>
  state.hwcPartnerWarehouses.hwcPartnerWarehouse;
export const selectHwcPartnerWarehouseLodaing = (state: RootState): boolean =>
  state.hwcPartnerWarehouses.loading;
export const selectShowModal = (state: RootState): boolean => state.hwcPartnerWarehouses.showModal;
export const selectModalLoading = (state: RootState): boolean =>
  state.hwcPartnerWarehouses.modalLoading;
export const selectShowEditModal = (state: RootState): boolean =>
  state.hwcPartnerWarehouses.showEditModal;

export default hwcPartnerWarehouseSlice.reducer;
