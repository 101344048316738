import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import {
  LabelUserBill,
  LabelUserBillCreate,
  LabelUserBillData,
  LabelUserBillState
} from '../../custom_types/labels/label-user-bill-types';
import { AppThunk, RootState } from '../../custom_types/redux-types';
import errorHandler from '../../shared/components/errorHandler';
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import { fetchLabelUsersHandler } from './LabelUserSlice';

const initialState: LabelUserBillState = {
  labelUserBills: [],
  loading: false,
  showModal: false,
  modalLoading: false
};

export const labelUserBillSlice = createSlice({
  name: 'labelUserBills',
  initialState,
  reducers: {
    setLabelUserBills: (state, action: PayloadAction<LabelUserBill[]>) => {
      state.labelUserBills = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalLoading = action.payload;
    }
  }
});

export const {
  setLabelUserBills,
  setLoading,
  setShowModal,
  setModalLoading
} = labelUserBillSlice.actions;

export const fetchLabelUserBillsHandler = (userId: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USER_BILLS}/${userId}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response) => dispatch(setLabelUserBills(response.data)))
      .catch((error) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const createLabelUserBillHandler = (data: LabelUserBillCreate): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setModalLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USER_BILLS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(setModalLoading(false));
        dispatch(setShowModal(false));
        dispatch(fetchLabelUserBillsHandler(data.userId));
        dispatch(fetchLabelUsersHandler('1'));
      })
      .catch((error) => errorHandler(error, dispatch))
      .finally(() => {
        dispatch(setModalLoading(false));
        dispatch(setShowModal(false));
      });
  }
};

export const selectLabelUserBills = (state: RootState): LabelUserBill[] =>
  state.labelUserBills.labelUserBills;
export const selectLabelUserBillLodaing = (state: RootState): boolean =>
  state.labelUserBills.loading;
export const selectLabelUserBillModal = (state: RootState): boolean =>
  state.labelUserBills.showModal;
export const selectLabelUserBillModalLoading = (state: RootState): boolean =>
  state.labelUserBills.modalLoading;

export default labelUserBillSlice.reducer;
