import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import axios from '../../shared/utils/axios.base';
import { AppThunk, RootState } from '../../custom_types/redux-types';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import errorHandler from '../../shared/components/errorHandler';
import { LabelUserState } from '../../custom_types/labels/label-redux-types';
import {
  LabelUser,
  UserPasswordUpdate,
  UserRegisterData
} from '../../custom_types/labels/label-profile-types';

const initialState: LabelUserState = {
  labelUsers: [],
  loading: false,
  showUserModal: false,
  showUpdateUserModal: false,
  showUpdatePasswordModal: false
};

export const labelUserSlice = createSlice({
  name: 'labelUser',
  initialState,
  reducers: {
    setLabelUsers: (state, action: PayloadAction<LabelUser[]>) => {
      state.labelUsers = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowUserModal: (state, action: PayloadAction<boolean>) => {
      state.showUserModal = action.payload;
    },
    setShowUpdateUserModal: (state, action: PayloadAction<boolean>) => {
      state.showUpdateUserModal = action.payload;
    },
    setShowUpdatePasswordModal: (state, action: PayloadAction<boolean>) => {
      state.showUpdatePasswordModal = action.payload;
    }
  }
});

export const {
  setLabelUsers,
  setLoading,
  setShowUserModal,
  setShowUpdateUserModal,
  setShowUpdatePasswordModal
} = labelUserSlice.actions;

export const fetchLabelUsersHandler = (warehouseId: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USERS}/${warehouseId}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response) => {
        const labelUsers = response.data;
        dispatch(setLabelUsers(labelUsers));
      })
      .catch((error) => {
        // TODO: handle password invalid error
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const updateLabelUserPassword = (data: UserPasswordUpdate): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USERS}/password`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(setShowUpdatePasswordModal(false));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const updateLabelUserHandler = (data: LabelUser): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USERS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(fetchLabelUsersHandler(data.warehouseRef));
        dispatch(setShowUserModal(false));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const createOverseaWarehouseUserHandler = (data: UserRegisterData): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USERS}/overseaWarehouseUser`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(fetchLabelUsersHandler(data.warehouseRef));
        dispatch(setShowUserModal(false));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const createLabelUserHandler = (data: UserRegisterData): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USERS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(fetchLabelUsersHandler(data.warehouseRef));
        dispatch(setShowUserModal(false));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const deleteLabelUserHandler = (id: string, warehouseRef: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USERS}/${id}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(fetchLabelUsersHandler(warehouseRef));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const selectLabelUsers = (state: RootState): LabelUser[] => state.labelUsers.labelUsers;
export const selectLoading = (state: RootState): boolean => state.labelUsers.loading;
export const selectShowUserModal = (state: RootState): boolean => state.labelUsers.showUserModal;
export const selectShowPassordModal = (state: RootState): boolean =>
  state.labelUsers.showUpdatePasswordModal;

export default labelUserSlice.reducer;
