import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Switch, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NoData from '../../../../shared/components/NoData';
import CreateWarehouseUserModal from './CreateWarehouseUserModal';
import {
  deleteLabelUserHandler,
  fetchLabelUsersHandler,
  selectLabelUsers,
  selectLoading,
  selectShowPassordModal,
  selectShowUserModal,
  setShowUpdatePasswordModal,
  setShowUserModal,
  updateLabelUserHandler
} from '../../../../redux/Labels/LabelUserSlice';
import { LabelUser } from '../../../../custom_types/labels/label-profile-types';
import UpdateWarehouseUserPasswordModal from './UpdateWarehouseUserPasswordModal';

interface WarehouseUserPanelProps {
  warehouseId: string;
  isOverseaWarehouse: boolean;
}

const WarehouseUserPanel = ({ warehouseId, isOverseaWarehouse }: WarehouseUserPanelProps) => {
  const dispatch = useDispatch();
  const warehouseUsers = useSelector(selectLabelUsers);
  const showCreateUserModal = useSelector(selectShowUserModal);
  const showPasswordModal = useSelector(selectShowPassordModal);
  const loading = useSelector(selectLoading);
  const [curUser, setCurUser] = useState<LabelUser | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchLabelUsersHandler(warehouseId));
  }, [dispatch, warehouseId]);

  const addUserClickedHandler = () => {
    setCurUser(undefined);
    dispatch(setShowUserModal(true));
  };

  const editUserClickedHandler = (record: LabelUser) => {
    setCurUser(record);
    dispatch(setShowUserModal(true));
  };

  const editPasswordClickedHandler = (record: LabelUser) => {
    setCurUser(record);
    dispatch(setShowUpdatePasswordModal(true));
  };

  const statusChangeHandler = async (active: boolean, value: LabelUser) => {
    const data = { ...value, active };
    dispatch(updateLabelUserHandler(data));
  };

  const columns: any[] = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: '电话',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: '状态',
      dataIndex: 'active',
      key: 'active',
      render: (text: string, record: LabelUser) => (
        <Switch
          checkedChildren="启用"
          unCheckedChildren="禁用"
          checked={record.active}
          onChange={(checked) => statusChangeHandler(checked, record)}
        />
      )
    },
    {
      title: '操作',
      key: 'action',
      render: (text: string, record: LabelUser) => (
        <Space size="middle">
          <Button type="link" onClick={() => editUserClickedHandler(record)}>
            编辑
          </Button>
          <Button type="link" onClick={() => editPasswordClickedHandler(record)}>
            密码
          </Button>
          <Popconfirm
            title="确定删除该用户？"
            onConfirm={() => dispatch(deleteLabelUserHandler(record.id, record.warehouseRef))}
            cancelText="取消"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div>
      {showCreateUserModal && (
        <CreateWarehouseUserModal
          warehouseId={warehouseId}
          warehouseUser={curUser}
          isOverseaWarehouse={isOverseaWarehouse}
        />
      )}
      {showPasswordModal && curUser && <UpdateWarehouseUserPasswordModal warehouseUser={curUser} />}
      <PageHeader
        title=""
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() => dispatch(fetchLabelUsersHandler(warehouseId))}
          />,
          <Button key="1" type="primary" icon={<PlusOutlined />} onClick={addUserClickedHandler}>
            {isOverseaWarehouse ? '添加用户' : '添加仓库用户'}
          </Button>
        ]}
      />
      <Table<LabelUser>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={warehouseUsers}
        pagination={false}
        loading={loading}
        locale={{
          emptyText: <NoData />
        }}
        scroll={{ x: 1000 }}
      />
    </div>
  );
};

export default WarehouseUserPanel;
