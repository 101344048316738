import React, { ReactElement, useEffect } from 'react';
import { Modal, Form, Input, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectWarehouseLoading,
  setEditWarehouseModal,
  updateWarehouseHandler
} from '../../../../redux/warehouseSlice';
import { Warehouse } from '../../../../custom_types/wms-page';

interface EditWarehouseModalProps {
  record: Warehouse;
  onCancel: () => void;
}

const { Option } = Select;

const EditWarehouseModal = ({ record, onCancel }: EditWarehouseModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(selectWarehouseLoading);
  const [isActive, setIsActive] = React.useState<boolean>(true);
  const [selectedCountry, setSelectedCountry] = React.useState<string>('CN');

  useEffect(() => {
    setIsActive(record.active);
    form.setFieldsValue({
      name: record.name,
      company: record.company,
      phone: record.phone,
      email: record.email,
      address1: record.address1,
      address2: record.address2,
      city: record.city,
      state: record.state,
      country: record.country,
      zip: record.zip
    });
  }, [form, record]);

  const okClickedHander = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        const data = {
          ...values,
          country: selectedCountry,
          active: isActive
        };
        dispatch(updateWarehouseHandler(record.id, data));
        dispatch(setEditWarehouseModal(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeModalHandler = () => {
    onCancel();
  };

  const countryChangeHandler = (value: string) => {
    setSelectedCountry(value);
  };

  return (
    <Modal
      width={600}
      bodyStyle={{
        minHeight: '300px',
        maxHeight: '1000px',
        overflowY: 'auto',
        margin: '10px 50px'
      }}
      visible
      centered
      closable={false}
      title="Create Warehouse"
      onOk={okClickedHander}
      onCancel={closeModalHandler}
      okButtonProps={{
        loading
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input warehouse name!' }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item label="Company" name="company">
          <Input placeholder="Company" />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input placeholder="phone" />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Address1"
          name="address1"
          rules={[{ required: true, message: 'Please input address1!' }]}
        >
          <Input placeholder="Address1" />
        </Form.Item>
        <Form.Item label="Address2" name="address2">
          <Input placeholder="Address2" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[{ required: true, message: 'Please input warehouse city!' }]}
        >
          <Input placeholder="City" />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: true, message: 'Please input warehouse state!' }]}
        >
          <Input placeholder="State" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="country"
          rules={[{ required: true, message: 'Please input warehouse country!' }]}
        >
          <Select placeholder="Country" optionLabelProp="label" onChange={countryChangeHandler}>
            <Option key="CN" value="CN" label="China">
              <div className="demo-option-label-item">
                <span role="img" aria-label="China">
                  🇨🇳
                </span>
                China (中国)
              </div>
            </Option>
            <Option key="US" value="US" label="United States">
              <div className="demo-option-label-item">
                <span role="img" aria-label="United States">
                  🇺🇸
                </span>
                United States (美国)
              </div>
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Zip"
          name="zip"
          rules={[{ required: true, message: 'Please input warehouse zip!' }]}
        >
          <Input placeholder="Zip" />
        </Form.Item>
        <Form.Item>
          <Switch
            checkedChildren="启用"
            unCheckedChildren="停用"
            checked={isActive}
            onClick={() => setIsActive(!isActive)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditWarehouseModal;
