import { Divider, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import { HWC_PARTNER_OPERATIONS } from '../../../../shared/utils/wms/wms.constants';
import { selectHwcPartners } from '../../../../redux/hwcPartnerSlice';
import { HwcPartner } from '../../../../custom_types/overseawarehouse/hwc-partner';
import HwcPartnerHeader from '../components/HwcPartnerHeader';
import HwcPartnerWarehousePanel from '../components/HwcPartnerWarehousePanel';

const { TabPane } = Tabs;

const HwcPartnerDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const hwcPartnerList = useSelector(selectHwcPartners);
  const [selectedHwcPartner, setSelectedHwcPartner] = React.useState<HwcPartner | undefined>(
    undefined
  );

  useEffect(() => {
    const curHwcPartner = hwcPartnerList.find((ele: HwcPartner) => ele.id === id);
    if (curHwcPartner) {
      setSelectedHwcPartner(curHwcPartner);
    } else {
      history.push(`${UI_ROUTES.WMS}${UI_ROUTES.HWC_PARTNERS}`);
    }
  }, [history, id, hwcPartnerList, dispatch]);

  return (
    <>
      <HwcPartnerHeader
        hwcPartner={selectedHwcPartner}
        footer={
          <>
            <Divider />
            {selectedHwcPartner && (
              <Tabs defaultActiveKey={HWC_PARTNER_OPERATIONS.WAREHOUSE}>
                <TabPane
                  tab={HWC_PARTNER_OPERATIONS.WAREHOUSE}
                  key={HWC_PARTNER_OPERATIONS.WAREHOUSE}
                >
                  <HwcPartnerWarehousePanel hwcPartnerId={selectedHwcPartner.id} />
                </TabPane>
              </Tabs>
            )}
          </>
        }
      />
    </>
  );
};

export default HwcPartnerDetailPage;
