import { PageHeader } from '@ant-design/pro-components';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Tag } from 'antd';
import { HwcClientWarehouse } from '../../../../custom_types/redux-types';
import { UI_ROUTES } from '../../../../shared/utils/constants';

interface HwcClientWarehouseDetailHeaderProps {
  hwcClientWarehouse: HwcClientWarehouse | undefined;
  overseaWarehouseName: string;
  footer: ReactElement;
}

const HwcClientWarehouseDetailHeader = ({
  hwcClientWarehouse,
  overseaWarehouseName,
  footer
}: HwcClientWarehouseDetailHeaderProps) => {
  const history = useHistory();
  return (
    <PageHeader
      title={`仓库${hwcClientWarehouse?.code || ''} (${overseaWarehouseName})`}
      tags={
        hwcClientWarehouse && (
          <Tag color={hwcClientWarehouse.active ? 'blue' : 'default'}>
            {hwcClientWarehouse.active ? '启用' : '停用'}
          </Tag>
        )
      }
      footer={footer}
      onBack={() =>
        history.push(
          `${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES}/${hwcClientWarehouse!.hwcClientRef}`
        )
      }
    />
  );
};

export default HwcClientWarehouseDetailHeader;
