import { SyncOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { Button, Table, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { HwcSyncFromPartnerRecord } from '../../../../custom_types/overseawarehouse/hwc-stock';
import {
  fetchStockSyncRecordsHandler,
  selectHwcStockSyncLoading,
  selectHwcStockSyncRecords
} from '../../../../redux/hwc/hwcStockSyncSlice';

interface HwcStockSyncRecordsTabProps {
  hwcWarehouseId: string;
}

const HwcStockSyncRecordsTab = ({ hwcWarehouseId }: HwcStockSyncRecordsTabProps) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectHwcStockSyncLoading);
  const records = useSelector(selectHwcStockSyncRecords);

  useEffect(() => {
    dispatch(fetchStockSyncRecordsHandler(hwcWarehouseId));
  }, [dispatch, hwcWarehouseId]);

  const columns = [
    {
      title: '日期',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (date: Date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      title: '任务名称',
      key: 'jobName',
      dataIndex: 'jobName'
    },
    {
      title: '任务类型',
      key: 'recordType',
      dataIndex: 'recordType'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (status: number) => {
        return status === 0 ? <Tag color="green">成功</Tag> : <Tag color="red">失败</Tag>;
      }
    },
    {
      title: '任务用时',
      key: 'processTime',
      dataIndex: 'processTime',
      render: (time: number) => {
        return `${time}秒`;
      }
    },
    {
      title: '任务信息',
      key: 'message',
      dataIndex: 'message'
    }
  ];

  return (
    <div>
      <PageHeader
        title=""
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() => dispatch(fetchStockSyncRecordsHandler(hwcWarehouseId))}
          />
        ]}
      />
      <Table<HwcSyncFromPartnerRecord>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={records}
        loading={loading}
      />
    </div>
  );
};

export default HwcStockSyncRecordsTab;
