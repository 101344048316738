import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NoData from '../../../../shared/components/NoData';
import {
  fetchHwcPartnerHanlder,
  selectHwcPartners,
  selectLoading,
  selectShowCreateHwcPartnerModal,
  selectShowUpdateHwcPartnerModal,
  setShowCreateHwcPartnerModal,
  setShowUpdateHwcPartnerModal
} from '../../../../redux/hwcPartnerSlice';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import { HwcPartner } from '../../../../custom_types/overseawarehouse/hwc-partner';
import CreateHwcPartnerModal from '../components/CreateHwcPartnerModal';
import EditHwcPartnerModal from '../components/EditHwcPartnerModal';

const HwcPartnerPage = (): ReactElement => {
  const dispatch = useDispatch();
  const hwcPartnerList = useSelector(selectHwcPartners);
  const loading = useSelector(selectLoading);
  const showCreateModal = useSelector(selectShowCreateHwcPartnerModal);
  const showEditModal = useSelector(selectShowUpdateHwcPartnerModal);
  const [selectedHwcPartner, setSelectedHwcPartner] = React.useState<HwcPartner | undefined>();

  useEffect(() => {
    dispatch(fetchHwcPartnerHanlder());
  }, [dispatch]);

  const handleEditHwcPartnerClicked = (record: HwcPartner) => {
    dispatch(setShowUpdateHwcPartnerModal(true));
    setSelectedHwcPartner(record);
  };

  const handleEditHwcPartnerModalClose = () => {
    dispatch(setShowUpdateHwcPartnerModal(false));
    setSelectedHwcPartner(undefined);
  };

  return (
    <div>
      {showCreateModal && <CreateHwcPartnerModal />}
      {showEditModal && selectedHwcPartner && (
        <EditHwcPartnerModal
          record={selectedHwcPartner}
          onCancel={handleEditHwcPartnerModalClose}
        />
      )}
      <PageHeader
        title="合作海外仓"
        subTitle="管理合作海外仓"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(setShowCreateHwcPartnerModal(true))}
          >
            添加合作海外仓
          </Button>
        ]}
      />
      <Divider />
      <Table<HwcPartner>
        rowKey={(record) => record.id}
        columns={[
          {
            title: '海外仓名称',
            dataIndex: 'name',
            key: 'name'
          },
          {
            title: '海外仓代码',
            dataIndex: 'code',
            key: 'code'
          },
          {
            title: '用户名',
            dataIndex: 'account',
            key: 'account'
          },
          {
            title: '秘钥',
            dataIndex: 'token',
            key: 'token'
          },
          {
            title: '操作',
            key: 'operations',
            fixed: 'right',
            render: (text: string, record: HwcPartner): ReactElement => {
              return (
                <>
                  <Link to={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_PARTNERS}/${record.id}`}>详情</Link>
                  <span> | </span>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => handleEditHwcPartnerClicked(record)}
                  >
                    编辑
                  </Button>
                </>
              );
            }
          }
        ]}
        dataSource={hwcPartnerList}
        loading={loading}
        locale={{
          emptyText: <NoData />
        }}
      />
    </div>
  );
};

export default HwcPartnerPage;
