import { Layout } from 'antd';
import React, { ReactElement } from 'react';

const { Footer } = Layout;

const FooterComponent = (): ReactElement => {
  return (
    <Footer style={{ textAlign: 'center' }}>
      ParcelsPro ©{new Date().getFullYear()} Created by Eksborder Inc
    </Footer>
  );
};

export default FooterComponent;
