import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AppThunk, RootState, ScheudleJobState } from '../custom_types/redux-types';
import { ScheduleJob, ScheduleJobSearchQuery } from '../custom_types/scheduleJob';
import axios from '../shared/utils/axios.base';
import { SERVER_ROUTES } from '../shared/utils/constants';

const initialState: ScheudleJobState = {
  loading: false,
  showSchecudleJobModal: false,
  showMabangPullLabelOrdersScheduleJobModal: false,
  showMabangPullForecastOrdersScheduleJobModal: false,
  scheduleJobs: [],
  maBangPullLabelOrdersJobs: [],
  maBangPullForecastOrdersJobs: []
};

const scheduleJobSlice = createSlice({
  name: 'scheduleJob',
  initialState,
  reducers: {
    setScheduleJob: (state, action) => {
      state.scheduleJobs = action.payload;
    },
    setMaBangPullLabelOrdersJobs: (state, action) => {
      state.maBangPullLabelOrdersJobs = action.payload;
    },
    setMaBangPullForecastOrdersJobs: (state, action) => {
      state.maBangPullForecastOrdersJobs = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowSchecudleJobModal: (state, action) => {
      state.showSchecudleJobModal = action.payload;
    },
    setShowMabangPullLabelOrdersScheduleJobModal: (state, action) => {
      state.showMabangPullLabelOrdersScheduleJobModal = action.payload;
    },
    setShowMabangForcecastOrdersScheduleJobModal: (state, action) => {
      state.showMabangPullForecastOrdersScheduleJobModal = action.payload;
    }
  }
});

export const {
  setScheduleJob,
  setLoading,
  setShowSchecudleJobModal,
  setShowMabangPullLabelOrdersScheduleJobModal,
  setShowMabangForcecastOrdersScheduleJobModal,
  setMaBangPullLabelOrdersJobs,
  setMaBangPullForecastOrdersJobs
} = scheduleJobSlice.actions;

// Search schedule job handler
export const searchSechduleJobHandler = (data: ScheduleJobSearchQuery): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.SCHEDULE_JOBS}/search`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        const jobType = data.jobType;
        if (jobType === 'labelOrderPull_mabang') {
          dispatch(setMaBangPullLabelOrdersJobs(response.data));
        } else if (jobType === 'forecastOrderPull_mabang') {
          dispatch(setMaBangPullForecastOrdersJobs(response.data));
        } else {
          dispatch(setScheduleJob(response.data));
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

// Create schedule job handler
export const createScheduleJobHandler = (
  data: ScheduleJob,
  searchQuery: ScheduleJobSearchQuery
): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    data.createBy = user.name;
    data.updatedBy = user.name;
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.SCHEDULE_JOBS}/create`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(searchSechduleJobHandler(searchQuery));
        notification.success({
          message: 'Schedule job created',
          description: 'Schedule job created'
        });
      })
      .catch((error: any) => {
        console.log(error);
        notification.error({
          message: `[Schedule Job]: ${data.jobName} 添加失败`,
          description: `${error}`
        });
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setShowSchecudleJobModal(false));
        dispatch(setShowMabangPullLabelOrdersScheduleJobModal(false));
        dispatch(setShowMabangForcecastOrdersScheduleJobModal(false));
      });
  }
};

// Update schedule job handler
export const updateScheduleJobHandler = (
  data: ScheduleJob,
  searchQuery: ScheduleJobSearchQuery
): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.SCHEDULE_JOBS}/update`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(searchSechduleJobHandler(searchQuery));
        notification.success({
          message: 'Schedule job updated',
          description: 'Schedule job updated'
        });
      })
      .catch((error: any) => {
        console.log(error);
        notification.error({
          message: `[Schedule Job]: ${data.jobName} 更新失败`,
          description: `${error}`
        });
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

// Start schedule job handler
export const startScheduleJobHandler = (
  jobName: string,
  searchQuery: ScheduleJobSearchQuery
): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.SCHEDULE_JOBS}/start/${jobName}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(searchSechduleJobHandler(searchQuery));
        notification.success({
          message: `[Schedule Job]: ${jobName} 启动成功`,
          description: `[Schedule Job]: ${jobName} 启动成功`
        });
      })
      .catch((error: any) => {
        console.log(error);
        notification.error({
          message: `[Schedule Job]: ${jobName} 启动失败`,
          description: `${error}`
        });
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

// Stop schedule job handler
export const stopScheduleJobHandler = (
  jobName: string,
  searchQuery: ScheduleJobSearchQuery
): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.SCHEDULE_JOBS}/stop/${jobName}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(searchSechduleJobHandler(searchQuery));
        notification.success({
          message: `[Schedule Job]: ${jobName} 停止成功`,
          description: `[Schedule Job]: ${jobName} 停止成功`
        });
      })
      .catch((error: any) => {
        console.log(error);
        notification.error({
          message: `[Schedule Job]: ${jobName} 停止失败`,
          description: `${error}`
        });
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

// Restart schedule job handler
export const restartScheduleJobHandler = (
  jobName: string,
  searchQuery: ScheduleJobSearchQuery
): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.SCHEDULE_JOBS}/restart/${jobName}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(searchSechduleJobHandler(searchQuery));
        notification.success({
          message: `[Schedule Job]: ${jobName} 重启成功`,
          description: `[Schedule Job]: ${jobName} 重启成功`
        });
      })
      .catch((error: any) => {
        console.log(error);
        notification.error({
          message: `[Schedule Job]: ${jobName} 重启失败`,
          description: `${error}`
        });
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

// Delete schedule job handler
export const deleteScheduleJobHandler = (
  jobName: string,
  searchQuery: ScheduleJobSearchQuery
): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.SCHEDULE_JOBS}/delete/${jobName}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(searchSechduleJobHandler(searchQuery));
        notification.success({
          message: `[Schedule Job]: ${jobName} 删除成功`,
          description: `[Schedule Job]: ${jobName} 删除成功`
        });
      })
      .catch((error: any) => {
        console.log(error);
        notification.error({
          message: `[Schedule Job]: ${jobName} 删除失败`,
          description: `${error}`
        });
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const selectSecduleJob = (state: RootState) => state.scheduleJob.scheduleJobs;
export const selectMaBangPullLabelOrdersJobs = (state: RootState) =>
  state.scheduleJob.maBangPullLabelOrdersJobs;
export const selectMaBangPullForecastOrdersJobs = (state: RootState) =>
  state.scheduleJob.maBangPullForecastOrdersJobs;
export const selectSecduleJobLoading = (state: RootState) => state.scheduleJob.loading;
export const selectShowSchecudleJobModal = (state: RootState) =>
  state.scheduleJob.showSchecudleJobModal;
export const selectShowMabangPullLabelOrdersScheduleJobModal = (state: RootState) =>
  state.scheduleJob.showMabangPullLabelOrdersScheduleJobModal;
export const selectShowMabangPullForecastOrdersScheduleJobModal = (state: RootState) =>
  state.scheduleJob.showMabangPullForecastOrdersScheduleJobModal;

export default scheduleJobSlice.reducer;
