import { Layout, Menu } from 'antd';
import React, { ReactElement, useState } from 'react';

const { Sider, Header, Content } = Layout;

interface ProfilePageProps {
  panels: ReactElement[];
}

const LabelUsersProfilePanel = ({ panels }: ProfilePageProps): ReactElement => {
  const [panelUI, setPanelUI] = useState<ReactElement | null>(panels[0]);
  const [headerTitle, setHeaderTitle] = useState('基本信息');

  const showPanelByIndexHandler = (ind: number, title: string) => {
    setPanelUI(panels[ind]);
    setHeaderTitle(title);
  };

  return (
    <Layout style={{ minHeight: 360 }}>
      <Sider theme="light">
        <Menu
          style={{ height: '100%' }}
          defaultSelectedKeys={['0']}
          mode="inline"
        >
          <Menu.Item
            key="0"
            onClick={() => showPanelByIndexHandler(0, '基本信息')}
          >
            基本信息
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => showPanelByIndexHandler(1, '密码设置')}
          >
            密码设置
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="profile-header"
          style={{ background: 'white', fontSize: '20px', fontWeight: 500 }}
        >
          {headerTitle}
        </Header>
        <Content>
          <div
            className="profile-content"
            style={{
              background: 'white',
              padding: '26px 50px',
              minHeight: 360
            }}
          >
            {panelUI}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LabelUsersProfilePanel;
