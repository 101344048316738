import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AppThunk, HwcClientFulfillOrderState, RootState } from '../../custom_types/redux-types';
import {
  ClientFulfillOrderSearchQuery,
  HwcFulfillOrder
} from '../../custom_types/overseawarehouse/hwc-fulfill';
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import errorHandler from '../../shared/components/errorHandler';

const initialState: HwcClientFulfillOrderState = {
  hwcClientFulfillOrders: [],
  hwcClientFulfillOrderModalLoading: false
};

export const hwcClientFulfillOrderSlice = createSlice({
  name: 'hwcClientFulfillOrder',
  initialState,
  reducers: {
    setHwcClientFulfillOrders: (state, action: PayloadAction<HwcFulfillOrder[]>) => {
      state.hwcClientFulfillOrders = action.payload;
    },
    setHwcClientFulfillOrderModalLoading: (state, action: PayloadAction<boolean>) => {
      state.hwcClientFulfillOrderModalLoading = action.payload;
    }
  }
});

export const {
  setHwcClientFulfillOrders,
  setHwcClientFulfillOrderModalLoading
} = hwcClientFulfillOrderSlice.actions;

export const searchHwcClientFulfillOrdersHandler = (
  searchQuery: ClientFulfillOrderSearchQuery
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcClientFulfillOrderModalLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_FULFILL_ORDERS}/search`, searchQuery, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(setHwcClientFulfillOrders(response.data));
        notification.success({ message: '订单搜索成功', description: '订单搜索成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setHwcClientFulfillOrderModalLoading(false)));
  }
};

export const revertFulfillOrdersHandler = (
  hwcClientId: string,
  hwcClientWarehouseId: string,
  orderIds: string[],
  searchQuery: ClientFulfillOrderSearchQuery
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    console.log(orderIds);
    dispatch(setHwcClientFulfillOrderModalLoading(true));
    axios
      .post(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_FULFILL_ORDERS}/revert/${hwcClientId}/${hwcClientWarehouseId}`,
        orderIds,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        notification.success({ message: '订单复原完成', description: '订单复原完成' });
        dispatch(searchHwcClientFulfillOrdersHandler(searchQuery));
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setHwcClientFulfillOrderModalLoading(false)));
  }
};

export const deleteFulfillOrdersHandler = (
  hwcClientId: string,
  hwcClientWarehouseId: string,
  orderIds: string[],
  searchQuery: ClientFulfillOrderSearchQuery
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    console.log(orderIds);
    dispatch(setHwcClientFulfillOrderModalLoading(true));
    axios
      .post(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_FULFILL_ORDERS}/delete/${hwcClientId}/${hwcClientWarehouseId}`,
        orderIds,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        notification.success({ message: '订单删除完成', description: '订单删除完成' });
        dispatch(searchHwcClientFulfillOrdersHandler(searchQuery));
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setHwcClientFulfillOrderModalLoading(false)));
  }
};

export const pushMabangLableOrderHandler = (
  hwcClientId: string,
  hwcClientWarehouseId: string,
  successOrderIds: string[],
  searchQuery: ClientFulfillOrderSearchQuery
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcClientFulfillOrderModalLoading(true));
    axios
      .post(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.MA_BANG_ERP}/manual-push-success`,
        { hwcClientId, hwcClientWarehouseId, successOrderIds },
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        dispatch(searchHwcClientFulfillOrdersHandler(searchQuery));
        notification.success({ message: '订单推送成功', description: '订单推送成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setHwcClientFulfillOrderModalLoading(false)));
  }
};

export const pushMabangLabelOrderFailHandler = (
  hwcClientId: string,
  hwcClientWarehouseId: string,
  failedOrderIds: string[],
  searchQuery: ClientFulfillOrderSearchQuery
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcClientFulfillOrderModalLoading(true));
    axios
      .post(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.MA_BANG_ERP}/manual-push-fail`,
        { hwcClientId, hwcClientWarehouseId, failedOrderIds },
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        dispatch(searchHwcClientFulfillOrdersHandler(searchQuery));
        notification.success({ message: '订单报错成功', description: '订单报错成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setHwcClientFulfillOrderModalLoading(false)));
  }
};

// export const syncHwcClientFulfillOrdersHandler = (
//   hwcClientId: string,
//   hwcClientWarehouseId: string
// ): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
//   const user = getState().currentUser.currentUser;
//   if (user) {
//     dispatch(setHwcClientFulfillOrderModalLoading(true));
//     axios
//       .get(
//         `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_FULFILL_ORDERS}/sync/${hwcClientId}/${hwcClientWarehouseId}`,
//         {
//           headers: {
//             Authorization: `${user.token_type} ${user.token}`
//           }
//         }
//       )
//       .then((response: any) => {
//         dispatch(fetchHwcClientFulfillOrdersHandler(hwcClientId, hwcClientWarehouseId));
//         notification.success({ message: '订单同步成功', description: '订单同步成功' });
//       })
//       .catch((error: any) => errorHandler(error, dispatch))
//       .finally(() => dispatch(setHwcClientFulfillOrderModalLoading(false)));
//   }
// };

// export const fetchHwcClientFulfillOrdersHandler = (
//   hwcClientId: string,
//   hwcClientWarehouseId: string
// ): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
//   const user = getState().currentUser.currentUser;
//   if (user) {
//     dispatch(setHwcClientFulfillOrderModalLoading(true));
//     axios
//       .get(
//         `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_FULFILL_ORDERS}/fetch/${hwcClientId}/${hwcClientWarehouseId}`,
//         {
//           headers: {
//             Authorization: `${user.token_type} ${user.token}`
//           }
//         }
//       )
//       .then((response: any) => {
//         dispatch(setHwcClientFulfillOrders(response.data));
//         notification.success({ message: '订单获取成功', description: '订单获取成功' });
//       })
//       .catch((error: any) => errorHandler(error, dispatch))
//       .finally(() => dispatch(setHwcClientFulfillOrderModalLoading(false)));
//   }
// };

export const selectHwcClientFulfillOrders = (state: RootState) =>
  state.hwcClientFulfillOrders.hwcClientFulfillOrders;
export const selectHwcClientFulfillOrdersLoading = (state: RootState) =>
  state.hwcClientFulfillOrders.hwcClientFulfillOrderModalLoading;

export default hwcClientFulfillOrderSlice.reducer;
