import { Divider, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectWarehouses } from '../../../../redux/warehouseSlice';
import { Warehouse } from '../../../../custom_types/wms-page';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import WarehouseHeader from '../components/WarehouseHeader';
import { WAREHOUSE_DETAIL_OOPERATIONS } from '../../../../shared/utils/wms/wms.constants';
import InventoryPanel from '../components/InventoryPanel';
import WarehouseCarrierPanel from '../components/WarehouseCarrierPanel';
import { fetchCarriersHandler } from '../../../../redux/carriersSlice';
import WarehouseUserPanel from '../components/WarehouseUserPanel';

const { TabPane } = Tabs;

const WarehouseDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const warehouseList = useSelector(selectWarehouses);
  const [selectedWarehouse, setSelectedWarehouse] = React.useState<Warehouse | undefined>(
    undefined
  );

  useEffect(() => {
    const curWarehouse = warehouseList.find((ele) => ele.id === id);
    if (curWarehouse) {
      dispatch(fetchCarriersHandler());
      setSelectedWarehouse(curWarehouse);
    } else {
      history.push(`${UI_ROUTES.WMS}${UI_ROUTES.WAREHOUSES}`);
    }
  }, [history, id, warehouseList, dispatch]);

  return (
    <>
      <WarehouseHeader
        warehouse={selectedWarehouse}
        footer={
          <>
            <Divider />
            {selectedWarehouse && (
              <Tabs defaultActiveKey={WAREHOUSE_DETAIL_OOPERATIONS.INVENTROY}>
                <TabPane
                  tab={WAREHOUSE_DETAIL_OOPERATIONS.INVENTROY}
                  key={WAREHOUSE_DETAIL_OOPERATIONS.INVENTROY}
                >
                  <InventoryPanel warehouseId={selectedWarehouse.id} />
                </TabPane>
                <TabPane
                  tab={WAREHOUSE_DETAIL_OOPERATIONS.VENDORS}
                  key={WAREHOUSE_DETAIL_OOPERATIONS.VENDORS}
                >
                  <div>VENDORS</div>
                </TabPane>
                <TabPane
                  tab={WAREHOUSE_DETAIL_OOPERATIONS.ORDERS}
                  key={WAREHOUSE_DETAIL_OOPERATIONS.ORDERS}
                >
                  <div>ORDERS</div>
                </TabPane>
                <TabPane
                  tab={WAREHOUSE_DETAIL_OOPERATIONS.USERS}
                  key={WAREHOUSE_DETAIL_OOPERATIONS.USERS}
                >
                  <WarehouseUserPanel
                    warehouseId={selectedWarehouse.id}
                    isOverseaWarehouse={false}
                  />
                </TabPane>
                <TabPane
                  tab={WAREHOUSE_DETAIL_OOPERATIONS.CARRIERS}
                  key={WAREHOUSE_DETAIL_OOPERATIONS.CARRIERS}
                >
                  <WarehouseCarrierPanel warehouseId={selectedWarehouse.id} />
                </TabPane>
              </Tabs>
            )}
          </>
        }
      />
    </>
  );
};

export default WarehouseDetailPage;
