import { Layout } from 'antd';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { selectCurUser } from '../../../redux/user/userSlice';
import { UI_ROUTES, USER_ROLES } from '../../../shared/utils/constants';
import LoginPage from '../../Users/pages/LoginPage';
import ForgotPage from '../../Users/pages/ForgotPage';
import HeaderMenu from '../components/HeaderMenu';

import './LayoutPage.css';
import ResetPage from '../../Users/pages/ResetPage';
import SuperAdminPage from './SuperAdminPage';
import LabelManagementPage from '../../Labels/pages/LabelManagementPage';
// import WMSPage from '../../WMS/pages/WMSPage';
import CarriersPage from '../../Carriers/pages/CarriersPage';
import EditCarrierPage from '../../Carriers/pages/EditCarrierPage';
import UsersPage from '../../Users/pages/UsersPage';
import FooterComponent from '../../../shared/components/FooterComponent';
import UserManagePage from '../../Users/pages/UserManagePage';
import WMSPage from '../../WMS/pages/WMSPage';

const { Content } = Layout;

const LayoutPage = (): ReactElement => {
  const curUser = useSelector(selectCurUser);

  return (
    <>
      {curUser ? (
        <Layout style={{ minHeight: '100vh' }}>
          {curUser.role === USER_ROLES.SUPER_ADMIN && (
            <Switch>
              <Route path={UI_ROUTES.ADMIN} component={SuperAdminPage} />
              <Route path={UI_ROUTES.LABELS} component={LabelManagementPage} />
              <Route
                path={`${UI_ROUTES.CARRIERS}${UI_ROUTES.EDIT}/:carrierId`}
                component={EditCarrierPage}
              />
              <Route path={UI_ROUTES.CARRIERS} component={CarriersPage} />
              <Route
                path={`${UI_ROUTES.USERS}${UI_ROUTES.EDIT}/:userId`}
                component={UserManagePage}
              />
              <Route path={UI_ROUTES.USERS} component={UsersPage} />
              <Route path={UI_ROUTES.WMS} component={WMSPage} />
              <Redirect from="/" to={UI_ROUTES.ADMIN} />
            </Switch>
          )}
          {curUser.role === USER_ROLES.LABEL_ADMIN && (
            <Switch>
              <Route path={UI_ROUTES.LABELS} component={LabelManagementPage} />
              <Redirect from="/" to={UI_ROUTES.LABELS} />
            </Switch>
          )}
        </Layout>
      ) : (
        <Layout style={{ minHeight: '100vh' }}>
          <HeaderMenu title="Parcelspro" />
          <Content>
            <Switch>
              <Route path={UI_ROUTES.LOGIN} component={LoginPage} />
              <Route path={UI_ROUTES.FORGOT} component={ForgotPage} />
              <Route path={`${UI_ROUTES.RESET}/:token`} component={ResetPage} exact />
              <Redirect from="/" to={UI_ROUTES.LOGIN} />
            </Switch>
          </Content>
          <FooterComponent />
        </Layout>
      )}
    </>
  );
};

export default LayoutPage;
