import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HwcPartnerWarehouse } from '../../../../custom_types/redux-types';
import {
  deleteHwcPartnerWarehouseHandler,
  fetchHwcPartnerWarehouseHandler,
  selectHwcPartnerWarehouseLodaing,
  selectHwcPartnerWarehouses,
  setShowEditModal,
  setShowModal
} from '../../../../redux/hwcPartnerWarehouseSlice';
import HwcPartnerWarehouseCreateModal from './HwcPartnerWarehouseCreateModal';
import HwcPartnerWarehouseEditModal from './HwcPartnerWarehouseEditModal';

interface HwcPartnerWarehousePanelProps {
  hwcPartnerId: string;
}

const HwcPartnerWarehousePanel = ({
  hwcPartnerId
}: HwcPartnerWarehousePanelProps): ReactElement => {
  const dispatch = useDispatch();
  const warehouses = useSelector(selectHwcPartnerWarehouses);
  const loading = useSelector(selectHwcPartnerWarehouseLodaing);
  const [selectedHwcPartnerWarehouse, setSelectedHwcPartnerWarehouse] = React.useState<
    HwcPartnerWarehouse | undefined
  >();

  useEffect(() => {
    dispatch(fetchHwcPartnerWarehouseHandler(hwcPartnerId));
  }, [dispatch, hwcPartnerId]);

  const handleEditHwcPartnerWarehouseClicked = (record: HwcPartnerWarehouse) => {
    dispatch(setShowEditModal(true));
    setSelectedHwcPartnerWarehouse(record);
  };

  const handleEditHwcPartnerWarehouseCanceled = () => {
    dispatch(setShowEditModal(false));
    setSelectedHwcPartnerWarehouse(undefined);
  };

  const columns = [
    {
      title: '仓库名称',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '仓库代码',
      key: 'code',
      dataIndex: 'code'
    },
    {
      title: '自提渠道',
      key: 'ztqd',
      dataIndex: 'ztqd'
    },
    {
      title: '仓库地址',
      key: 'address',
      dataIndex: 'address',
      render: (_: any, record: HwcPartnerWarehouse): ReactElement => {
        return (
          <>
            <div>{record.contact}</div>
            {record.phone && <div>{record.phone}</div>}
            {record.email && <div>{record.email}</div>}
            <div>{record.address1}</div>
            {record.address2 && <div>{record.address2}</div>}
            <div>
              {record.city}, {record.state} {record.zip} {record.country}
            </div>
          </>
        );
      }
    },
    {
      title: '操作',
      key: 'operations',
      render: (text: string, record: HwcPartnerWarehouse): ReactElement => {
        return (
          <>
            <Button
              size="small"
              type="link"
              onClick={() => handleEditHwcPartnerWarehouseClicked(record)}
            >
              编辑
            </Button>
            <span> | </span>
            <Popconfirm
              key="确认删除该仓库"
              title="确认删除该仓库?"
              placement="topRight"
              onConfirm={() =>
                dispatch(deleteHwcPartnerWarehouseHandler(record.hwcPartnerRef, record.id))
              }
              okText="确定"
              cancelText="取消"
            >
              <Button key="删除" size="small" type="link">
                删除
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  return (
    <div>
      <HwcPartnerWarehouseCreateModal hwcPartnerId={hwcPartnerId} />
      <HwcPartnerWarehouseEditModal
        record={selectedHwcPartnerWarehouse}
        onCancel={handleEditHwcPartnerWarehouseCanceled}
      />
      <PageHeader
        title=""
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() => dispatch(fetchHwcPartnerWarehouseHandler(hwcPartnerId))}
          />,
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(setShowModal(true))}
          >
            添加仓库
          </Button>
        ]}
      />
      <Table<HwcPartnerWarehouse>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={warehouses}
        loading={loading}
      />
    </div>
  );
};

export default HwcPartnerWarehousePanel;
