import { Divider, Tabs } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { OverseaWarehouse } from '../../../../custom_types/wms-page';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import { HWC_CLIENT_OPERATIONS } from '../../../../shared/utils/wms/wms.constants';
import {
  createAPITokenHandler,
  deleteAPITokenHandler,
  selectOverseaWarehouses
} from '../../../../redux/overseaWarehouseSlice';
import OverseaWarehouseHeader from '../components/OverseaWarehouseHeader';
import WarehouseUserPanel from '../../Warehouses/components/WarehouseUserPanel';
import HwcClientAPITokenPanel from '../components/HwcClientAPITokenPanel';
import HwcClientBillPanel from '../components/HwcClientBillPanel';
import HwcClientWarehousePanel from '../components/HwcClientWarehousePanel';
import HwcFulfillOrders from '../components/HwcFulfillOrders/HwcFulfillOrders';
import {
  fetchHwcClientWarehouseHandler,
  selectHwcClientWarehouses
} from '../../../../redux/hwc/hwcClientWarehouseSlice';
import HwcProductPanel from '../components/HwcProducts/HwcProductPanel';
import HwcStocks from '../components/HwcStocks/HwcStocks';
import HwcTouCheng from '../components/HwcTouCheng/HwcTouCheng';

const { TabPane } = Tabs;

const OverseaWarehouseDetailPage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const overseaWarehouseList = useSelector(selectOverseaWarehouses);
  const clientWarehouses = useSelector(selectHwcClientWarehouses);
  const [selectedOverseaWarehouse, setSelectedOverseaWarehouse] = React.useState<
    OverseaWarehouse | undefined
  >(undefined);

  useEffect(() => {
    const curOverseaWarehouse = overseaWarehouseList.find((ele: OverseaWarehouse) => ele.id === id);
    if (curOverseaWarehouse) {
      setSelectedOverseaWarehouse(curOverseaWarehouse);
      dispatch(fetchHwcClientWarehouseHandler(curOverseaWarehouse.id));
    } else {
      history.push(`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES}`);
    }
  }, [history, id, overseaWarehouseList, dispatch]);

  const createApiToken = async () => {
    dispatch(createAPITokenHandler(id));
  };

  const deleteApiToken = async () => {
    dispatch(deleteAPITokenHandler(id));
  };

  return (
    <>
      <OverseaWarehouseHeader
        overseaWarehouse={selectedOverseaWarehouse}
        footer={
          <>
            <Divider />
            {selectedOverseaWarehouse && (
              <Tabs defaultActiveKey={HWC_CLIENT_OPERATIONS.WAREHOUSES}>
                <TabPane
                  tab={HWC_CLIENT_OPERATIONS.WAREHOUSES}
                  key={HWC_CLIENT_OPERATIONS.WAREHOUSES}
                >
                  <HwcClientWarehousePanel
                    hwcClientId={selectedOverseaWarehouse.id}
                    hwcClientName={selectedOverseaWarehouse.name}
                  />
                </TabPane>
                <TabPane
                  tab={HWC_CLIENT_OPERATIONS.FULFILL}
                  key={HWC_CLIENT_OPERATIONS.FULFILL}
                  disabled={!clientWarehouses || clientWarehouses.length === 0}
                >
                  <HwcFulfillOrders hwcClient={selectedOverseaWarehouse} />
                </TabPane>
                <TabPane
                  tab={HWC_CLIENT_OPERATIONS.PRODUCTS}
                  key={HWC_CLIENT_OPERATIONS.PRODUCTS}
                  disabled={!clientWarehouses || clientWarehouses.length === 0}
                >
                  <HwcProductPanel hwcClientId={selectedOverseaWarehouse.id} />
                </TabPane>
                <TabPane
                  tab={HWC_CLIENT_OPERATIONS.TOU_CHENG}
                  key={HWC_CLIENT_OPERATIONS.TOU_CHENG}
                  disabled={!clientWarehouses || clientWarehouses.length === 0}
                >
                  <HwcTouCheng hwcClient={selectedOverseaWarehouse} />
                </TabPane>
                <TabPane
                  tab={HWC_CLIENT_OPERATIONS.STOCK}
                  key={HWC_CLIENT_OPERATIONS.STOCK}
                  disabled={!clientWarehouses || clientWarehouses.length === 0}
                >
                  <HwcStocks hwcClient={selectedOverseaWarehouse} />
                </TabPane>
                <TabPane tab={HWC_CLIENT_OPERATIONS.BILLS} key={HWC_CLIENT_OPERATIONS.BILLS}>
                  <HwcClientBillPanel overseaWarehouseId={selectedOverseaWarehouse.id} />
                </TabPane>
                {/* <TabPane tab={HWC_CLIENT_OPERATIONS.CARRIERS} key={HWC_CLIENT_OPERATIONS.CARRIERS}>
                  <HwcClientCarrierPanel overseaWarehouseId={selectedOverseaWarehouse.id} />
                </TabPane> */}
                <TabPane tab={HWC_CLIENT_OPERATIONS.APIKEYS} key={HWC_CLIENT_OPERATIONS.APIKEYS}>
                  <HwcClientAPITokenPanel
                    token={selectedOverseaWarehouse.apiToken || ''}
                    onCreate={createApiToken}
                    onDelete={deleteApiToken}
                  />
                </TabPane>
                <TabPane tab={HWC_CLIENT_OPERATIONS.USERS} key={HWC_CLIENT_OPERATIONS.USERS}>
                  <WarehouseUserPanel
                    warehouseId={selectedOverseaWarehouse.id}
                    isOverseaWarehouse
                  />
                </TabPane>
              </Tabs>
            )}
          </>
        }
      />
    </>
  );
};

export default OverseaWarehouseDetailPage;
