import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Tag, Row, Col, Descriptions } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { Warehouse } from '../../../../custom_types/wms-page';
import { UI_ROUTES } from '../../../../shared/utils/constants';

interface WarehouseHeaderProps {
  warehouse: Warehouse | undefined;
  footer: ReactElement;
}

const WarehouseHeader = ({ warehouse, footer }: WarehouseHeaderProps) => {
  const history = useHistory();
  return (
    <PageHeader
      title="仓库详情"
      tags={
        warehouse && (
          <Tag color={warehouse.active ? 'blue' : 'default'}>
            {warehouse.active ? '启用' : '停用'}
          </Tag>
        )
      }
      footer={footer}
      onBack={() => history.push(`${UI_ROUTES.WMS}${UI_ROUTES.WAREHOUSES}`)}
    >
      {warehouse && (
        <Row>
          <Col>
            <Descriptions size="small" column={3}>
              <Descriptions.Item label="仓库名称">{warehouse.name}</Descriptions.Item>
              {warehouse.company && (
                <Descriptions.Item label="公司名称">{warehouse.company}</Descriptions.Item>
              )}
              <Descriptions.Item label="仓库电话">{warehouse.phone || '-'}</Descriptions.Item>
              <Descriptions.Item label="仓库邮箱">{warehouse.email}</Descriptions.Item>
              <Descriptions.Item label="仓库地址">{`${warehouse.address1}, ${
                warehouse.address2 ? warehouse.address2 : ''
              } ${warehouse.city} ${warehouse.state} ${warehouse.country} ${
                warehouse.zip
              }`}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      )}
    </PageHeader>
  );
};

export default WarehouseHeader;
