import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import axios from '../shared/utils/axios.base';
import { Carrier, CarrierUpdateData } from '../custom_types/carrier-page';
import {
  AppThunk,
  CarriersState,
  RootState,
} from '../custom_types/redux-types';
import errorHandler from '../shared/components/errorHandler';
import { SERVER_ROUTES } from '../shared/utils/constants';

const initialState: CarriersState = {
  carriers: [],
  loading: false,
  carrierModalLoading: false,
  showCarrierModal: false,
  redirectToCarriersPage: false,
};

export const carriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    setCarriers: (state, action: PayloadAction<Carrier[]>) => {
      state.carriers = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCarrierModalLoading: (state, action: PayloadAction<boolean>) => {
      state.carrierModalLoading = action.payload;
    },
    setShowCarrierModal: (state, action: PayloadAction<boolean>) => {
      state.showCarrierModal = action.payload;
    },
    setRedirectToCarriersPage: (state, action: PayloadAction<boolean>) => {
      state.redirectToCarriersPage = action.payload;
    },
  },
});

export const {
  setCarriers,
  setLoading,
  setCarrierModalLoading,
  setShowCarrierModal,
  setRedirectToCarriersPage,
} = carriersSlice.actions;

export const fetchCarriersHandler = (): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.CARRIER}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`,
        },
      })
      .then((response) => {
        const carriers = response.data;
        dispatch(setCarriers(carriers));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }
};

export const createCarrierHandler = (data: CarrierUpdateData): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.CARRIER}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`,
        },
      })
      .then(() => {
        dispatch(setRedirectToCarriersPage(true));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const updateCarrierHandler = (data: Carrier): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.CARRIER}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`,
        },
      })
      .then(() => {
        dispatch(setLoading(false));
        dispatch(fetchCarriersHandler());
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const deleteCarrierHandler = (carrierId: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.CARRIER}/${carrierId}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`,
        },
      })
      .then(() => {
        dispatch(fetchCarriersHandler());
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const selectCarriers = (state: RootState): Carrier[] =>
  state.carriers.carriers;
export const selectCarriersLoading = (state: RootState): boolean =>
  state.carriers.loading;
export const selectCarrierModalLoading = (state: RootState): boolean =>
  state.carriers.carrierModalLoading;
export const selectShowCarrierModal = (state: RootState): boolean =>
  state.carriers.showCarrierModal;
export const selectRedirectToCarriersPage = (state: RootState): boolean =>
  state.carriers.redirectToCarriersPage;

export default carriersSlice.reducer;
