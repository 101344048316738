import React, { useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, Space, Tabs, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { HwcWarehouse } from '../../../../custom_types/redux-types';
import {
  manualSyncPartnerStocks,
  selectHwcStockSyncLoading
} from '../../../../redux/hwc/hwcStockSyncSlice';
import {
  createScheduleJobHandler,
  searchSechduleJobHandler,
  selectSecduleJob,
  selectSecduleJobLoading,
  selectShowSchecudleJobModal,
  setShowSchecudleJobModal
} from '../../../../redux/scheduleJobSlice';
import { ScheduleJob } from '../../../../custom_types/scheduleJob';
import HwcScheduleJobModal from './HwcScheduleJobModal';
import HwcScheduleJobElement from './HwcScheduleJobElement';
import HwcStockSyncRecordsTab from './HwcStockSyncRecordsTab';

interface HwcStockSyncPanelProps {
  hwcWarehouse: HwcWarehouse;
}

const HwcStockSyncPanel = ({ hwcWarehouse }: HwcStockSyncPanelProps) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectHwcStockSyncLoading);
  const scheduleJobs = useSelector(selectSecduleJob);
  const scheduleJobLoading = useSelector(selectSecduleJobLoading);
  const showSchecudleJobModal = useSelector(selectShowSchecudleJobModal);
  const [name, code, account] = hwcWarehouse.thirdPartyParnter.split('_');

  useEffect(() => {
    // Search HwcWarehouse Stock Sync Schedule Job
    dispatch(searchSechduleJobHandler({ hwcWarehouseId: hwcWarehouse.id, jobType: 'stocksync' }));
  }, [dispatch, hwcWarehouse.id]);

  const showCreateScheduleJobModal = () => {
    dispatch(setShowSchecudleJobModal(true));
  };

  const createStockSyncScheduleJob = (values: any) => {
    console.log(values);
    const data: ScheduleJob = {
      id: '',
      jobType: 'stocksync',
      autoStart: values.autoStart,
      jobName: `stocksync:${hwcWarehouse.code}:${values.jobName}`,
      jobHandler: '',
      params: { hwcWarehouseId: hwcWarehouse.id },
      description: `Auto Sotck Sync Job for ${hwcWarehouse.code}`,
      status: 0,
      createBy: '',
      updatedBy: '',
      createTime: dayjs().toDate(),
      updateTime: dayjs().toDate(),
      startTime: { hour: values['start-hour'], minute: values['start-minute'] },
      repeatTimes: values.repeatTimes,
      repeatInterval: {
        hour: values.repeatTimes === 1 ? 0 : values['repeat-hour'],
        minute: values.repeatTimes === 1 ? 0 : values['repeat-minute']
      },
      tz: values.tz,
      hwcWarehouseId: hwcWarehouse.id
    };
    dispatch(
      createScheduleJobHandler(data, { hwcWarehouseId: hwcWarehouse.id, jobType: 'stocksync' })
    );
  };

  const closeModalHandler = () => {
    dispatch(setShowSchecudleJobModal(false));
  };

  return (
    <div>
      {showSchecudleJobModal && (
        <HwcScheduleJobModal onOk={createStockSyncScheduleJob} onCancel={closeModalHandler} />
      )}
      <PageHeader
        title={`同步${name}(${code}), ${account}, ${hwcWarehouse.thirdPartyCode}的库存`}
      />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="库存同步任务" key="1">
          <>
            <Divider orientation="left">手动同步库存</Divider>
            <Space
              size="large"
              direction="horizontal"
              style={{ border: '1px solid #e8e8e8', padding: '20px', width: '100%' }}
            >
              <Button
                type="primary"
                loading={loading}
                onClick={() => dispatch(manualSyncPartnerStocks(hwcWarehouse.id))}
                icon={<SyncOutlined />}
              >
                开始同步库存
              </Button>
            </Space>
            <Divider orientation="left">自动同步库存设置</Divider>
            <Space
              size="large"
              direction="horizontal"
              style={{ border: '1px solid #e8e8e8', padding: '20px', width: '100%' }}
            >
              <Button
                type="primary"
                loading={scheduleJobLoading}
                onClick={showCreateScheduleJobModal}
                icon={<PlusOutlined />}
              >
                添加自动同步库存任务
              </Button>
            </Space>
            {scheduleJobs &&
              scheduleJobs.map((job) => (
                <HwcScheduleJobElement
                  job={job}
                  searchQuery={{ hwcWarehouseId: hwcWarehouse.id, jobType: 'stocksync' }}
                />
              ))}
          </>
        </Tabs.TabPane>
        <Tabs.TabPane tab="库存同步记录" key="2">
          <HwcStockSyncRecordsTab hwcWarehouseId={hwcWarehouse.id} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default HwcStockSyncPanel;
