import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import axios from '../../shared/utils/axios.base';
import {
  AppThunk,
  RootState,
  HwcClientWarehouseState,
  HwcClientWarehouse,
  HwcClientWarehouseData
} from '../../custom_types/redux-types';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import errorHandler from '../../shared/components/errorHandler';

const initialState: HwcClientWarehouseState = {
  hwcClientWarehouses: [],
  loading: false,
  showCreateHwcClientWarehouseModal: false,
  hwcClientWarehouseModalLoading: false,
  showEditHwcClientWarehouseModal: false,
  showHwcClientWarehouseFeeModal: false,
  hwcClientWarehouseFeeModalLoading: false
};

export const hwcClientWarehouseSlice = createSlice({
  name: 'hwcClientWarehouse',
  initialState,
  reducers: {
    setHwcClientWarehouses: (state, action: PayloadAction<HwcClientWarehouse[]>) => {
      state.hwcClientWarehouses = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowCreateHwcClientWarehouseModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateHwcClientWarehouseModal = action.payload;
    },
    setClientWarehouseModalLoading: (state, action: PayloadAction<boolean>) => {
      state.hwcClientWarehouseModalLoading = action.payload;
    },
    setShowEditHwcClientWarehouseModal: (state, action: PayloadAction<boolean>) => {
      state.showEditHwcClientWarehouseModal = action.payload;
    },
    setShowHwcClientWarehouseFeeModal: (state, action: PayloadAction<boolean>) => {
      state.showHwcClientWarehouseFeeModal = action.payload;
    },
    setHwcClientWarehouseFeeModalLoading: (state, action: PayloadAction<boolean>) => {
      state.hwcClientWarehouseFeeModalLoading = action.payload;
    }
  }
});

export const {
  setHwcClientWarehouses,
  setLoading,
  setShowCreateHwcClientWarehouseModal,
  setClientWarehouseModalLoading,
  setShowEditHwcClientWarehouseModal,
  setShowHwcClientWarehouseFeeModal,
  setHwcClientWarehouseFeeModalLoading
} = hwcClientWarehouseSlice.actions;

export const fetchHwcClientWarehouseHandler = (hwcClientId: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_WAREHOUSES}/${hwcClientId}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => dispatch(setHwcClientWarehouses(response.data)))
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const createHwcClientWarehouseHandler = (data: HwcClientWarehouseData): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setClientWarehouseModalLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_WAREHOUSES}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(setShowCreateHwcClientWarehouseModal(false));
        dispatch(fetchHwcClientWarehouseHandler(data.hwcClientRef));
      })
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setClientWarehouseModalLoading(false)));
  }
};

export const syncHwcClientWarehouseFeeHandler = (id: string, hwcClientRef: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setClientWarehouseModalLoading(true));
    axios
      .post(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_WAREHOUSES}/${id}`,
        {},
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        dispatch(fetchHwcClientWarehouseHandler(hwcClientRef));
      })
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setClientWarehouseModalLoading(false)));
  }
};

export const updateHwcClientWarehouseHandler = (id: string, data: HwcClientWarehouse): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  console.log(data);
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setClientWarehouseModalLoading(true));
    axios
      .put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_WAREHOUSES}/${id}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(setShowEditHwcClientWarehouseModal(false));
        dispatch(fetchHwcClientWarehouseHandler(data.hwcClientRef));
      })
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setClientWarehouseModalLoading(false)));
  }
};

export const mabangAuthUrlHandler = (hwcClientWarehouse: HwcClientWarehouse): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.MA_BANG_ERP}/url/${hwcClientWarehouse.id}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(fetchHwcClientWarehouseHandler(hwcClientWarehouse.hwcClientRef));
      })
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const deleteHwcClientWarehouseHandler = (hwcClientRef: string, id: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setClientWarehouseModalLoading(true));
    axios
      .delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_WAREHOUSES}/${id}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(fetchHwcClientWarehouseHandler(hwcClientRef));
      })
      .catch((error: Error) => errorHandler(error as any, dispatch))
      .finally(() => dispatch(setClientWarehouseModalLoading(false)));
  }
};

export const selectHwcClientWarehouses = (state: RootState): HwcClientWarehouse[] =>
  state.hwcClientWarehouses.hwcClientWarehouses;
export const selectHwcClientWarehouseLodaing = (state: RootState): boolean =>
  state.hwcClientWarehouses.loading;
export const selectShowCreateHwcClientWarehouseModal = (state: RootState): boolean =>
  state.hwcClientWarehouses.showCreateHwcClientWarehouseModal;
export const selectHwcClientWarehouseModalLoading = (state: RootState): boolean =>
  state.hwcClientWarehouses.hwcClientWarehouseModalLoading;
export const selectShowEditHwcClientWarehouseModal = (state: RootState): boolean =>
  state.hwcClientWarehouses.showEditHwcClientWarehouseModal;
export const selectShowHwcClientWarehouseFeeModal = (state: RootState): boolean =>
  state.hwcClientWarehouses.showHwcClientWarehouseFeeModal;
export const selectHwcClientWarehouseFeeModalLoading = (state: RootState): boolean =>
  state.hwcClientWarehouses.hwcClientWarehouseFeeModalLoading;

export default hwcClientWarehouseSlice.reducer;
