import { Button, Form, Input, Select, Switch } from 'antd';
import { Option } from 'antd/lib/mentions';
import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserBasicInfo } from '../../../custom_types/profile-page';
import {
  selectUsersPageLoading,
  updateUserHandler
} from '../../../redux/user/userSlice';
import { USER_ROLES, USER_ROLES_TEXT } from '../../../shared/utils/constants';

interface UserInfoPanelProps {
  data: UserBasicInfo;
}

const UserInfoPanel = ({ data }: UserInfoPanelProps): ReactElement => {
  const dispatch = useDispatch();
  const loading = useSelector(selectUsersPageLoading);
  const [active, setActive] = useState(data.active);

  const infoFormSubmitHandler = (values: any) => {
    const updateData: UserBasicInfo = {
      id: data.id,
      name: values.name,
      company: values.company,
      email: values.email,
      phone: values.phone,
      role: values.role,
      active
    };
    dispatch(updateUserHandler(updateData));
  };

  return (
    <div>
      <Form
        layout="vertical"
        className="form-body"
        initialValues={data}
        onFinish={infoFormSubmitHandler}
      >
        <Form.Item
          label="用户姓名"
          name="name"
          rules={[{ required: true, message: '用户姓名必须填！' }]}
        >
          <Input placeholder="用户姓名" disabled={!active} />
        </Form.Item>
        <Form.Item label="公司名称" name="company">
          <Input placeholder="公司名称" disabled={!active} />
        </Form.Item>
        <Form.Item
          label="用户邮箱"
          name="email"
          rules={[
            { type: 'email', message: '邮箱格式不正确!' },
            { required: true, message: '请输入用户邮箱!' }
          ]}
        >
          <Input placeholder="用户邮箱" disabled={!active} />
        </Form.Item>
        <Form.Item label="用户手机" name="phone">
          <Input placeholder="用户手机" disabled={!active} />
        </Form.Item>
        <Form.Item label="用户权限" name="role">
          <Select disabled={!active}>
            {Object.values(USER_ROLES).map((ele) => (
              <Option key={ele} value={ele}>
                {USER_ROLES_TEXT[ele]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="active">
          <Switch
            checkedChildren="启用"
            unCheckedChildren="停用"
            defaultChecked
            checked={active}
            onClick={() => setActive(!active)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            更新基本信息
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserInfoPanel;
