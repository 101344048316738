import { PayloadAction, createSlice, Dispatch } from '@reduxjs/toolkit';
import { AppThunk, RootState, WarehouseCarrierState } from '../custom_types/redux-types';
import { CreateWarehouseCarrierData, WarehouseCarrier } from '../custom_types/wms-page';
import axios from '../shared/utils/axios.base';
import errorHandler from '../shared/components/errorHandler';
import { SERVER_ROUTES } from '../shared/utils/constants';

const initialState: WarehouseCarrierState = {
  warehouseCarriers: [],
  loading: false,
  showWarehouseCarrierModal: false,
  warehouseCarrierModalLoading: false
};

export const warehouseCarriersSlice = createSlice({
  name: 'warehouseCarriers',
  initialState,
  reducers: {
    setWarehouseCarriers: (state, action: PayloadAction<WarehouseCarrier[]>) => {
      state.warehouseCarriers = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowWarehouseCarrierModal: (state, action: PayloadAction<boolean>) => {
      state.showWarehouseCarrierModal = action.payload;
    },
    setWarehouseCarrierModalLoading: (state, action: PayloadAction<boolean>) => {
      state.warehouseCarrierModalLoading = action.payload;
    }
  }
});

export const {
  setWarehouseCarriers,
  setLoading,
  setShowWarehouseCarrierModal,
  setWarehouseCarrierModalLoading
} = warehouseCarriersSlice.actions;

export const fetchWarehouseCarriersHandler = (warehouseId: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.WAREHOUSE_CARRIERS}/${warehouseId}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(setWarehouseCarriers(response.data));
    } catch (error) {
      errorHandler(error, dispatch);
    } finally {
      dispatch(setLoading(false));
    }
  }
};

export const createWarehouseCarrierHandler = (data: CreateWarehouseCarrierData): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setWarehouseCarrierModalLoading(true));
    try {
      const response = await axios.post(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.WAREHOUSE_CARRIERS}`,
        data,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchWarehouseCarriersHandler(data.warehouseRef));
    } catch (error) {
      errorHandler(error, dispatch);
    } finally {
      dispatch(setWarehouseCarrierModalLoading(false));
      dispatch(setShowWarehouseCarrierModal(false));
    }
  }
};

export const updateWarehouseCarrierHandler = (data: WarehouseCarrier): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setWarehouseCarrierModalLoading(true));
    try {
      const response = await axios.put(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.WAREHOUSE_CARRIERS}`,
        data,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchWarehouseCarriersHandler(data.warehouseRef));
    } catch (error) {
      errorHandler(error, dispatch);
    } finally {
      dispatch(setWarehouseCarrierModalLoading(false));
      dispatch(setShowWarehouseCarrierModal(false));
    }
  }
};

export const deleteWarehouseCarrierHandler = (id: string, warehouseId: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.WAREHOUSE_CARRIERS}/${id}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchWarehouseCarriersHandler(warehouseId));
    } catch (error) {
      errorHandler(error, dispatch);
    } finally {
      dispatch(setLoading(false));
    }
  }
};

export const selectWarehouseCarriers = (state: RootState) =>
  state.warehouseCarriers.warehouseCarriers;
export const selectWarehouseCarriersLoading = (state: RootState) => state.warehouseCarriers.loading;
export const selectShowWarehouseCarrierModal = (state: RootState) =>
  state.warehouseCarriers.showWarehouseCarrierModal;
export const selectWarehouseCarrierModalLoading = (state: RootState) =>
  state.warehouseCarriers.warehouseCarrierModalLoading;

export default warehouseCarriersSlice.reducer;
