import { Tabs } from 'antd';
import React from 'react';
import HwcStocksPanel from './HwcStocksPanel';
import MabangErpStockPanel from './MaBangErpStockPanel';
import { OverseaWarehouse } from '../../../../../custom_types/wms-page';

interface HwcStocksProps {
  hwcClient: OverseaWarehouse;
}

const HwcStocks = ({ hwcClient }: HwcStocksProps) => {
  return (
    <Tabs
      defaultActiveKey="1"
      tabPosition="left"
      style={{ height: 220 }}
      items={[
        {
          label: '库存列表',
          key: '1',
          children: <HwcStocksPanel hwcClientId={hwcClient.id} />
        },
        {
          label: '马帮ERP操作',
          key: '2',
          children: <MabangErpStockPanel hwcClientCode={hwcClient.code} />
        }
      ]}
    />
  );
};

export default HwcStocks;
