import React, { ReactElement, useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHwcPartnerModalLoading,
  setShowCreateHwcPartnerModal,
  updateHwcPartnerHandler
} from '../../../../redux/hwcPartnerSlice';
import { HwcPartner } from '../../../../custom_types/overseawarehouse/hwc-partner';

interface EditHwcPartnerModalProps {
  record: HwcPartner;
  onCancel: () => void;
}

const EditHwcPartnerModal = ({ record, onCancel }: EditHwcPartnerModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(selectHwcPartnerModalLoading);

  useEffect(() => {
    form.setFieldsValue({
      name: record.name,
      code: record.code,
      account: record.account,
      token: record.token
    });
  }, [form, record]);

  const okClickedHander = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          name: values.name,
          account: values.account,
          token: values.token
        };
        dispatch(updateHwcPartnerHandler(record.id, data));
        dispatch(setShowCreateHwcPartnerModal(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeModalHandler = () => {
    onCancel();
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      open
      centered
      closable={false}
      title="修改合作海外仓"
      onOk={okClickedHander}
      onCancel={closeModalHandler}
      okButtonProps={{
        loading
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="合作海外仓名称"
          name="name"
          rules={[{ required: true, message: '合作海外仓名称不能为空!' }]}
        >
          <Input placeholder="合作海外仓名称" />
        </Form.Item>
        <Form.Item label="代码" name="code" rules={[{ required: true, message: '代码不能为空' }]}>
          <Input placeholder="代码" disabled />
        </Form.Item>
        <Form.Item
          label="用户名"
          name="account"
          rules={[{ required: true, message: '用户名不能为空' }]}
        >
          <Input placeholder="用户名" />
        </Form.Item>
        <Form.Item
          label="API秘钥"
          name="token"
          rules={[{ required: true, message: 'API秘钥不能为空' }]}
        >
          <Input placeholder="API秘钥" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditHwcPartnerModal;
