import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { AppThunk, HwcPartnerState, RootState } from '../custom_types/redux-types';
import axios from '../shared/utils/axios.base';
import { SERVER_ROUTES } from '../shared/utils/constants';
import errorHandler from '../shared/components/errorHandler';
import { ResponseError } from '../custom_types/common';
import { HwcPartner, HwcPartnerData } from '../custom_types/overseawarehouse/hwc-partner';

const initialState: HwcPartnerState = {
  hwcPartners: [],
  loading: false,
  showCreateHwcPartnerModal: false,
  showUpdateHwcPartnerModal: false,
  hwcPartnerModalLoading: false
};

export const hwcPartnerSlice = createSlice({
  name: 'hwcPartner',
  initialState,
  reducers: {
    setHwcPartners: (state, action) => {
      state.hwcPartners = action.payload;
    },
    setHwcPartnerLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowCreateHwcPartnerModal: (state, action) => {
      state.showCreateHwcPartnerModal = action.payload;
    },
    setShowUpdateHwcPartnerModal: (state, action) => {
      state.showUpdateHwcPartnerModal = action.payload;
    },
    setHwcPartnerModalLoading: (state, action) => {
      state.hwcPartnerModalLoading = action.payload;
    }
  }
});

export const {
  setHwcPartners,
  setHwcPartnerLoading,
  setShowCreateHwcPartnerModal,
  setShowUpdateHwcPartnerModal,
  setHwcPartnerModalLoading
} = hwcPartnerSlice.actions;

export const fetchHwcPartnerHanlder = (): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcPartnerLoading(true));
    try {
      const response = await axios.get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PARTNERS}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      console.log(response.data);
      dispatch(setHwcPartners(response.data));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setHwcPartnerLoading(false));
    }
  }
};

export const createHwcPartnerHandler = (data: HwcPartnerData): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcPartnerLoading(true));
    try {
      await axios.post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PARTNERS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setShowCreateHwcPartnerModal(false));
      dispatch(fetchHwcPartnerHanlder());
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setHwcPartnerLoading(false));
    }
  }
};

export const updateHwcPartnerHandler = (id: string, data: { name: string }): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcPartnerModalLoading(true));
    try {
      await axios.put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PARTNERS}/${id}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setShowUpdateHwcPartnerModal(false));
      dispatch(fetchHwcPartnerHanlder());
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setHwcPartnerModalLoading(false));
    }
  }
};

export const deleteHwcPartnerHandler = (id: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcPartnerLoading(true));
    try {
      await axios.delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PARTNERS}/${id}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(fetchHwcPartnerHanlder());
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setHwcPartnerLoading(false));
    }
  }
};

export const selectLoading = (state: RootState): boolean => state.hwcPartners.loading;
export const selectHwcPartners = (state: RootState): HwcPartner[] => state.hwcPartners.hwcPartners;
export const selectShowUpdateHwcPartnerModal = (state: RootState): boolean =>
  state.hwcPartners.showUpdateHwcPartnerModal;
export const selectShowCreateHwcPartnerModal = (state: RootState): boolean =>
  state.hwcPartners.showCreateHwcPartnerModal;
export const selectHwcPartnerModalLoading = (state: RootState): boolean =>
  state.hwcPartners.hwcPartnerModalLoading;

export default hwcPartnerSlice.reducer;
