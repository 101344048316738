import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Popconfirm, Select, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '@ant-design/pro-components';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { render } from '@testing-library/react';
import { HwcProduct } from '../../../../../custom_types/overseawarehouse/hwc-product';
import {
  deleteHwcProductHandler,
  fetchHwcProductsHandler,
  selectHwcProducts,
  selectLoading,
  selectShowCreateHwcProductModal,
  setShowCreateHwcProductModal,
  syncHwcProductsHandler
} from '../../../../../redux/hwc/hwcProductSlice';
import {
  fetchHwcClientWarehouseHandler,
  selectHwcClientWarehouses
} from '../../../../../redux/hwc/hwcClientWarehouseSlice';
import { HwcClientWarehouse } from '../../../../../custom_types/redux-types';
import HwcProductCreateModal from './HwcProductCreateModal';

interface HwcProductPanelProps {
  hwcClientId: string;
}

const HwcProductPanel = ({ hwcClientId }: HwcProductPanelProps): ReactElement => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const showHwcProductCreateModal = useSelector(selectShowCreateHwcProductModal);
  const clientWarehouseProductList: HwcProduct[] = useSelector(selectHwcProducts);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const [selectHwcClientWarehouse, setSelectHwcClientWarehouse] = useState<
    HwcClientWarehouse | undefined
  >();

  useEffect(() => {
    if (!hwcClientWarehouseList || hwcClientWarehouseList.length === 0) {
      dispatch(fetchHwcClientWarehouseHandler(hwcClientId));
    }
    if (hwcClientWarehouseList && hwcClientWarehouseList.length > 0) {
      setSelectHwcClientWarehouse(hwcClientWarehouseList[0]);
      dispatch(fetchHwcProductsHandler(hwcClientId, hwcClientWarehouseList[0].id));
    }
  }, [dispatch, hwcClientId, hwcClientWarehouseList]);

  const handleHwcClientWarehouseChange = (clientWarehouseId: string) => {
    setSelectHwcClientWarehouse(
      hwcClientWarehouseList.find((item) => item.id === clientWarehouseId)
    );
    dispatch(fetchHwcProductsHandler(hwcClientId, clientWarehouseId));
  };

  const columns = [
    {
      title: '图片',
      key: 'img_web',
      dataIndex: 'img_web',
      render: (text: string) => {
        return text ? <img src={text} alt="img" width="100" /> : null;
      }
    },
    {
      title: 'SKU',
      key: 'ck_sku',
      dataIndex: 'ck_sku'
    },
    {
      title: '平台SKU',
      key: 'pt_sku',
      dataIndex: 'pt_sku'
    },
    {
      title: '产品名称',
      key: 'pname',
      dataIndex: 'pname'
    },
    {
      title: '重量(lb)',
      key: 'weight',
      dataIndex: 'weight'
    },
    {
      title: '尺寸(in)',
      key: 'dimention',
      dataIndex: 'dimention',
      render: (_: any, record: HwcProduct) => {
        const length = record.length ? record.length : '-';
        const width = record.width ? record.width : '-';
        const height = record.height ? record.height : '-';
        return `${length} x ${width} x ${height}`;
      }
    },
    {
      title: '颜色',
      key: 'pvalue',
      dataIndex: 'pvalue'
    },
    {
      title: '申报价格',
      key: 'colour',
      dataIndex: 'colour'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (text: string, _: HwcProduct): ReactElement => {
        switch (text) {
          case '0':
            return <div>待审核</div>;
          case '1':
            return <div>已审核</div>;
          case '2':
            return <div>已审核</div>;
          case '3':
            return <div>已审核</div>;
          default:
            return <div>-</div>;
        }
      }
    },
    {
      title: '备注',
      key: 'description',
      dataIndex: 'description'
    },
    {
      title: '操作',
      key: 'operations',
      render: (text: string, record: HwcProduct): ReactElement => {
        return (
          <>
            <Popconfirm
              key="确认删除该仓库"
              title="确认删除该仓库?"
              placement="topRight"
              onConfirm={() =>
                dispatch(
                  deleteHwcProductHandler(record.clientRef, record.clientWarehouseRef, record.id)
                )
              }
              okText="确定"
              cancelText="取消"
            >
              <Button key="删除" size="small" type="link">
                删除
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  return (
    <div>
      {showHwcProductCreateModal && selectHwcClientWarehouse && (
        <HwcProductCreateModal
          clientId={hwcClientId}
          clientWarehouseId={selectHwcClientWarehouse.id}
        />
      )}
      <PageHeader
        title={
          selectHwcClientWarehouse && (
            <Select
              defaultValue={selectHwcClientWarehouse.id}
              style={{ width: 200 }}
              options={hwcClientWarehouseList.map((item) => ({ label: item.name, value: item.id }))}
              onChange={handleHwcClientWarehouseChange}
            />
          )
        }
        extra={[
          <Button
            key="1"
            type="default"
            disabled={!selectHwcClientWarehouse}
            icon={<SyncOutlined />}
            loading={loading}
            onClick={() =>
              dispatch(fetchHwcProductsHandler(hwcClientId, selectHwcClientWarehouse!.id))
            }
          />,
          <Button
            key="1"
            type="primary"
            disabled={!selectHwcClientWarehouse}
            icon={<SyncOutlined />}
            loading={loading}
            onClick={() =>
              dispatch(syncHwcProductsHandler(hwcClientId, selectHwcClientWarehouse!.id))
            }
          >
            同步商品
          </Button>,
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            loading={loading}
            disabled={!selectHwcClientWarehouse}
            onClick={() => dispatch(setShowCreateHwcProductModal(true))}
          >
            添加商品
          </Button>
        ]}
      />
      <Table<HwcProduct>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={clientWarehouseProductList}
        loading={loading}
      />
    </div>
  );
};

export default HwcProductPanel;
