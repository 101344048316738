import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AppThunk, HwcStockState, RootState } from '../../custom_types/redux-types';
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import errorHandler from '../../shared/components/errorHandler';
import { HwcStock } from '../../custom_types/overseawarehouse/hwc-stock';

const initialState: HwcStockState = {
  hwcStocks: [],
  loading: false
};

export const hwcClientStockSlice = createSlice({
  name: 'hwcStock',
  initialState,
  reducers: {
    setHwcStocks: (state, action) => {
      state.hwcStocks = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const { setHwcStocks, setLoading } = hwcClientStockSlice.actions;

export const fetchHwcStocksHandler = (
  hwcClientId: string,
  hwcClientWarehouseCode: string
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    console.log('fetchHwcStocksHandler');
    dispatch(setLoading(true));
    axios
      .get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWc_STOCKS}/fetch/${hwcClientId}/${hwcClientWarehouseCode}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        dispatch(setHwcStocks(response.data));
        console.log(response.data);
        notification.success({ message: '库存获取成功', description: '库存获取成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const syncHwcStocksHandler = (
  hwcClientId: string,
  hwcClientWarehouseCode: string
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWc_STOCKS}/sync/${hwcClientId}/${hwcClientWarehouseCode}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        dispatch(fetchHwcStocksHandler(hwcClientId, hwcClientWarehouseCode));
        notification.success({ message: '库存同步成功', description: '库存同步成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const selectHwcStockLoading = (state: RootState): boolean => state.hwcStocks.loading;
export const selectHwcStocks = (state: RootState): HwcStock[] => state.hwcStocks.hwcStocks;

export default hwcClientStockSlice.reducer;
