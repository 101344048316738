import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUserHandler } from '../../../redux/user/userSlice';

interface AvatarDropdownProps {
  name: string;
}

const AvatarDropdown = ({ name }: AvatarDropdownProps): ReactElement => {
  const dispatch = useDispatch();

  const menuHeaderDropdown = (
    <Menu>
      <Menu.Item
        key="logout"
        onClick={() => dispatch(logoutUserHandler())}
        icon={<LogoutOutlined />}
      >
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menuHeaderDropdown}>
      <Button type="default" icon={<UserOutlined />}>
        {name}
      </Button>
    </Dropdown>
  );
};

export default AvatarDropdown;
