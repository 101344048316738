import React from 'react';
import { Button, Popconfirm, Space, Tag } from 'antd';
import { CheckOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleJob, ScheduleJobSearchQuery } from '../../../../custom_types/scheduleJob';
import {
  deleteScheduleJobHandler,
  restartScheduleJobHandler,
  selectSecduleJobLoading,
  startScheduleJobHandler,
  stopScheduleJobHandler
} from '../../../../redux/scheduleJobSlice';

interface HwcScheduleJobElementProps {
  job: ScheduleJob;
  searchQuery: ScheduleJobSearchQuery;
}

const HwcScheduleJobElement = ({ job, searchQuery }: HwcScheduleJobElementProps) => {
  const dispatch = useDispatch();
  const scheduleJobLoading = useSelector(selectSecduleJobLoading);

  return (
    <div key={job.id}>
      <Space
        size="large"
        direction="horizontal"
        style={{ border: '1px solid #e8e8e8', padding: '20px', width: '100%' }}
      >
        <Space size="small" direction="vertical">
          {job.status === 1 ? (
            <Tag color="green">
              <Space size="small">
                <SyncOutlined spin />
                <div>正在运行</div>
              </Space>
            </Tag>
          ) : (
            <Tag color="red">
              <Space size="small">
                <StopOutlined />
                <div>任务停止</div>
              </Space>
            </Tag>
          )}
          {job.autoStart && (
            <Tag color="default">
              <Space size="small">
                <CheckOutlined />
                <div>自动启动</div>
              </Space>
            </Tag>
          )}
        </Space>
        <div>
          <div>任务名称: {job.jobName}</div>
          <div>任务说明: {job.description}</div>
          <div>任务时区: {job.tz}</div>
        </div>
        <div>
          <div>启动时间: {`${job.startTime.hour}点${job.startTime.minute}分(每天)`}</div>
          <div>执行次数: {`${job.repeatTimes}次(每天)`}</div>
          {job.repeatTimes > 1 && (
            <div>执行间隔: {`${job.repeatInterval.hour}小时${job.repeatInterval.minute}分钟`}</div>
          )}
        </div>
        <Space size="small" direction="vertical">
          {job.status === 0 && (
            <Button
              type="primary"
              loading={scheduleJobLoading}
              onClick={() => dispatch(startScheduleJobHandler(job.jobName, searchQuery))}
            >
              启动任务
            </Button>
          )}
          {job.status === 1 && (
            <Popconfirm
              key="确认停止任务"
              title="确认停止任务?"
              placement="topLeft"
              onConfirm={() => dispatch(stopScheduleJobHandler(job.jobName, searchQuery))}
              okText="确定"
              cancelText="取消"
            >
              <Button type="primary" loading={scheduleJobLoading}>
                停止任务
              </Button>
            </Popconfirm>
          )}
          {job.status === 1 && (
            <Button
              type="primary"
              loading={scheduleJobLoading}
              onClick={() => dispatch(restartScheduleJobHandler(job.jobName, searchQuery))}
            >
              重启任务
            </Button>
          )}
          <Popconfirm
            key="确认删除任务"
            title="确认删除任务?"
            placement="topLeft"
            onConfirm={() => dispatch(deleteScheduleJobHandler(job.jobName, searchQuery))}
            okText="确定"
            cancelText="取消"
          >
            <Button type="primary" loading={scheduleJobLoading}>
              删除任务
            </Button>
          </Popconfirm>
        </Space>
      </Space>
    </div>
  );
};

export default HwcScheduleJobElement;
