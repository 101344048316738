import React from 'react';
import { Form, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  LabelUser,
  PasswordFormValue,
  UserPasswordUpdate
} from '../../../../custom_types/labels/label-profile-types';
import PasswordPanel from '../../../../shared/components/PasswordPanel';
import {
  selectShowPassordModal,
  setShowUpdatePasswordModal,
  updateLabelUserPassword
} from '../../../../redux/Labels/LabelUserSlice';

interface UpdateWarehouseUserPasswordModalProps {
  warehouseUser: LabelUser;
}

const UpdateWarehouseUserPasswordModal = ({
  warehouseUser
}: UpdateWarehouseUserPasswordModalProps) => {
  const dispatch = useDispatch();
  const showModal = useSelector(selectShowPassordModal);
  const [form] = Form.useForm();

  const closeClickedHandler = () => {
    form.resetFields();
    dispatch(setShowUpdatePasswordModal(false));
  };

  const passwordUpdatedHandler = async (userId: string, values: PasswordFormValue) => {
    const data: UserPasswordUpdate = {
      id: userId,
      ...values
    };
    dispatch(updateLabelUserPassword(data));
  };

  return (
    <Modal
      closable={false}
      visible={showModal}
      title="修改密码"
      cancelText="取消"
      onCancel={closeClickedHandler}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <PasswordPanel
        isSelf={false}
        onSumbit={(values: PasswordFormValue) => passwordUpdatedHandler(warehouseUser.id, values)}
      />
    </Modal>
  );
};

export default UpdateWarehouseUserPasswordModal;
