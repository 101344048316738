import React, { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UI_ROUTES } from '../../../shared/utils/constants';
import WarehousePage from '../Warehouses/pages/WarehousePage';
import WMSOrderPage from '../WMSOrder/pages/WMSOrderPage';
import WarehouseDetailPage from '../Warehouses/pages/WarehouseDetailPage';
import WmsDashboardPage from '../pages/WmsDashboardPage';
import VendorsPage from '../Vendors/pages/vendorsPage';
import OverseaWarehousePage from '../OverseaWarehouse/pages/OverseaWarehouse';
import OverseaWarehouseDetailPage from '../OverseaWarehouse/pages/OverseaWarehouseDetailPage';
import HwcPartnerPage from '../HwcPartner/pages/HwcPartnerPage';
import HwcPartnerDetailPage from '../HwcPartner/pages/HwcPartnerDetailedPage';
import HwcWarehousePage from '../HwcWarehouse/pages/HwcWarehousePage';
import HwcWarehouseDetailPage from '../HwcWarehouse/pages/HwcWarehouseDetailedPage';
import HwcWarehouseFeePage from '../HwcWarehouse/pages/HwcWarehouseFeePage';
import HwcClientWarehouseDetailPage from '../OverseaWarehouse/components/HwcClientWarehouseDetailPage';

const WMSRoutes = (): ReactElement => {
  return (
    <Switch>
      <Route path={`${UI_ROUTES.WMS}${UI_ROUTES.DASHBOARD}`} component={WmsDashboardPage} />
      <Route path={`${UI_ROUTES.WMS}${UI_ROUTES.WAREHOUSES}/:id`} component={WarehouseDetailPage} />
      <Route path={`${UI_ROUTES.WMS}${UI_ROUTES.WAREHOUSES}`} component={WarehousePage} />
      <Route path={`${UI_ROUTES.WMS}${UI_ROUTES.ORDERS}`} component={WMSOrderPage} />
      <Route path={`${UI_ROUTES.WMS}${UI_ROUTES.VENDORS}`} component={VendorsPage} />
      <Route
        path={`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES_CK}/:id/:clientId/:clientName`}
        component={HwcClientWarehouseDetailPage}
      />
      <Route
        path={`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES}/:id`}
        component={OverseaWarehouseDetailPage}
      />
      <Route
        path={`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES}`}
        component={OverseaWarehousePage}
      />
      <Route
        path={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_WAREHOUSES}/:id`}
        component={HwcWarehouseDetailPage}
      />
      <Route path={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_WAREHOUSES}`} component={HwcWarehousePage} />
      <Route
        path={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_PARTNERS}/:id`}
        component={HwcPartnerDetailPage}
      />
      <Route path={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_PARTNERS}`} component={HwcPartnerPage} />
      <Route path={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_FEE}`} component={HwcWarehouseFeePage} />
      <Redirect from={UI_ROUTES.WMS} to={`${UI_ROUTES.WMS}${UI_ROUTES.DASHBOARD}`} />
    </Switch>
  );
};

export default WMSRoutes;
