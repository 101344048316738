import React, { ReactElement, useEffect } from 'react';
import { Modal, Form, Input, Row, Col, InputNumber, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LabelUserBillCreate } from '../../../../custom_types/labels/label-user-bill-types';
import { Currency, HWC_CLIENT_BILL_TYPE } from '../../../../shared/utils/constants';
import {
  selectModalLoading,
  selectShowTopUpModal,
  setShowTopUpModal,
  topupHwcClientBillHandler
} from '../../../../redux/hwc/hwcClientBillSlice';
import { HwcBillTopup } from '../../../../custom_types/overseawarehouse/hwc-bill';

interface HwcClientBillTopUpModalProps {
  warehouseId: string;
}

const HwcClientBillTopUpModal = ({ warehouseId }: HwcClientBillTopUpModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const showModal = useSelector(selectShowTopUpModal);
  const modalLoading = useSelector(selectModalLoading);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const cancelClickedHandler = () => {
    form.resetFields();
    dispatch(setShowTopUpModal(false));
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        const data: HwcBillTopup = {
          code: '-',
          type: HWC_CLIENT_BILL_TYPE.RECHARGE,
          depositChange: values.depositChange,
          balanceChange: values.balanceChange,
          clientId: warehouseId
        };
        dispatch(topupHwcClientBillHandler(data));
        form.resetFields();
      })
      .catch(() => {});
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      centered
      closable={false}
      open={showModal}
      okText="调整余额"
      cancelText="取消"
      title="客户余额额度调整"
      onCancel={cancelClickedHandler}
      onOk={okClickedHandler}
      okButtonProps={{ loading: modalLoading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="余额调整金额"
          name="balanceChange"
          rules={[{ required: true, message: '余额调整金额必须填！' }]}
        >
          <InputNumber
            style={{ width: 'auto' }}
            autoFocus
            defaultValue={0}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Form.Item>
        <Form.Item
          label="押金调整金额"
          name="depositChange"
          rules={[{ required: true, message: '押金调整金额必须填！' }]}
        >
          <InputNumber
            style={{ width: 'auto' }}
            autoFocus
            defaultValue={0}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HwcClientBillTopUpModal;
