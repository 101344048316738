import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import {
  HwcBill,
  HwcBillCreate,
  HwcBillTopup,
  HwcBillUpdate,
  HwcClientBillState
} from '../../custom_types/overseawarehouse/hwc-bill';
import { AppThunk, RootState } from '../../custom_types/redux-types';
import errorHandler from '../../shared/components/errorHandler';
import { fetchOverseaWarehouseHanlder } from '../overseaWarehouseSlice';

const initialState: HwcClientBillState = {
  hwcClientBills: [],
  loading: false,
  showModal: false,
  modalLoading: false,
  showTopUpModal: false
};

export const hwcClientBillSlice = createSlice({
  name: 'hwcClientBill',
  initialState,
  reducers: {
    setHwcClientBills: (state, action: PayloadAction<HwcBill[]>) => {
      state.hwcClientBills = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalLoading = action.payload;
    },
    setShowTopUpModal: (state, action: PayloadAction<boolean>) => {
      state.showTopUpModal = action.payload;
    }
  }
});

export const {
  setHwcClientBills,
  setLoading,
  setShowModal,
  setModalLoading,
  setShowTopUpModal
} = hwcClientBillSlice.actions;

export const fetchHwcClientBillsHandler = (overseawarehouseId: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_BILLS}/${overseawarehouseId}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response) => dispatch(setHwcClientBills(response.data)))
      .catch((error) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const createHwcClientBillHandler = (data: HwcBillCreate): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setModalLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_BILLS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response) => {
        dispatch(setShowModal(false));
        dispatch(fetchHwcClientBillsHandler(data.clientId));
        dispatch(fetchOverseaWarehouseHanlder());
      })
      .catch((error) => errorHandler(error, dispatch))
      .finally(() => dispatch(setModalLoading(false)));
  }
};

export const updateHwcClientBillHandler = (id: string, data: HwcBillUpdate): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setModalLoading(true));
    axios
      .put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_BILLS}/${id}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response) => {
        dispatch(setShowModal(false));
        dispatch(fetchHwcClientBillsHandler(data.clientId));
        dispatch(fetchOverseaWarehouseHanlder());
      })
      .catch((error) => errorHandler(error, dispatch))
      .finally(() => dispatch(setModalLoading(false)));
  }
};

export const topupHwcClientBillHandler = (data: HwcBillTopup): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setModalLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_BILLS}/topup`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response) => {
        dispatch(setShowTopUpModal(false));
        dispatch(fetchHwcClientBillsHandler(data.clientId));
        dispatch(fetchOverseaWarehouseHanlder());
      })
      .catch((error) => errorHandler(error, dispatch))
      .finally(() => dispatch(setModalLoading(false)));
  }
};

export const selectHwcClientBills = (state: RootState): HwcBill[] =>
  state.hwcClientBills.hwcClientBills;
export const selectHwcClientBillLodaing = (state: RootState): boolean =>
  state.hwcClientBills.loading;
export const selectShowModal = (state: RootState): boolean => state.hwcClientBills.showModal;
export const selectModalLoading = (state: RootState): boolean => state.hwcClientBills.modalLoading;
export const selectShowTopUpModal = (state: RootState): boolean =>
  state.hwcClientBills.showTopUpModal;

export default hwcClientBillSlice.reducer;
