import { Order } from '../../custom_types/order-page';
import { FeeRate } from '../../custom_types/platform-carriers-tyes';
import {
  CarrierRateType,
  Country,
  CURRENCY_SIGNS,
  RATE_BASES
} from './constants';

export const isOrderInternational = (order: Order): boolean => {
  const sender = order.sender;
  const recipient = order.recipient;
  const isInternational = sender.country !== recipient.country;
  return isInternational;
};

export const isOrderChinaImport = (order: Order): boolean => {
  const sender = order.sender;
  const recipient = order.recipient;
  const isInternational = sender.country !== recipient.country;
  const isChinaImport = isInternational && recipient.country === Country.CHINA;
  return isChinaImport;
};

export const displayRate = (rate: FeeRate): string => {
  let surfix = rate.ratebase;
  if (rate.ratebase === RATE_BASES.WEIGHT) {
    surfix = `/${rate.weightUnit}`;
  }
  let currencySign = CURRENCY_SIGNS[rate.currency];
  if (rate.ratetype === CarrierRateType.PERSENTAGE) {
    currencySign = '%';
  }
  let data = `${currencySign} ${rate.rate.toFixed(2)} ${surfix}`;
  if (rate.ratetype === CarrierRateType.PERSENTAGE) {
    data = `${rate.rate.toFixed(2)}${currencySign} ${surfix}`;
  }
  return data;
};
