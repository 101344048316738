import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import axios from '../../shared/utils/axios.base';
import {
  CreateWmsUserData,
  UpdateUserResponse,
  User,
  UserBasicInfo
} from '../../custom_types/profile-page';
import {
  AppThunk,
  CurrentUserState,
  RootState
} from '../../custom_types/redux-types';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import { setCarriers } from '../carriersSlice';
import errorHandler from '../../shared/components/errorHandler';
import { UserPasswordUpdate } from '../../custom_types/labels/label-profile-types';

const initialState: CurrentUserState = {
  currentUser: undefined,
  usersList: [],
  usersPageLoading: false,
  userModal: false,
  loginLoading: false,
  loginError: false,
  resetEmailSent: false,
  registerError: undefined,
  resetError: undefined,
  resetLoading: false,
  userTimeout: undefined
};

export const userSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User | undefined>) => {
      state.currentUser = action.payload;
    },
    setUsersList: (state, action: PayloadAction<UserBasicInfo[]>) => {
      state.usersList = action.payload;
    },
    updateCurrentUser: (state, action: PayloadAction<UpdateUserResponse>) => {
      if (state.currentUser) {
        state.currentUser.name = action.payload.name;
        state.currentUser.company = action.payload.company;
        state.currentUser.email = action.payload.email;
      }
    },
    setUsersPageLoading: (state, action: PayloadAction<boolean>) => {
      state.usersPageLoading = action.payload;
    },
    setUserModal: (state, action: PayloadAction<boolean>) => {
      state.userModal = action.payload;
    },
    setLoginLoading: (state, action: PayloadAction<boolean>) => {
      state.loginLoading = action.payload;
    },
    setLoginError: (state, action: PayloadAction<boolean>) => {
      state.loginError = action.payload;
    },
    setResetEmailSent: (state, action: PayloadAction<boolean>) => {
      state.resetEmailSent = action.payload;
    },
    setRegisterError: (state, action: PayloadAction<string | undefined>) => {
      state.registerError = action.payload;
    },
    setResetError: (state, action: PayloadAction<string | undefined>) => {
      state.resetError = action.payload;
    },
    setResetLoading: (state, action: PayloadAction<boolean>) => {
      state.resetLoading = action.payload;
    },
    setUserTimeout: (
      state,
      action: PayloadAction<NodeJS.Timeout | undefined>
    ) => {
      state.userTimeout = action.payload;
    }
  }
});

export const {
  setCurrentUser,
  setUsersList,
  updateCurrentUser,
  setUsersPageLoading,
  setUserModal,
  setLoginLoading,
  setLoginError,
  setResetEmailSent,
  setRegisterError,
  setResetError,
  setResetLoading,
  setUserTimeout
} = userSlice.actions;

export const loginUserHandler = (info: {
  email: string;
  password: string;
}): AppThunk => (dispatch: Dispatch) => {
  dispatch(setLoginLoading(true));
  axios
    .post(`${SERVER_ROUTES.USERS}/wms_login`, info)
    .then((response) => {
      const userData = response.data;
      dispatch(setCurrentUser(userData));
      const remaintingTime = userData.tokenExpire - Date.now();
      const logoutTimer = setTimeout(() => {
        dispatch(setCurrentUser(undefined));
      }, remaintingTime);
      dispatch(setUserTimeout(logoutTimer));
    })
    .catch(() => {
      dispatch(setLoginError(true));
    })
    .finally(() => dispatch(setLoginLoading(false)));
};

export const fetchUserHandler = (): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setUsersPageLoading(true));
    axios
      .get(`${SERVER_ROUTES.USERS}${SERVER_ROUTES.WMS_USERS}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response) => {
        const users = response.data;
        dispatch(setUsersList(users));
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setUsersPageLoading(false)));
  }
};

export const resetUserPasswordEmail = (data: { email: string }): AppThunk => (
  dispatch: Dispatch
) => {
  dispatch(setLoginLoading(true));
  axios
    .post(`${SERVER_ROUTES.USERS}${SERVER_ROUTES.WMS_USERS}/reset_email`, data)
    .then(() => {
      dispatch(setResetEmailSent(true));
      dispatch(setResetError(undefined));
    })
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        dispatch(setResetError(`${data.email} not found`));
      } else {
        dispatch(
          setResetError('Failed to send email, please try again later.')
        );
      }
    })
    .finally(() => {
      dispatch(setLoginLoading(false));
    });
};

export const logoutUserHandler = (): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  dispatch(setCurrentUser(undefined));
  dispatch(setCarriers([]));
  const timer = getState().currentUser.userTimeout;
  if (timer) clearTimeout(timer);
};

export const createUserHandler = (data: CreateWmsUserData): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    axios
      .post(`${SERVER_ROUTES.USERS}${SERVER_ROUTES.WMS_USERS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(setUserModal(false));
        dispatch(fetchUserHandler());
      })
      .catch((error) => errorHandler(error, dispatch));
  }
};

export const updateUserPasswordHandler = (
  data: UserPasswordUpdate
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setUsersPageLoading(true));
    axios
      .put(`${SERVER_ROUTES.USERS}${SERVER_ROUTES.WMS_USERS}/password`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {})
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setUsersPageLoading(false)));
  }
};

export const updateUserHandler = (data: UserBasicInfo): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setUsersPageLoading(true));
    axios
      .put(`${SERVER_ROUTES.USERS}${SERVER_ROUTES.WMS_USERS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(fetchUserHandler());
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setUsersPageLoading(false)));
  }
};

export const selectCurUser = (state: RootState): User | undefined =>
  state.currentUser.currentUser;
export const selectUsersList = (state: RootState): UserBasicInfo[] =>
  state.currentUser.usersList;
export const selectUsersPageLoading = (state: RootState): boolean =>
  state.currentUser.usersPageLoading;
export const selectUserModal = (state: RootState): boolean =>
  state.currentUser.userModal;
export const selectLoginLoading = (state: RootState): boolean =>
  state.currentUser.loginLoading;
export const selectLoginError = (state: RootState): boolean =>
  state.currentUser.loginError;
export const selectResetEmailSent = (state: RootState): boolean =>
  state.currentUser.resetEmailSent;
export const selectRegisterError = (state: RootState): string | undefined =>
  state.currentUser.registerError;
export const selectResetError = (state: RootState): string | undefined =>
  state.currentUser.resetError;
export const selectResetLoading = (state: RootState): boolean =>
  state.currentUser.resetLoading;

export default userSlice.reducer;
