import { Button, DatePicker, Divider, Form, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { ReactElement, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { TIMEZONE } from '../../../../../shared/utils/constants';
import { MannualLabelOrderRequest } from '../../../../../custom_types/mabangErp/mabangErpOrder';
import {
  manualPullLabelOrdersHandler,
  selectMabangLoading
} from '../../../../../redux/mabangErp/mabangErpOrderSlice';
import {
  createScheduleJobHandler,
  searchSechduleJobHandler,
  selectMaBangPullLabelOrdersJobs,
  selectSecduleJob,
  selectSecduleJobLoading,
  selectShowMabangPullLabelOrdersScheduleJobModal,
  selectShowSchecudleJobModal,
  setShowMabangPullLabelOrdersScheduleJobModal,
  setShowSchecudleJobModal
} from '../../../../../redux/scheduleJobSlice';
import HwcScheduleJobElement from '../../../HwcWarehouse/components/HwcScheduleJobElement';
import HwcPullOrderScheduleJobModal from './HwcPullOrderScheduleJobModal';
import { ScheduleJob } from '../../../../../custom_types/scheduleJob';
import { HwcClientWarehouse } from '../../../../../custom_types/redux-types';

interface MabangErpLabelOrderPanelProps {
  hwcClientCode: string;
  hwcClientWarehouse: HwcClientWarehouse;
}

const MabangErpLabelOrderPanel = ({
  hwcClientCode,
  hwcClientWarehouse
}: MabangErpLabelOrderPanelProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const loading = useSelector(selectMabangLoading);
  const scheduleJobs = useSelector(selectMaBangPullLabelOrdersJobs);
  const scheduleJobLoading = useSelector(selectSecduleJobLoading);
  const showSchecudleJobModal = useSelector(selectShowMabangPullLabelOrdersScheduleJobModal);

  useEffect(() => {
    // Search HwcClientWarehouse Stock Push Schedule Job
    dispatch(
      searchSechduleJobHandler({
        clientId: hwcClientWarehouse.hwcClientRef,
        clientWarehouseCode: hwcClientWarehouse.code,
        jobType: 'labelOrderPull_mabang'
      })
    );
  }, [dispatch, hwcClientWarehouse.code, hwcClientWarehouse.hwcClientRef]);

  const manualPullOrders = (hwcClientWarehouseId: string) => {
    form.validateFields().then((values) => {
      const data: MannualLabelOrderRequest = {
        hwcClientWarehouseId,
        timezone: values.timezone,
        startDate: values.startDate.format('YYYY-MM-DD'),
        endDate: values.endDate.format('YYYY-MM-DD')
      };
      dispatch(manualPullLabelOrdersHandler(data));
    });
  };

  const showCreateScheduleJobModal = () => {
    dispatch(setShowMabangPullLabelOrdersScheduleJobModal(true));
  };

  const createMabangStockPushScheduleJob = (values: any) => {
    console.log(values);
    const data: ScheduleJob = {
      id: '',
      jobType: 'labelOrderPull_mabang',
      autoStart: values.autoStart,
      jobName: `labelOrderPull_mabang:${hwcClientCode}:${hwcClientWarehouse.code}:${values.jobName}`,
      jobHandler: '',
      params: {
        clientId: hwcClientWarehouse.hwcClientRef,
        clientWarehouseId: hwcClientWarehouse.id,
        clientWarehouseCode: hwcClientWarehouse.code,
        orderIntervalHour: values['order-hour'],
        orderIntervalMinute: values['order-minute'],
        timezone: values.tz,
        jobName: values.jobName
      },
      description: `Auto Ma Bang Pull Order Job for ${hwcClientCode}:${hwcClientWarehouse.code}`,
      status: 0,
      createBy: '',
      updatedBy: '',
      createTime: dayjs().toDate(),
      updateTime: dayjs().toDate(),
      startTime: { hour: values['start-hour'], minute: values['start-minute'] },
      repeatTimes: values.repeatTimes,
      repeatInterval: {
        hour: values.repeatTimes === 1 ? 0 : values['repeat-hour'],
        minute: values.repeatTimes === 1 ? 0 : values['repeat-minute']
      },
      tz: values.tz,
      clientId: hwcClientWarehouse.hwcClientRef,
      clientWarehouseCode: hwcClientWarehouse.code
    };
    dispatch(
      createScheduleJobHandler(data, {
        clientId: hwcClientWarehouse.hwcClientRef,
        clientWarehouseCode: hwcClientWarehouse.code,
        jobType: 'labelOrderPull_mabang'
      })
    );
  };

  const closeModalHandler = () => {
    dispatch(setShowMabangPullLabelOrdersScheduleJobModal(false));
  };

  return (
    <div>
      {showSchecudleJobModal && (
        <HwcPullOrderScheduleJobModal
          onOk={createMabangStockPushScheduleJob}
          onCancel={closeModalHandler}
        />
      )}
      <Divider orientation="left">手动拉取交运订单</Divider>
      <Form form={form}>
        <Space
          size="large"
          direction="horizontal"
          style={{ border: '1px solid #e8e8e8', padding: '20px', width: '100%' }}
        >
          <Form.Item
            name="timezone"
            label="时区选择"
            rules={[{ required: true, message: '请选择时区' }]}
            initialValue={TIMEZONE[0].value}
          >
            <Select style={{ width: 200 }} options={TIMEZONE} />
          </Form.Item>
          <Form.Item name="startDate" label="起始日期" initialValue={dayjs().subtract(1, 'day')}>
            <DatePicker style={{ width: 200 }} format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item name="endDate" label="结束日期" initialValue={dayjs()}>
            <DatePicker style={{ width: 200 }} format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={() => manualPullOrders(hwcClientWarehouse.id)}
            >
              拉取订单
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <Divider orientation="left">自动拉取交运订单</Divider>
      <Space
        size="large"
        direction="horizontal"
        style={{ border: '1px solid #e8e8e8', padding: '20px', width: '100%' }}
      >
        <Button
          type="primary"
          loading={scheduleJobLoading}
          onClick={showCreateScheduleJobModal}
          icon={<PlusOutlined />}
        >
          添加马帮自动拉取交运订单任务
        </Button>
        <Button
          icon={<SyncOutlined spin={loading} />}
          onClick={() =>
            dispatch(
              searchSechduleJobHandler({
                clientId: hwcClientWarehouse.hwcClientRef,
                clientWarehouseCode: hwcClientWarehouse.code,
                jobType: 'labelOrderPull_mabang'
              })
            )
          }
        >
          刷新
        </Button>
      </Space>
      {scheduleJobs &&
        scheduleJobs.map((job) => (
          <HwcScheduleJobElement
            job={job}
            searchQuery={{
              clientId: hwcClientWarehouse.hwcClientRef,
              clientWarehouseCode: hwcClientWarehouse.code,
              jobType: 'labelOrderPull_mabang'
            }}
          />
        ))}
    </div>
  );
};

export default MabangErpLabelOrderPanel;
