import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Descriptions } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import { HwcWarehouse } from '../../../../custom_types/redux-types';

interface HwcWarehouseHeaderProps {
  hwcWarehouse: HwcWarehouse | undefined;
  footer: ReactElement;
}

const HwcWarehouseHeader = ({ hwcWarehouse, footer }: HwcWarehouseHeaderProps) => {
  const history = useHistory();
  return (
    <PageHeader
      title="海外仓详情"
      footer={footer}
      onBack={() => history.push(`${UI_ROUTES.WMS}${UI_ROUTES.HWC_WAREHOUSES}`)}
    >
      {hwcWarehouse && (
        <Row>
          <Col span={12}>
            <Descriptions size="small" column={3}>
              <Descriptions.Item label="名称">{hwcWarehouse.name}</Descriptions.Item>
              <Descriptions.Item label="代码">{hwcWarehouse.code}</Descriptions.Item>
              {hwcWarehouse.thirdParty && (
                <>
                  <Descriptions.Item label="合作海外仓">
                    {hwcWarehouse.thirdPartyParnter}
                  </Descriptions.Item>
                  <Descriptions.Item label="合作仓库">
                    {hwcWarehouse.thirdPartyCode}
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          </Col>
        </Row>
      )}
    </PageHeader>
  );
};

export default HwcWarehouseHeader;
