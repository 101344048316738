import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Image, Input, Layout, Select, Space, Switch, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { useForm } from 'antd/lib/form/Form';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Carrier, CarrierUpdateData, Facility, Service } from '../../../custom_types/carrier-page';
import {
  createCarrierHandler,
  selectCarriers,
  selectCarriersLoading,
  selectRedirectToCarriersPage,
  updateCarrierHandler
} from '../../../redux/carriersSlice';
import FooterComponent from '../../../shared/components/FooterComponent';
import {
  CARRIERS,
  CARRIER_REGIONS,
  DHL_ECOMMERCE_SERVICES,
  FEDEX_SERVICES,
  RUI_YUN_SERVICES,
  UI_ROUTES,
  UPS_SERVICES,
  USPS3_SERVICES,
  USPS_SERVICES
} from '../../../shared/utils/constants';
import { getCarrierLogo } from '../../../shared/utils/logo.helper';
import HeaderMenu from '../../Layout/components/HeaderMenu';
import ThirdPartyPanel from '../components/ThirdPartyPanel';

const { Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;

const EditCarrierPage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = useForm();
  const { carrierId } = useParams<{ carrierId: string }>();
  const carriers = useSelector(selectCarriers);
  const loading = useSelector(selectCarriersLoading);
  const redirectToCarrierPage = useSelector(selectRedirectToCarriersPage);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [carrierType, setCarrierType] = useState<string>('');
  const [curCarrier, setCurCarrier] = useState<Carrier | undefined>(undefined);
  const [activeSwitch, setActiveSwitch] = useState<boolean>(true);

  useEffect(() => {
    let newFlag = false;
    const carrierValues = Object.values(CARRIERS);
    for (let i = 0; i < carrierValues.length; i += 1) {
      newFlag = carrierValues[i] === carrierId;
      if (newFlag) break;
    }
    setIsNew(newFlag);

    if (!newFlag) {
      const carrier = carriers.find((ele) => ele.id === carrierId);
      if (carrier) {
        setCurCarrier(carrier);
        setCarrierType(carrier.carrierName);
        setActiveSwitch(carrier.isActive);
        form.setFieldsValue({
          accountName: carrier.accountName,
          description: carrier.description,
          clientId: carrier.clientId,
          clientSecret: carrier.clientSecret,
          regions: carrier.regions
        });

        if (carrier.carrierName === CARRIERS.DHL_ECOM || carrier.carrierName === CARRIERS.UPS) {
          form.setFieldsValue({
            shipper_name: carrier.shipper?.name,
            shipper_company: carrier.shipper?.company,
            shipper_street1: carrier.shipper?.street1,
            shipper_street2: carrier.shipper?.street1,
            shipper_city: carrier.shipper?.city,
            shipper_state: carrier.shipper?.state,
            shipper_country: carrier.shipper?.country,
            shipper_postalCode: carrier.shipper?.postalCode,
            shipper_email: carrier.shipper?.email,
            shipper_phone: carrier.shipper?.phone
          });
        }

        if (carrier.carrierName === CARRIERS.DHL_ECOM) {
          const facilities = [];
          for (let i = 1; i < carrier.facilities!.length; i += 1) {
            facilities.push(carrier.facilities![i]);
          }
          const firstEle = carrier.facilities![0];

          const serviceIndex = [];
          for (let i = 0; i < carrier.services.length; i += 1) {
            const service = carrier.services[i];
            const index = DHL_ECOMMERCE_SERVICES.findIndex((ele) => ele.key === service.key);
            serviceIndex.push(index);
          }

          form.setFieldsValue({
            services: serviceIndex,
            testClientId: carrier.testClientId,
            testClientSecret: carrier.testClientSecret,
            'pickup-main': firstEle?.pickup,
            'facility-main': firstEle?.facility,
            facilities,
            'test-pickup-main': carrier.testFacilities![0].pickup,
            'test-facility-main': carrier.testFacilities![0].facility
          });
        }

        if (carrier.carrierName === CARRIERS.UPS) {
          const servicesIndex = [];
          for (let i = 0; i < carrier.services.length; i += 1) {
            const service = carrier.services[i];
            const index = UPS_SERVICES.findIndex((ele) => ele.key === service.key);
            servicesIndex.push(index);
          }

          form.setFieldsValue({
            accountNum: carrier.accountNum,
            accessKey: carrier.accessKey,
            services: servicesIndex
          });
        }

        if (carrier.carrierName === CARRIERS.USPS) {
          const servicesIndex = [];
          for (let i = 0; i < carrier.services.length; i += 1) {
            const service = carrier.services[i];
            const index = USPS_SERVICES.findIndex((ele) => ele.key === service.key);
            servicesIndex.push(index);
          }
          form.setFieldsValue({
            services: servicesIndex
          });
        }

        if (carrier.carrierName === CARRIERS.FEDEX) {
          const serviceIndex = [];
          for (let i = 0; i < carrier.services.length; i += 1) {
            const service = carrier.services[i];
            const index = FEDEX_SERVICES.findIndex((ele) => ele.key === service.key);
            serviceIndex.push(index);
          }
          form.setFieldsValue({
            services: serviceIndex,
            accountNum: carrier.accountNum,
            accessKey: carrier.accessKey,
            hubId: carrier.hubId,
            testClientId: carrier.testClientId,
            testClientSecret: carrier.testClientSecret,
            testAccountNum: carrier.testAccountNum,
            testAccessKey: carrier.testAccessKey,
            testHubId: carrier.testHubId
          });
        }

        if (carrier.carrierName === CARRIERS.RUI_YUN) {
          const servicesIndex = [];
          for (let i = 0; i < carrier.services.length; i += 1) {
            const service = carrier.services[i];
            const index = RUI_YUN_SERVICES.findIndex((ele) => ele.id === service.id);
            servicesIndex.push(index);
          }

          form.setFieldsValue({
            accountNum: carrier.accountNum,
            accessKey: carrier.accessKey,
            services: servicesIndex
          });
        }

        if (carrier.carrierName === CARRIERS.USPS3) {
          const servicesIndex = [];
          for (let i = 0; i < carrier.services.length; i += 1) {
            const service = carrier.services[i];
            const index = USPS3_SERVICES.findIndex((ele) => ele.id === service.id);
            servicesIndex.push(index);
          }

          form.setFieldsValue({
            services: servicesIndex
          });
        }
      } else {
        history.push(UI_ROUTES.CARRIERS);
      }
    } else {
      setCarrierType(carrierId);
      setActiveSwitch(true);
    }
  }, [carrierId, carriers, history, isNew, form]);

  const buildCarrierUpdateData = (values: any): CarrierUpdateData => {
    let carrierServices: Service[] = [];
    let services: Service[] = [];
    if (carrierType === CARRIERS.DHL_ECOM) {
      carrierServices = DHL_ECOMMERCE_SERVICES;
    } else if (carrierType === CARRIERS.UPS) {
      carrierServices = UPS_SERVICES;
    } else if (carrierType === CARRIERS.USPS) {
      carrierServices = USPS_SERVICES;
    } else if (carrierType === CARRIERS.FEDEX) {
      carrierServices = FEDEX_SERVICES;
    } else if (carrierType === CARRIERS.RUI_YUN) {
      carrierServices = RUI_YUN_SERVICES;
    } else if (carrierType === CARRIERS.USPS3) {
      carrierServices = USPS3_SERVICES;
    }
    if (carrierServices.length > 0) {
      services = values.services.map((inds: number) => carrierServices[inds]);
    }

    const facilities: Facility[] = [];
    if (carrierType === CARRIERS.DHL_ECOM) {
      facilities.push({
        pickup: values['pickup-main'],
        facility: values['facility-main']
      });
    }
    if (values.facilities) {
      values.facilities.forEach((ele: Facility) => {
        facilities.push(ele);
      });
    }

    const testFacilities: Facility[] = [];
    if (carrierType === CARRIERS.DHL_ECOM) {
      testFacilities.push({
        pickup: values['test-pickup-main'],
        facility: values['test-facility-main']
      });
    }

    const data: CarrierUpdateData = {
      carrierName: carrierType,
      accountName: values.accountName,
      description: values.description,
      clientId: values.clientId,
      clientSecret: values.clientSecret,
      services,
      regions: values.regions,
      isActive: true
    };

    if (carrierType === CARRIERS.DHL_ECOM) {
      data.facilities = facilities;
      data.testClientId = values.testClientId;
      data.testClientSecret = values.testClientSecret;
      data.testFacilities = testFacilities;
    }

    if (carrierType === CARRIERS.UPS || carrierType === CARRIERS.RUI_YUN) {
      data.accessKey = values.accessKey;
      data.accountNum = values.accountNum;
    }

    if (carrierType === CARRIERS.FEDEX) {
      data.accessKey = values.accessKey;
      data.accountNum = values.accountNum;
      data.hubId = values.hubId;
      data.testClientId = values.testClientId;
      data.testClientSecret = values.testClientSecret;
      data.testAccessKey = values.testAccessKey;
      data.testAccountNum = values.testAccountNum;
      data.testHubId = values.testHubId;
    }

    if (carrierType === CARRIERS.DHL_ECOM || carrierType === CARRIERS.UPS) {
      data.shipper = {
        name: values.shipper_name,
        company: values.shipper_company,
        street1: values.shipper_street1,
        street2: values.shipper_street2,
        city: values.shipper_city,
        state: values.shipper_state,
        country: values.shipper_country,
        postalCode: values.shipper_postalCode,
        email: values.shipper_email,
        phone: values.shipper_phone
      };
    }

    if (carrierType === CARRIERS.USPS3) {
      data.clientId = values.clientId;
      data.clientSecret = values.clientSecret;
    }

    return data;
  };

  const addCarrierHandler = () => {
    form.validateFields().then((values) => {
      const data = buildCarrierUpdateData(values);
      dispatch(createCarrierHandler(data));
    });
  };

  const updateClickedHandler = () => {
    form.validateFields().then((values) => {
      const data = buildCarrierUpdateData(values);
      if (curCarrier) {
        const carrierData: Carrier = {
          id: curCarrier?.id,
          ...data
        };
        carrierData.isActive = activeSwitch;
        dispatch(updateCarrierHandler(carrierData));
      }
    });
  };

  if (redirectToCarrierPage) {
    return <Redirect to={UI_ROUTES.CARRIERS} />;
  }

  return (
    <>
      <HeaderMenu title="Parcelspro" />
      <Content
        style={{
          padding: 24,
          margin: '24px 16px 0 16px',
          minHeight: '200px',
          background: '#fff'
        }}
      >
        <PageHeader
          onBack={() => history.push(`${UI_ROUTES.CARRIERS}`)}
          title={isNew ? '添加物流账号' : curCarrier?.accountName}
        />
        <Divider />
        <Tabs defaultActiveKey="1" size="large" type="card">
          <TabPane tab="账号信息" key="1">
            <div style={{ minWidth: '300px', maxWidth: '720px', margin: 'auto' }}>
              <Space size="large">
                <Image
                  width={200}
                  height={163.06}
                  src={getCarrierLogo(carrierType)}
                  alt={carrierType}
                  preview={false}
                />
                {!isNew && (
                  <div>
                    启用开关:{' '}
                    <Switch
                      checkedChildren="启用"
                      unCheckedChildren="停用"
                      checked={activeSwitch}
                      onClick={() => setActiveSwitch(!activeSwitch)}
                    />
                  </div>
                )}
              </Space>
              <Divider orientation="left" plain>
                账号信息
              </Divider>
              <Form layout="vertical" form={form}>
                <Space size={[10, 2]} style={{ width: '100%' }}>
                  <Form.Item
                    style={{ width: '350px' }}
                    label="账号名称"
                    name="accountName"
                    rules={[
                      { required: true, message: '请填写账号名称' },
                      { min: 3, message: '账号名称至少3位' }
                    ]}
                  >
                    <Input placeholder="账号名称" disabled={!activeSwitch} />
                  </Form.Item>
                  <Form.Item style={{ width: '350px' }} label="账号说明" name="description">
                    <Input placeholder="账号说明" disabled={!activeSwitch} />
                  </Form.Item>
                </Space>
                <Space size={[10, 2]} style={{ width: '100%' }}>
                  <Form.Item
                    style={{ width: '350px' }}
                    label="Client ID (Meter Number, userId)"
                    name="clientId"
                    rules={[
                      { required: true, message: 'Client ID 必须填写' },
                      { min: 3, message: 'Client ID至少3位' }
                    ]}
                  >
                    <Input placeholder="Client ID" disabled={!activeSwitch} />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '350px' }}
                    label="Client Secret (Password, bankerId)"
                    name="clientSecret"
                    rules={[
                      { required: true, message: 'Client Secret 必须填写' },
                      { min: 3, message: 'Client Secret至少3位' }
                    ]}
                  >
                    <Input.Password placeholder="Client Secret" disabled={!activeSwitch} />
                  </Form.Item>
                </Space>
                {(carrierType === CARRIERS.UPS ||
                  carrierType === CARRIERS.FEDEX ||
                  carrierType === CARRIERS.RUI_YUN) && (
                  <>
                    <Space size={[10, 2]} style={{ width: '100%' }}>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="Access Key (plantKey)"
                        name="accessKey"
                        rules={[{ required: true, message: 'Access Key必须填写' }]}
                      >
                        <Input placeholder="Access Key" disabled={!activeSwitch} />
                      </Form.Item>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="Account Number (plantId)"
                        name="accountNum"
                        rules={[
                          { required: true, message: 'Account Number必须填写' },
                          { min: 3, message: 'Account Number至少3位' }
                        ]}
                      >
                        <Input placeholder="Account Number" disabled={!activeSwitch} />
                      </Form.Item>
                    </Space>
                    {carrierType === CARRIERS.FEDEX && (
                      <Space size={[10, 2]} style={{ width: '100%' }}>
                        <Form.Item
                          style={{ width: '350px' }}
                          label="Hub ID"
                          name="hubId"
                          rules={[{ required: true, message: 'Hub ID必须填写' }]}
                        >
                          <Input placeholder="Hub ID" disabled={!activeSwitch} />
                        </Form.Item>
                      </Space>
                    )}
                  </>
                )}
                <Space size={[10, 2]} style={{ width: '100%' }}>
                  <Form.Item
                    style={{ width: '350px' }}
                    label="Services"
                    name="services"
                    rules={[{ required: true, message: '至少要有一个Service' }]}
                  >
                    <Select
                      mode="multiple"
                      optionLabelProp="label"
                      disabled={!activeSwitch}
                      allowClear
                    >
                      {carrierType === CARRIERS.DHL_ECOM &&
                        DHL_ECOMMERCE_SERVICES.map((service, index) => {
                          return (
                            <Option key={service.key} value={index} label={service.key}>
                              {`${service.key} - ${service.name}`}
                            </Option>
                          );
                        })}
                      {carrierType === CARRIERS.UPS &&
                        UPS_SERVICES.map((service, index) => {
                          return (
                            <Option key={service.name} value={index} label={service.name}>
                              {service.name}
                            </Option>
                          );
                        })}
                      {carrierType === CARRIERS.USPS &&
                        USPS_SERVICES.map((service, index) => {
                          return (
                            <Option key={service.name} value={index} label={service.name}>
                              {service.name}
                            </Option>
                          );
                        })}
                      {carrierType === CARRIERS.FEDEX &&
                        FEDEX_SERVICES.map((service, index) => {
                          return (
                            <Option key={service.name} value={index} label={service.name}>
                              {service.name}
                            </Option>
                          );
                        })}
                      {carrierType === CARRIERS.RUI_YUN &&
                        RUI_YUN_SERVICES.map((service, index) => {
                          return (
                            <Option
                              key={service.name}
                              value={index}
                              label={`${service.name}-${service.id}`}
                            >
                              {`${service.name}-${service.id}`}
                            </Option>
                          );
                        })}
                      {carrierType === CARRIERS.USPS3 &&
                        USPS3_SERVICES.map((service, index) => {
                          return (
                            <Option
                              key={service.name}
                              value={index}
                              label={`${service.key!}-${service.name}-${service.id}`}
                            >
                              {`${service.key!}-${service.name}-${service.id}`}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ width: '350px' }}
                    label="Regions"
                    name="regions"
                    rules={[{ required: true, message: '至少要有一个Region' }]}
                  >
                    <Select
                      mode="multiple"
                      optionLabelProp="label"
                      disabled={!activeSwitch}
                      allowClear
                    >
                      {Object.values(CARRIER_REGIONS).map((region) => {
                        return (
                          <Option key={region} value={region} label={region}>
                            {region}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Space>
                {carrierType === CARRIERS.DHL_ECOM && (
                  <>
                    <Space>
                      <Form.Item
                        name="pickup-main"
                        rules={[{ required: true, message: 'Pickup Code 必须填写' }]}
                      >
                        <Input placeholder="Pickup Code" disabled={!activeSwitch} />
                      </Form.Item>
                      <Form.Item
                        name="facility-main"
                        rules={[{ required: true, message: 'Facility Code 必须填写' }]}
                      >
                        <Input placeholder="Facility Code" disabled={!activeSwitch} />
                      </Form.Item>
                    </Space>
                    <Form.List name="facilities">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space
                              key={field.key}
                              style={{ display: 'flex', marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                name={[field.name, 'pickup']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Pickup Code 必须填写'
                                  }
                                ]}
                              >
                                <Input placeholder="Pickup Code" disabled={!activeSwitch} />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'facility']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Facility Code 必须填写'
                                  }
                                ]}
                              >
                                <Input placeholder="Facility Code" disabled={!activeSwitch} />
                              </Form.Item>
                              {activeSwitch && (
                                <MinusCircleOutlined
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              )}
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                              disabled={!activeSwitch}
                            >
                              Add Pickup Information
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </>
                )}
                {(carrierType === CARRIERS.DHL_ECOM || carrierType === CARRIERS.UPS) && (
                  <>
                    <Divider orientation="left" plain>
                      {carrierType === CARRIERS.DHL_ECOM && 'Return Address'}
                      {carrierType === CARRIERS.UPS && 'Shipper'}
                    </Divider>
                    <Space size={[10, 2]} style={{ width: '100%' }}>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="姓名"
                        name="shipper_name"
                        rules={[{ required: true, message: '姓名必须填写' }]}
                      >
                        <Input placeholder="姓名" disabled={!activeSwitch} />
                      </Form.Item>
                      <Form.Item style={{ width: '350px' }} label="公司名称" name="shipper_company">
                        <Input placeholder="公司名称" disabled={!activeSwitch} />
                      </Form.Item>
                    </Space>
                    <Space size={[10, 2]} style={{ width: '100%' }}>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="地址信息1"
                        name="shipper_street1"
                        rules={[{ required: true, message: '地址信息1必须填写' }]}
                      >
                        <Input placeholder="地址信息1" disabled={!activeSwitch} />
                      </Form.Item>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="地址信息2"
                        name="shipper_street2"
                      >
                        <Input placeholder="地址信息2" disabled={!activeSwitch} />
                      </Form.Item>
                    </Space>
                    <Space size={[10, 2]} style={{ width: '100%' }}>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="城市"
                        name="shipper_city"
                        rules={[{ required: true, message: '城市必须填写' }]}
                      >
                        <Input placeholder="城市" disabled={!activeSwitch} />
                      </Form.Item>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="省份"
                        name="shipper_state"
                        rules={[{ required: true, message: '省份必须填写' }]}
                      >
                        <Input placeholder="省份" disabled={!activeSwitch} />
                      </Form.Item>
                    </Space>
                    <Space size={[10, 2]} style={{ width: '100%' }}>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="国家"
                        name="shipper_country"
                        rules={[{ required: true, message: '国家必须填写' }]}
                      >
                        <Input placeholder="国家" disabled={!activeSwitch} />
                      </Form.Item>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="邮政编码"
                        name="shipper_postalCode"
                        rules={[{ required: true, message: '邮政编码必须填写' }]}
                      >
                        <Input placeholder="邮政编码" disabled={!activeSwitch} />
                      </Form.Item>
                    </Space>
                    <Space size={[10, 2]} style={{ width: '100%' }}>
                      <Form.Item style={{ width: '350px' }} label="邮箱地址" name="shipper_email">
                        <Input placeholder="邮箱地址" disabled={!activeSwitch} />
                      </Form.Item>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="电话手机"
                        name="shipper_phone"
                        rules={[{ required: true, message: '电话手机必须填写' }]}
                      >
                        <Input placeholder="电话手机" disabled={!activeSwitch} />
                      </Form.Item>
                    </Space>
                  </>
                )}
                {(carrierType === CARRIERS.DHL_ECOM || carrierType === CARRIERS.FEDEX) && (
                  <>
                    <Divider orientation="left" plain>
                      测试信息
                    </Divider>
                    <Space size={[10, 2]} style={{ width: '100%' }}>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="Test Client ID (Meter Number)"
                        name="testClientId"
                        rules={[
                          {
                            required: true,
                            message: 'Test Client ID 必须填写'
                          },
                          { min: 3, message: 'Test Client ID至少3位' }
                        ]}
                      >
                        <Input placeholder="Test Client ID" disabled={!activeSwitch} />
                      </Form.Item>
                      <Form.Item
                        style={{ width: '350px' }}
                        label="Test Client Secret (Password)"
                        name="testClientSecret"
                        rules={[
                          {
                            required: true,
                            message: 'Test Client Secret 必须填写'
                          },
                          { min: 3, message: 'Test Client Secret至少3位' }
                        ]}
                      >
                        <Input.Password placeholder="Test Client Secret" disabled={!activeSwitch} />
                      </Form.Item>
                    </Space>
                    {carrierType === CARRIERS.FEDEX && (
                      <>
                        <Space size={[10, 2]} style={{ width: '100%' }}>
                          <Form.Item
                            style={{ width: '350px' }}
                            label="Test Access Key"
                            name="testAccessKey"
                            rules={[
                              {
                                required: true,
                                message: 'Test Access Key必须填写'
                              }
                            ]}
                          >
                            <Input placeholder="Test Access Key" disabled={!activeSwitch} />
                          </Form.Item>
                          <Form.Item
                            style={{ width: '350px' }}
                            label="Test Account Number"
                            name="testAccountNum"
                            rules={[
                              {
                                required: true,
                                message: 'Test Account Number必须填写'
                              },
                              { min: 3, message: 'Test Account Number至少3位' }
                            ]}
                          >
                            <Input placeholder="Test Account Number" disabled={!activeSwitch} />
                          </Form.Item>
                        </Space>
                        <Space size={[10, 2]} style={{ width: '100%' }}>
                          <Form.Item
                            style={{ width: '350px' }}
                            label="Test Hub ID"
                            name="testHubId"
                            rules={[
                              {
                                required: true,
                                message: 'Test Hub ID必须填写'
                              }
                            ]}
                          >
                            <Input placeholder="Test Hub ID" disabled={!activeSwitch} />
                          </Form.Item>
                        </Space>
                      </>
                    )}
                    {carrierType === CARRIERS.DHL_ECOM && (
                      <Space>
                        <Form.Item
                          name="test-pickup-main"
                          rules={[
                            {
                              required: true,
                              message: 'Test Pickup Code 必须填写'
                            }
                          ]}
                        >
                          <Input placeholder="Test Pickup Code" disabled={!activeSwitch} />
                        </Form.Item>
                        <Form.Item
                          name="test-facility-main"
                          rules={[
                            {
                              required: true,
                              message: 'Test Facility Code 必须填写'
                            }
                          ]}
                        >
                          <Input placeholder="Test Facility Code" disabled={!activeSwitch} />
                        </Form.Item>
                      </Space>
                    )}
                  </>
                )}
                <Divider />
                <Form.Item>
                  {isNew ? (
                    <Button type="primary" onClick={addCarrierHandler} loading={loading}>
                      添加账号
                    </Button>
                  ) : (
                    <Button type="primary" onClick={updateClickedHandler} loading={loading}>
                      保存设置
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </div>
          </TabPane>
          {!isNew && curCarrier && (
            <TabPane tab="三方账号" key="2">
              <ThirdPartyPanel carrier={curCarrier} />
            </TabPane>
          )}
        </Tabs>
      </Content>
      <FooterComponent />
    </>
  );
};

export default EditCarrierPage;
