import { PageHeader } from '@ant-design/pro-components';
import { Alert, Button, Divider, Select, Space, Tabs } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { selectHwcClientWarehouses } from '../../../../../redux/hwc/hwcClientWarehouseSlice';
import { HwcClientWarehouse } from '../../../../../custom_types/redux-types';
import {
  manualPushMabangStockHandler,
  selectMabangStockLoading
} from '../../../../../redux/mabangErp/mabangErpStockSlice';
import {
  createScheduleJobHandler,
  searchSechduleJobHandler,
  selectSecduleJob,
  selectSecduleJobLoading,
  selectShowSchecudleJobModal,
  setShowSchecudleJobModal
} from '../../../../../redux/scheduleJobSlice';
import HwcScheduleJobElement from '../../../HwcWarehouse/components/HwcScheduleJobElement';
import HwcScheduleJobModal from '../../../HwcWarehouse/components/HwcScheduleJobModal';
import { ScheduleJob } from '../../../../../custom_types/scheduleJob';
import HwcMabangStockPushRecordsTab from './HwcMabangStockPushRecordsTab';

interface MabangErpStockPanelProps {
  hwcClientCode: string;
}

const MabangErpStockPanel = ({ hwcClientCode }: MabangErpStockPanelProps): ReactElement => {
  const dispatch = useDispatch();
  const loading = useSelector(selectMabangStockLoading);
  const scheduleJobs = useSelector(selectSecduleJob);
  const scheduleJobLoading = useSelector(selectSecduleJobLoading);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const showSchecudleJobModal = useSelector(selectShowSchecudleJobModal);
  const [selectedHwcClientWarehouse, setSelectedHwcClientWarehouse] = useState<HwcClientWarehouse>(
    hwcClientWarehouseList[0]
  );

  useEffect(() => {
    // Search HwcClientWarehouse Stock Push Schedule Job
    dispatch(
      searchSechduleJobHandler({
        clientId: selectedHwcClientWarehouse.hwcClientRef,
        clientWarehouseCode: selectedHwcClientWarehouse.code,
        jobType: 'stockpush_mabang'
      })
    );
  }, [dispatch, selectedHwcClientWarehouse.code, selectedHwcClientWarehouse.hwcClientRef]);

  const handleHwcClientWarehouseChange = (value: string) => {
    const hwcClientWarehouse = hwcClientWarehouseList.find((item) => item.id === value);
    if (hwcClientWarehouse) {
      setSelectedHwcClientWarehouse(hwcClientWarehouse);
    }
  };

  const disableErp = () => {
    if (!selectedHwcClientWarehouse) return true;
    if (!selectedHwcClientWarehouse.mabangAccesstoken) return true;
    return false;
  };

  const manualPushMabangStock = () => {
    dispatch(manualPushMabangStockHandler({ hwcClientWarehouseId: selectedHwcClientWarehouse.id }));
  };

  const showCreateScheduleJobModal = () => {
    dispatch(setShowSchecudleJobModal(true));
  };

  const createMabangStockPushScheduleJob = (values: any) => {
    console.log(values);
    const data: ScheduleJob = {
      id: '',
      jobType: 'stockpush_mabang',
      autoStart: values.autoStart,
      jobName: `stockpush_mabang:${hwcClientCode}:${selectedHwcClientWarehouse.code}:${values.jobName}`,
      jobHandler: '',
      params: {
        clientId: selectedHwcClientWarehouse.hwcClientRef,
        clientWarehouseCode: selectedHwcClientWarehouse.code
      },
      description: `Auto Ma Bang Sotck Push Job for ${hwcClientCode}:${selectedHwcClientWarehouse.code}`,
      status: 0,
      createBy: '',
      updatedBy: '',
      createTime: dayjs().toDate(),
      updateTime: dayjs().toDate(),
      startTime: { hour: values['start-hour'], minute: values['start-minute'] },
      repeatTimes: values.repeatTimes,
      repeatInterval: {
        hour: values.repeatTimes === 1 ? 0 : values['repeat-hour'],
        minute: values.repeatTimes === 1 ? 0 : values['repeat-minute']
      },
      tz: values.tz,
      clientId: selectedHwcClientWarehouse.hwcClientRef,
      clientWarehouseCode: selectedHwcClientWarehouse.code
    };
    dispatch(
      createScheduleJobHandler(data, {
        clientId: selectedHwcClientWarehouse.hwcClientRef,
        clientWarehouseCode: selectedHwcClientWarehouse.code,
        jobType: 'stockpush_mabang'
      })
    );
  };

  const closeModalHandler = () => {
    dispatch(setShowSchecudleJobModal(false));
  };

  return (
    <div>
      {showSchecudleJobModal && (
        <HwcScheduleJobModal onOk={createMabangStockPushScheduleJob} onCancel={closeModalHandler} />
      )}
      <PageHeader title="马帮ERP库存操作" />
      <Space size="large">
        <div>选择海外仓库：</div>
        <Select
          defaultValue={hwcClientWarehouseList[0].id}
          style={{ width: 200 }}
          options={hwcClientWarehouseList.map((item) => {
            return { value: item.id, label: item.code };
          })}
          onChange={handleHwcClientWarehouseChange}
        />
        {disableErp() && <Alert message="客户未授权该仓库马帮ERP" type="error" showIcon />}
      </Space>
      <Divider />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="库存同步任务" key="1">
          <>
            <Divider orientation="left">手动推送库存</Divider>
            <Space
              size="large"
              direction="horizontal"
              style={{ border: '1px solid #e8e8e8', padding: '20px', width: '100%' }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={manualPushMabangStock}
              >
                推送库存到马帮
              </Button>
            </Space>
            <Divider orientation="left">自动推送库存设置</Divider>
            <Space
              size="large"
              direction="horizontal"
              style={{ border: '1px solid #e8e8e8', padding: '20px', width: '100%' }}
            >
              <Button
                type="primary"
                loading={scheduleJobLoading}
                onClick={showCreateScheduleJobModal}
                icon={<PlusOutlined />}
              >
                添加马帮自动推送库存任务
              </Button>
              <Button
                icon={<SyncOutlined spin={loading} />}
                onClick={() =>
                  dispatch(
                    searchSechduleJobHandler({
                      clientId: selectedHwcClientWarehouse.hwcClientRef,
                      clientWarehouseCode: selectedHwcClientWarehouse.code,
                      jobType: 'stockpush_mabang'
                    })
                  )
                }
              >
                刷新
              </Button>
            </Space>
            {scheduleJobs &&
              scheduleJobs.map((job) => (
                <HwcScheduleJobElement
                  job={job}
                  searchQuery={{
                    clientId: selectedHwcClientWarehouse.hwcClientRef,
                    clientWarehouseCode: selectedHwcClientWarehouse.code,
                    jobType: 'stockpush_mabang'
                  }}
                />
              ))}
          </>
        </Tabs.TabPane>
        <Tabs.TabPane tab="库存推送记录" key="2">
          <div>库存推送记录</div>
          <HwcMabangStockPushRecordsTab
            clientId={selectedHwcClientWarehouse.hwcClientRef}
            clientWarehouseCode={selectedHwcClientWarehouse.code}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default MabangErpStockPanel;
