import React, { ReactElement } from 'react';
import { Warehouse } from '../../../../custom_types/wms-page';

const WarehouseTableColumns: any[] = [
  {
    title: '仓库名称',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: '联系方式',
    key: 'contact',
    render: (text: string, record: Warehouse): ReactElement => {
      return (
        <>
          <div>{record.company}</div>
          <div>{record.phone}</div>
          <div>{record.email}</div>
        </>
      );
    }
  },
  {
    title: '仓库地址',
    key: 'address',
    render: (text: string, record: Warehouse): ReactElement => {
      return (
        <>
          <div>{record.address1}</div>
          {record.address2 && <div>{record.address2}</div>}
          <div>
            {record.city}, {record.state}
          </div>
          {record.zip && <div>{record.zip}</div>}
          <div>{record.country}</div>
        </>
      );
    }
  },
  {
    title: '状态',
    key: 'active',
    render: (text: string, record: Warehouse): ReactElement => {
      return <div>{record.active ? '启用' : '禁用'}</div>;
    }
  }
];

export default WarehouseTableColumns;
