import React, { ReactElement, useEffect } from 'react';
import { Modal, Form, Input, Select, Space, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { HwcWarehouse } from '../../../../custom_types/redux-types';
import { Country, COUNTRY_NAMES } from '../../../../shared/utils/constants';
import {
  selectEditHwcWareHouseModal,
  selectHwcWarehouseModalLoading,
  updateHwcWarehouseHandler
} from '../../../../redux/hwcWarehouseSlice';

interface EditHwcWarehouseModalProps {
  record: HwcWarehouse | undefined;
  onCancel: () => void;
}

const { Option } = Select;

const HwcWarehouseEditModal = ({ record, onCancel }: EditHwcWarehouseModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(selectHwcWarehouseModalLoading);
  const showModal = useSelector(selectEditHwcWareHouseModal);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        contact: record.contact,
        phone: record.phone,
        email: record.email,
        address1: record.address1,
        address2: record.address2,
        city: record.city,
        state: record.state,
        zip: record.zip,
        country: record.country,
        mabangAppKey: record.mabangAppKey
      });
    }
  }, [form, record]);

  const okClickedHander = () => {
    if (!record) return;
    form
      .validateFields()
      .then((values) => {
        const data = {
          ...record,
          contact: values.contact,
          phone: values.phone,
          email: values.email,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          zip: values.zip,
          country: values.country,
          mabangAppKey: values.mabangAppKey
        };
        dispatch(updateHwcWarehouseHandler(record.id, data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeModalHandler = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      open={showModal}
      centered
      closable={false}
      title="修改仓库"
      onOk={okClickedHander}
      onCancel={closeModalHandler}
      okButtonProps={{
        loading
      }}
    >
      <Form form={form} layout="vertical">
        <Space size="large">
          <Form.Item
            label="联系人"
            name="contact"
            rules={[{ required: true, message: '联系人必须填' }]}
          >
            <Input placeholder="联系人" />
          </Form.Item>
          <Form.Item label="电话" name="phone">
            <Input placeholder="电话" />
          </Form.Item>
        </Space>
        <Form.Item label="邮箱" name="email">
          <Input placeholder="邮箱" />
        </Form.Item>
        <Form.Item
          label="地址1"
          name="address1"
          rules={[{ required: true, message: '地址1必须填' }]}
        >
          <Input placeholder="地址1" />
        </Form.Item>
        <Form.Item label="地址2" name="address2">
          <Input placeholder="地址2" />
        </Form.Item>
        <Space size="large">
          <Form.Item label="城市" name="city" rules={[{ required: true, message: '城市必须填' }]}>
            <Input placeholder="城市" />
          </Form.Item>
          <Form.Item label="州" name="state" rules={[{ required: true, message: '州必须填' }]}>
            <Input placeholder="州" />
          </Form.Item>
        </Space>
        <Space size="large">
          <Form.Item label="邮编" name="zip" rules={[{ required: true, message: '邮编必须填' }]}>
            <Input placeholder="邮编" />
          </Form.Item>
          <Form.Item
            label="国家"
            name="country"
            rules={[{ required: true, message: '国家必须填' }]}
          >
            <Select placeholder="国家" style={{ width: 180 }}>
              <Option value={Country.USA}>{COUNTRY_NAMES[Country.USA]}</Option>
              <Option value={Country.CHINA}>{COUNTRY_NAMES[Country.CHINA]}</Option>
            </Select>
          </Form.Item>
        </Space>
        <Divider orientation="left">ERP信息</Divider>
        <Form.Item label="马帮 appKey" name="mabangAppKey">
          <Input placeholder="马帮 appKey" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HwcWarehouseEditModal;
