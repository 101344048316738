import {
  BarChartOutlined,
  FileTextOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import React, { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { UI_ROUTES } from '../../../shared/utils/constants';

const LabelMenuList = (): ReactElement => {
  const location = useLocation();

  return (
    <Menu
      mode="inline"
      selectedKeys={[location.pathname]}
      style={{ height: '100%', borderRight: 0 }}
    >
      <Menu.Item
        key={`${UI_ROUTES.LABELS}${UI_ROUTES.USERS}`}
        icon={<TeamOutlined />}
      >
        <NavLink to={`${UI_ROUTES.LABELS}${UI_ROUTES.USERS}`}>客户中心</NavLink>
      </Menu.Item>
      <Menu.Item
        key={`${UI_ROUTES.LABELS}${UI_ROUTES.CARRIERS}`}
        icon={<FileTextOutlined />}
      >
        <NavLink to={`${UI_ROUTES.LABELS}${UI_ROUTES.CARRIERS}`}>
          物流设置
        </NavLink>
      </Menu.Item>
      <Menu.Item
        key={`${UI_ROUTES.LABELS}${UI_ROUTES.DATA}`}
        icon={<BarChartOutlined />}
      >
        <NavLink to={`${UI_ROUTES.LABELS}${UI_ROUTES.DATA}`}>面单数据</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default LabelMenuList;
