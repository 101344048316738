import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import {
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Result,
  Select,
  Space,
  Switch
} from 'antd';
import { selectCarriers } from '../../../../redux/carriersSlice';
import { Carrier, Facility, Service } from '../../../../custom_types/carrier-page';
import {
  CARRIERS,
  CarrierRateType,
  Currency,
  RATE_BASES,
  WeightUnit
} from '../../../../shared/utils/constants';
import {
  HwcWarehouseCarrier,
  HwcWarehouseCarrierData
} from '../../../../custom_types/overseawarehouse/hwc-carrier';
import {
  createHwcWarehouseCarrierHandler,
  selectHwcWarehouseCarrierModalLoading,
  selectShowHwcWarehouseCarrierModal,
  setShowHwcWarehouseCarrierModal,
  updateHwcWarehouseCarrierHandler
} from '../../../../redux/hwcWarehouseCarrierSlice';

interface HwcWarehouseCarrierModalProps {
  hwcWarehouseId: string;
  hwcWarehouseCarrier: HwcWarehouseCarrier | undefined;
}

const { Option } = Select;

const HwcWarehouseCarrierModal = ({
  hwcWarehouseId,
  hwcWarehouseCarrier
}: HwcWarehouseCarrierModalProps) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const carriers = useSelector(selectCarriers);
  const loading = useSelector(selectHwcWarehouseCarrierModalLoading);
  const [curCarrier, setCurCarrier] = useState<Carrier | undefined>(undefined);
  const [useThirdpary, setUseThirdparty] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const showModal = useSelector(selectShowHwcWarehouseCarrierModal);

  useEffect(() => {
    if (hwcWarehouseCarrier && carriers) {
      const findCarrier = carriers.find((ele) => ele.id === hwcWarehouseCarrier.carrierRef);
      const carrierIndex = carriers.findIndex((ele) => ele.id === hwcWarehouseCarrier.carrierRef);
      setCurCarrier(findCarrier);
      setIsActive(hwcWarehouseCarrier.active);
      setUseThirdparty(hwcWarehouseCarrier.thirdpartyPrice);

      const service = hwcWarehouseCarrier.service;
      const serviceIndex = findCarrier!.services.findIndex((ele) => ele.key === service.key);

      form.setFieldsValue({
        name: hwcWarehouseCarrier.name,
        code: hwcWarehouseCarrier.code,
        description: hwcWarehouseCarrier.description.join(','),
        region: hwcWarehouseCarrier.region,
        connectedAccount: carrierIndex,
        service: serviceIndex,
        rate: hwcWarehouseCarrier.rate.rate,
        ratetype: hwcWarehouseCarrier.rate.ratetype
      });

      if (hwcWarehouseCarrier.carrier === CARRIERS.DHL_ECOM) {
        const facility = hwcWarehouseCarrier.facility!;
        const facilityIndex = findCarrier!.facilities!.findIndex(
          (ele) => ele.pickup === facility.pickup
        );

        form.setFieldsValue({
          facility: facilityIndex
        });
      }
    } else {
      setCurCarrier(undefined);
      setIsActive(true);
      setUseThirdparty(false);
    }
  }, [dispatch, carriers, hwcWarehouseCarrier, form]);

  const generateHwcWarehouseCarrierData = (carrier: Carrier, values: any) => {
    console.log('generate hwc warehouse carrier data');
    console.log(values);
    const service = carrier.services[values.service];
    let facility: Facility | undefined;
    if (carrier.carrierName === CARRIERS.DHL_ECOM) {
      facility = carrier.facilities![values.facility];
    }
    const data: HwcWarehouseCarrierData = {
      carrier: carrier.carrierName,
      name: values.name,
      code: values.code,
      description: values.description ? values.description.split(',') : [],
      rate: {
        ratebase: RATE_BASES.ORDER,
        currency: Currency.USD,
        rate: values.rate,
        ratetype: values.ratetype
      },
      active: true,
      isSystem: true,
      refAccountName: carrier.accountName,
      service,
      facility,
      region: values.region,
      thirdpartyPrice: useThirdpary,
      carrierRef: carrier.id,
      hwcWarehouseRef: hwcWarehouseId
    };

    return data;
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('OK Clicked Handler');
        if (curCarrier) {
          const data = generateHwcWarehouseCarrierData(curCarrier, values);
          console.log('created Data');
          console.log(data);
          dispatch(createHwcWarehouseCarrierHandler(data));
          form.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        if (hwcWarehouseCarrier && curCarrier) {
          const data = generateHwcWarehouseCarrierData(curCarrier, values);
          const result: HwcWarehouseCarrier = {
            id: hwcWarehouseCarrier.id,
            ...data,
            thirdpartyPrice: useThirdpary,
            active: isActive
          };
          dispatch(updateHwcWarehouseCarrierHandler(result));
          form.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const carrierSelectedHandler = (index: number) => {
    setCurCarrier(carriers[index]);
  };

  const closeModalHandler = () => {
    dispatch(setShowHwcWarehouseCarrierModal(false));
    form.resetFields();
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      open={showModal}
      centered
      closable={false}
      okText={hwcWarehouseCarrier ? '保存设置' : '添加设置'}
      cancelText="取消"
      title={hwcWarehouseCarrier ? '编辑物流设置' : '添加物流设置'}
      onCancel={closeModalHandler}
      onOk={hwcWarehouseCarrier ? updateClickedHandler : okClickedHandler}
      okButtonProps={{
        disabled: !(carriers && carriers.length > 0),
        loading
      }}
    >
      {carriers && carriers.length > 0 ? (
        <Form form={form} layout="vertical">
          <Form.Item
            label="渠道名称"
            name="name"
            rules={[{ required: true, message: '账号名称必须填！' }]}
          >
            <Input placeholder="渠道名称" disabled={!isActive} />
          </Form.Item>
          <Form.Item
            label="渠道代码"
            name="code"
            rules={[{ required: true, message: '渠道代码必须填！' }]}
          >
            <Input placeholder="渠道代码" disabled={!isActive} />
          </Form.Item>
          <Form.Item
            label="选择要关联的账号"
            name="connectedAccount"
            rules={[{ required: true, message: '请选择要关联的账号！' }]}
          >
            <Select placeholder="关联账号" onChange={carrierSelectedHandler} disabled={!isActive}>
              {carriers.map((item: Carrier, index: number) => {
                return (
                  <Option key={item.id} value={index}>
                    {item.accountName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {curCarrier && curCarrier.services && (
            <Form.Item
              label="授权服务"
              name="service"
              rules={[{ required: true, message: '请选择一个服务！' }]}
            >
              <Select placeholder="授权服务" disabled={!isActive} optionLabelProp="label">
                {curCarrier.services.map((service: Service, index: number) => {
                  return (
                    <Option key={service.key} value={index} label={service.key}>
                      {service.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          {curCarrier && curCarrier.facilities && curCarrier.facilities.length > 0 && (
            <Form.Item
              label="操作中心"
              name="facility"
              rules={[{ required: true, message: '至少选择一个操作中心！' }]}
            >
              <Select placeholder="操作中心" disabled={!isActive} optionLabelProp="label">
                {curCarrier.facilities.map((item: Facility, index: number) => {
                  return (
                    <Option key={item.facility} value={index} label={item.facility}>
                      {item.facility}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          {curCarrier && curCarrier.regions && curCarrier.regions.length > 0 && (
            <Form.Item
              label="服务范围"
              name="region"
              rules={[{ required: true, message: '请选择一个服务范围！' }]}
            >
              <Select placeholder="服务范围" disabled={!isActive} optionLabelProp="label">
                {curCarrier.regions.map((item: string) => {
                  return (
                    <Option key={item} value={item} label={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="说明" name="description">
            <Input.TextArea autoSize placeholder="账号相关备注" disabled={!isActive} />
          </Form.Item>
          <Space size="large">
            <Form.Item name="thirdpartyPrice">
              <Checkbox
                checked={useThirdpary}
                onClick={() => setUseThirdparty(!useThirdpary)}
                disabled={!isActive}
              >
                三方价格
              </Checkbox>
            </Form.Item>
            {hwcWarehouseCarrier && (
              <Form.Item>
                <Switch
                  checkedChildren="启用"
                  unCheckedChildren="停用"
                  checked={isActive}
                  onClick={() => setIsActive(!isActive)}
                />
              </Form.Item>
            )}
          </Space>
          <Divider orientation="left" plain>
            费率
          </Divider>
          <>
            <Space size="large" align="baseline" style={{ width: '100%' }}>
              <Form.Item
                name="ratebase"
                initialValue={RATE_BASES.ORDER}
                rules={[{ required: true, message: '收费基准必须选' }]}
              >
                <Input style={{ width: '180px' }} type="text" disabled />
              </Form.Item>
              <Form.Item
                name="currency"
                initialValue={Currency.USD}
                rules={[{ required: true, message: '货币种类必须填' }]}
              >
                <Input style={{ width: '80px' }} type="text" disabled />
              </Form.Item>
            </Space>
            <Space size="large" align="baseline" style={{ width: '100%' }}>
              <Form.Item name="rate" rules={[{ required: true, message: '费率必须填！' }]}>
                <InputNumber
                  placeholder="费率"
                  min={0}
                  style={{ width: '100px' }}
                  step="0.01"
                  precision={2}
                  disabled={!isActive}
                />
              </Form.Item>
              <Form.Item name="ratetype" rules={[{ required: true, message: '请选择费率模式' }]}>
                <Radio.Group disabled={!isActive}>
                  {Object.values(CarrierRateType).map((item) => {
                    return (
                      <Radio key={item} value={item}>
                        {item}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </Space>
            <Divider />
          </>
        </Form>
      ) : (
        <Result status="warning" title="未找到可以关联的物流账号，请先创建物流账号" />
      )}
    </Modal>
  );
};

export default HwcWarehouseCarrierModal;
