import { Checkbox, Form, Input, Modal, Select, Space } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectSecduleJobLoading } from '../../../../../redux/scheduleJobSlice';

interface HwcPullOrderScheduleJobModalProps {
  onOk: (value: any) => void;
  onCancel: () => void;
}

const { Option } = Select;

const HwcPullOrderScheduleJobModal = ({ onOk, onCancel }: HwcPullOrderScheduleJobModalProps) => {
  const [form] = Form.useForm();
  const loading = useSelector(selectSecduleJobLoading);
  const repeatTimes = Form.useWatch('repeatTimes', form);
  const [autoStart, setAutoStart] = React.useState(false);

  const handleOk = () => {
    form.validateFields().then((values) => {
      values.autoStart = autoStart;
      console.log(values);
      onOk(values);
    });
  };

  const closeModalHandler = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      open
      centered
      title="自动拉取订单设置"
      onOk={handleOk}
      onCancel={closeModalHandler}
      okButtonProps={{
        loading
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="任务名称"
          name="jobName"
          rules={[{ required: true, message: '任务名称必须填' }]}
        >
          <Input placeholder="任务名称" />
        </Form.Item>
        <div>开始时间</div>
        <Space size="large">
          <Form.Item
            name="start-hour"
            rules={[{ required: true, message: '开始时间必须填' }]}
            initialValue={0}
          >
            <Select placeholder="小时" style={{ width: 120 }}>
              {Array.from({ length: 24 }, (_, i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>点</Form.Item>
          <Form.Item
            name="start-minute"
            rules={[{ required: true, message: '开始时间必须填' }]}
            initialValue={0}
          >
            <Select placeholder="分钟" style={{ width: 120 }}>
              {Array.from({ length: 60 }, (_, i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>分</Form.Item>
        </Space>
        <Form.Item
          label="重复次数"
          name="repeatTimes"
          rules={[{ required: true, message: '重复次数必须填' }]}
          initialValue={1}
        >
          <Select placeholder="重复次数" style={{ width: 120 }}>
            {Array.from({ length: 5 }, (_, i) => (
              <Option key={i + 1} value={i + 1}>
                {i + 1}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div>重复间隔</div>
        <Space size="large">
          <Form.Item
            name="repeat-hour"
            rules={[{ required: true, message: '重复间隔必须填' }]}
            initialValue={0}
          >
            <Select placeholder="小时" style={{ width: 120 }} disabled={repeatTimes <= 1}>
              {Array.from({ length: 24 }, (_, i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>小时</Form.Item>
          <Form.Item
            name="repeat-minute"
            rules={[{ required: true, message: '重复间隔必须填' }]}
            initialValue={0}
          >
            <Select placeholder="分钟" style={{ width: 120 }} disabled={repeatTimes <= 1}>
              {Array.from({ length: 60 }, (_, i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>分钟</Form.Item>
        </Space>
        <div>订单间隔</div>
        <Space size="large">
          <Form.Item
            name="order-hour"
            rules={[{ required: true, message: '订单间隔必须填' }]}
            initialValue={0}
          >
            <Select placeholder="小时" style={{ width: 120 }}>
              {Array.from({ length: 24 }, (_, i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>小时</Form.Item>
          <Form.Item
            name="order-minute"
            rules={[{ required: true, message: '订单间隔必须填' }]}
            initialValue={0}
          >
            <Select placeholder="分钟" style={{ width: 120 }}>
              {Array.from({ length: 60 }, (_, i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>分钟</Form.Item>
        </Space>
        <Form.Item
          label="时区"
          name="tz"
          rules={[{ required: true, message: '时区必须填' }]}
          initialValue="Asia/Shanghai"
        >
          <Select placeholder="时区" style={{ width: 200 }}>
            <Option value="Asia/Shanghai">Asia/Shanghai</Option>
            <Option value="America/New_York">America/New_York</Option>
            <Option value="America/Los_Angeles">America/Los_Angeles</Option>
          </Select>
        </Form.Item>
        <Space size="large">
          <div>是否自动启动</div>
          <Checkbox
            onChange={(e) => (e.target.checked ? setAutoStart(true) : setAutoStart(false))}
          />
        </Space>
      </Form>
    </Modal>
  );
};

export default HwcPullOrderScheduleJobModal;
