import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, MabangErpOrderState, RootState } from '../../custom_types/redux-types';
import {
  MabangPullOrderRecord,
  MannualForecastOrderRequest,
  MannualLabelOrderRequest
} from '../../custom_types/mabangErp/mabangErpOrder';
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import errorHandler from '../../shared/components/errorHandler';

const initialState: MabangErpOrderState = {
  mabangLoading: false,
  mabangPullOrderRecords: []
};

export const mabangErpOrderSlice = createSlice({
  name: 'mabangErpOrder',
  initialState,
  reducers: {
    setMabangLoading: (state, action: PayloadAction<boolean>) => {
      state.mabangLoading = action.payload;
    },
    setMabangPullOrderRecords: (state, action: PayloadAction<MabangPullOrderRecord[]>) => {
      state.mabangPullOrderRecords = action.payload;
    }
  }
});

export const { setMabangLoading, setMabangPullOrderRecords } = mabangErpOrderSlice.actions;

export const manualPullLabelOrdersHandler = (data: MannualLabelOrderRequest): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setMabangLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.MA_BANG_ERP}/manual-label-orders`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        console.log(response.data);
        // TODO: show results
        // update the pull order record
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setMabangLoading(false)));
  }
};

export const manualPullForecastOrdersHandler = (data: MannualForecastOrderRequest): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setMabangLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.MA_BANG_ERP}/manual-forecast-orders`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        console.log(response.data);
        // TODO: show results
        // update the pull order record
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setMabangLoading(false)));
  }
};

export const fetchMabangPullOrderRecodsHandler = (data: {
  clientId: string;
  clientWarehouseId: string;
}): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  console.log(data);
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setMabangLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.MA_BANG_ERP}/pull-order-records`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(setMabangPullOrderRecords(response.data));
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setMabangLoading(false)));
  }
};

export const selectMabangLoading = (state: RootState) => state.mabangErpOrder.mabangLoading;
export const selectMabangPullOrderRecords = (state: RootState) =>
  state.mabangErpOrder.mabangPullOrderRecords;

export default mabangErpOrderSlice.reducer;
