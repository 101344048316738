import { PageHeader } from '@ant-design/pro-components';
import { Alert, Divider, Select, Space, Tabs } from 'antd';
import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHwcClientWarehouses } from '../../../../../redux/hwc/hwcClientWarehouseSlice';
import { HwcClientWarehouse } from '../../../../../custom_types/redux-types';
import MabangErpLabelOrderPanel from './MabangErpLabelOrderPanel';
import MabangErpForecastOrderPanel from './MabangErpForecastOrderPanel';
import MabangErpPullOrderRecordsPanel from './MabangErpPullOrderRecordsPanel';

interface MabangErpOrderPanelProps {
  hwcClientCode: string;
}

const MabangErpOrderPanel = ({ hwcClientCode }: MabangErpOrderPanelProps): ReactElement => {
  const dispatch = useDispatch();
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const [selectedHwcClientWarehouse, setSelectedHwcClientWarehouse] = useState<HwcClientWarehouse>(
    hwcClientWarehouseList[0]
  );

  const handleHwcClientWarehouseChange = (value: string) => {
    const hwcClientWarehouse = hwcClientWarehouseList.find((item) => item.id === value);
    if (hwcClientWarehouse) {
      setSelectedHwcClientWarehouse(hwcClientWarehouse);
    }
  };

  const disableErp = () => {
    if (!selectedHwcClientWarehouse) return true;
    if (!selectedHwcClientWarehouse.mabangAccesstoken) return true;
    return false;
  };

  return (
    <div>
      <PageHeader title="马帮ERP海外仓订单操作" />
      <Space size="large">
        <div>选择海外仓库：</div>
        <Select
          defaultValue={hwcClientWarehouseList[0].id}
          style={{ width: 200 }}
          options={hwcClientWarehouseList.map((item) => {
            return { value: item.id, label: item.code };
          })}
          onChange={handleHwcClientWarehouseChange}
        />
        {disableErp() && <Alert message="客户未授权该仓库马帮ERP" type="error" showIcon />}
      </Space>
      <Divider />
      {!disableErp() && (
        <Tabs
          type="card"
          defaultActiveKey="1"
          items={[
            {
              label: '交运订单',
              key: '1',
              children: (
                <MabangErpLabelOrderPanel
                  hwcClientCode={hwcClientCode}
                  hwcClientWarehouse={selectedHwcClientWarehouse}
                />
              ),
              disabled: disableErp()
            },
            {
              label: '预报订单',
              key: '2',
              children: (
                <MabangErpForecastOrderPanel
                  hwcClientCode={hwcClientCode}
                  hwcClientWarehouse={selectedHwcClientWarehouse}
                />
              ),
              disabled: disableErp()
            },
            {
              label: '拉单记录',
              key: '3',
              children: (
                <MabangErpPullOrderRecordsPanel
                  clientId={selectedHwcClientWarehouse.hwcClientRef}
                  clientWarehouseId={selectedHwcClientWarehouse.id}
                />
              ),
              disabled: disableErp()
            }
          ]}
        />
      )}
    </div>
  );
};

export default MabangErpOrderPanel;
