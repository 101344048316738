import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, Popconfirm, Space, Table } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteHwcWarehouseFeeHandler,
  fetchHwcWarehouseFeeHanlder,
  selectCreateHwcWareHouseFeeModal,
  selectEditHwcWareHouseFeeModal,
  selectHwcWarehouseFees,
  setCreateHwcWarehouseFeeModal,
  setEditHwcWarehouseFeeModal
} from '../../../../redux/hwcWarehouseFeeSlice';
import { HwcWarehouseFee } from '../../../../custom_types/redux-types';
import HwcWarehouseFeeCreateModal from '../components/HwcWarehouseFeeCreateModal';
import { FEE_ITEM_DESCRIPTIONS, FEE_ITEM_NAMES } from '../../../../shared/utils/constants';
import HwcWarehouseFeeEditModal from '../components/HwcWarehouseFeeEditModal';

const HwcWarehouseFeePage = (): ReactElement => {
  const dispatch = useDispatch();
  const hwcWarehouseFees = useSelector(selectHwcWarehouseFees);
  const showModal = useSelector(selectCreateHwcWareHouseFeeModal);
  const showEditModal = useSelector(selectEditHwcWareHouseFeeModal);
  const [selectedHwcWarehouseFee, setSelectedHwcWarehouseFee] = React.useState<HwcWarehouseFee>();

  useEffect(() => {
    dispatch(fetchHwcWarehouseFeeHanlder());
  }, [dispatch]);

  const buildDataRecords = (item: HwcWarehouseFee) => {
    const dataRecords: Record<string, any>[] = [];
    dataRecords.push({
      name: FEE_ITEM_NAMES.baseFee,
      fee: item.baseFee,
      description: FEE_ITEM_DESCRIPTIONS.baseFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.countingFee,
      fee: item.countingFee,
      description: FEE_ITEM_DESCRIPTIONS.countingFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.skuFee,
      fee: item.skuFee,
      description: FEE_ITEM_DESCRIPTIONS.skuFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.packingFee,
      fee: item.packingFee,
      description: FEE_ITEM_DESCRIPTIONS.packingFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.labelFee,
      fee: item.labelFee,
      description: FEE_ITEM_DESCRIPTIONS.labelFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.returnFee,
      fee: item.returnFee,
      description: FEE_ITEM_DESCRIPTIONS.returnFee
    });
    for (let i = 0; i < item.storageFee.length; i += 1) {
      const sf = item.storageFee[i];
      dataRecords.push({
        name: `${FEE_ITEM_NAMES.storageFee}(${sf.volumnRange})`,
        fee: sf.fee,
        description: FEE_ITEM_DESCRIPTIONS.storageFee
      });
    }
    return dataRecords;
  };

  const colums = [
    {
      title: '费用类目',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '单价/USD',
      key: 'fee',
      dataIndex: 'fee'
    },
    {
      title: '备注',
      key: 'description',
      dataIndex: 'description'
    }
  ];

  const editClickedHandler = (item: HwcWarehouseFee) => {
    dispatch(setEditHwcWarehouseFeeModal(true));
    setSelectedHwcWarehouseFee(item);
  };

  const editModalCloseHandler = () => {
    setSelectedHwcWarehouseFee(undefined);
    dispatch(setEditHwcWarehouseFeeModal(false));
  };

  return (
    <div>
      {showModal && <HwcWarehouseFeeCreateModal />}
      {showEditModal && selectedHwcWarehouseFee && (
        <HwcWarehouseFeeEditModal
          hwcWarehouseFee={selectedHwcWarehouseFee}
          onCancel={editModalCloseHandler}
        />
      )}
      <PageHeader
        title="仓库操作费模板"
        extra={[
          <Button
            icon={<SyncOutlined spin={false} />}
            onClick={() => dispatch(fetchHwcWarehouseFeeHanlder())}
          />,
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(setCreateHwcWarehouseFeeModal(true))}
          >
            添加费用模板
          </Button>
        ]}
      />
      {hwcWarehouseFees.map((item) => {
        const dataRecords = buildDataRecords(item);
        return (
          <>
            <Divider />
            <Table<Record<string, any>>
              title={() => (
                <Space size="large">
                  {`重量${item.weightRange}${item.weightUnit}`}
                  <Button
                    key="编辑"
                    size="small"
                    type="link"
                    onClick={() => editClickedHandler(item)}
                  >
                    编辑
                  </Button>
                  <Popconfirm
                    key="确认删除该仓库"
                    title="确认删除该仓库?"
                    placement="topRight"
                    onConfirm={() => dispatch(deleteHwcWarehouseFeeHandler(item.id))}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Button key="删除" size="small" type="link">
                      删除
                    </Button>
                  </Popconfirm>
                </Space>
              )}
              columns={colums}
              dataSource={dataRecords}
            />
          </>
        );
      })}
    </div>
  );
};

export default HwcWarehouseFeePage;
