import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '@ant-design/pro-components';
import { Button, Table, Tag } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  fetchMabangPullOrderRecodsHandler,
  selectMabangLoading,
  selectMabangPullOrderRecords
} from '../../../../../redux/mabangErp/mabangErpOrderSlice';
import { MabangPullOrderRecord } from '../../../../../custom_types/mabangErp/mabangErpOrder';

interface MabangErpPullOrderRecordsPanelProps {
  clientId: string;
  clientWarehouseId: string;
}

const MabangErpPullOrderRecordsPanel = ({
  clientId,
  clientWarehouseId
}: MabangErpPullOrderRecordsPanelProps) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectMabangLoading);
  const records = useSelector(selectMabangPullOrderRecords);

  useEffect(() => {
    dispatch(fetchMabangPullOrderRecodsHandler({ clientId, clientWarehouseId }));
  }, [dispatch, clientId, clientWarehouseId]);

  const columns = [
    {
      title: '日期',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (date: Date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      title: '任务名称',
      key: 'jobName',
      dataIndex: 'jobName'
    },
    {
      title: '任务类型',
      key: 'orderType',
      dataIndex: 'orderType'
    },
    {
      title: '订单间隔',
      key: 'interval',
      dataIndex: 'interval',
      render: (_: any, record: MabangPullOrderRecord) => {
        return `${record.startDate} ~ ${record.endDate}`;
      }
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (status: number) => {
        return status === 0 ? <Tag color="green">成功</Tag> : <Tag color="red">失败</Tag>;
      }
    },
    {
      title: '任务用时',
      key: 'processingTime',
      dataIndex: 'processingTime'
    },
    {
      title: '任务信息',
      key: 'message',
      dataIndex: 'message'
    }
  ];

  return (
    <div>
      <PageHeader
        title=""
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() =>
              dispatch(fetchMabangPullOrderRecodsHandler({ clientId, clientWarehouseId }))
            }
          />
        ]}
      />
      <Table<MabangPullOrderRecord>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={records}
        loading={loading}
      />
    </div>
  );
};

export default MabangErpPullOrderRecordsPanel;
