import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Popconfirm, Space, Switch, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { CheckCircleTwoTone, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import NoData from '../../../../shared/components/NoData';
import { getCarrierLogo } from '../../../../shared/utils/logo.helper';
import { HwcWarehouseCarrier } from '../../../../custom_types/overseawarehouse/hwc-carrier';
import { FeeRate } from '../../../../custom_types/platform-carriers-tyes';
import { displayRate } from '../../../../shared/utils/helpers';
import {
  deleteHwcWarehouseCarrierHandler,
  fetchHwcWarehouseCarriersHandler,
  selectHwcWarehouseCarriers,
  selectHwcWarehouseCarriersLoading,
  setShowHwcWarehouseCarrierModal,
  updateHwcWarehouseCarrierHandler
} from '../../../../redux/hwcWarehouseCarrierSlice';
import HwcWarehouseCarrierModal from './HwcWarehouseCarrierModal';

interface HwcWarehouseCarrierPanelProps {
  hwcWarehouseId: string;
}

const HwcWarehouseCarrierPanel = ({ hwcWarehouseId }: HwcWarehouseCarrierPanelProps) => {
  const dispatch = useDispatch();
  const hwcWarehouseCarriers = useSelector(selectHwcWarehouseCarriers);
  const loading = useSelector(selectHwcWarehouseCarriersLoading);
  const [curCarrier, setCurCarrier] = useState<HwcWarehouseCarrier | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchHwcWarehouseCarriersHandler(hwcWarehouseId));
  }, [dispatch, hwcWarehouseId]);

  const addCarrierClickedHandler = () => {
    setCurCarrier(undefined);
    dispatch(setShowHwcWarehouseCarrierModal(true));
  };

  const editCarrierClickedHandler = (record: HwcWarehouseCarrier) => {
    setCurCarrier(record);
    dispatch(setShowHwcWarehouseCarrierModal(true));
  };

  const statusChangeHandler = async (active: boolean, value: HwcWarehouseCarrier) => {
    const data = { ...value, active };
    dispatch(updateHwcWarehouseCarrierHandler(data));
  };

  const columns: any[] = [
    {
      title: '',
      key: 'logo',
      render: (text: string, record: HwcWarehouseCarrier) => {
        const logo = getCarrierLogo(record.carrier);
        return <img style={{ width: '60px', height: '60px' }} src={logo} alt={record.carrier} />;
      }
    },
    {
      title: '物流公司',
      dataIndex: 'carrier',
      key: 'carrier'
    },
    {
      title: '账号渠道',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '渠道代码',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: '账号说明',
      dataIndex: 'description',
      key: 'description',
      render: (descriptions: string[]): ReactElement => {
        return (
          <ul>
            {descriptions.map((desc) => {
              return <li key={desc}>{desc}</li>;
            })}
          </ul>
        );
      }
    },
    {
      title: '物流服务',
      dataIndex: 'servies',
      key: 'services',
      render: (text: string, record: HwcWarehouseCarrier): string => {
        return `${record.service.name}-${record.service.id}-${record.service.key}`;
      }
    },
    {
      title: '服务范围',
      dataIndex: 'region',
      key: 'region'
    },
    {
      title: '三方价格',
      dataIndex: 'thirdpartyPrice',
      key: 'thirdpartyPrice',
      render: (thirdpartyPrice: boolean) => {
        return thirdpartyPrice ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null;
      }
    },
    {
      title: '费率',
      dataIndex: 'rate',
      key: 'rate',
      render: (rate: FeeRate, record: HwcWarehouseCarrier) => {
        if (record.rate) {
          return <div>{displayRate(rate)}</div>;
        }
        return '-';
      }
    },
    {
      title: '状态',
      dataIndex: 'active',
      key: 'active',
      render: (value: boolean, record: HwcWarehouseCarrier) => (
        <Switch
          checkedChildren="启用"
          unCheckedChildren="停用"
          defaultChecked
          checked={value}
          onChange={() => statusChangeHandler(!value, record)}
        />
      )
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      render: (text: string, record: HwcWarehouseCarrier) => (
        <Space size={0} split={<Divider type="vertical" />}>
          <Button size="small" type="link" onClick={() => editCarrierClickedHandler(record)}>
            编辑
          </Button>
          <Popconfirm
            title="确认删除该账号?"
            placement="topRight"
            onConfirm={() =>
              dispatch(deleteHwcWarehouseCarrierHandler(record.id, record.hwcWarehouseRef))
            }
            okText="确定"
            cancelText="取消"
          >
            <Button size="small" type="link">
              删除
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div>
      <HwcWarehouseCarrierModal hwcWarehouseId={hwcWarehouseId} hwcWarehouseCarrier={curCarrier} />
      <PageHeader
        title=""
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() => dispatch(fetchHwcWarehouseCarriersHandler(hwcWarehouseId))}
          />,
          <Button key="1" type="primary" icon={<PlusOutlined />} onClick={addCarrierClickedHandler}>
            添加物流账号
          </Button>
        ]}
      />
      <Table<HwcWarehouseCarrier>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={hwcWarehouseCarriers}
        pagination={false}
        loading={loading}
        locale={{
          emptyText: <NoData />
        }}
        scroll={{ x: 1000 }}
      />
    </div>
  );
};

export default HwcWarehouseCarrierPanel;
