import React, { ReactElement, useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Radio, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { HWC_CLIENT_BILL_TYPE } from '../../../../shared/utils/constants';
import {
  createHwcClientBillHandler,
  selectModalLoading,
  selectShowModal,
  setShowModal
} from '../../../../redux/hwc/hwcClientBillSlice';
import { HwcBillCreate } from '../../../../custom_types/overseawarehouse/hwc-bill';

interface HwcClienBillCreateModalProps {
  warehouseId: string;
}

const HwcClientBillCreateModal = ({ warehouseId }: HwcClienBillCreateModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const billType = Form.useWatch('type', form);
  const showModal = useSelector(selectShowModal);
  const modalLoading = useSelector(selectModalLoading);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const cancelClickedHandler = () => {
    form.resetFields();
    dispatch(setShowModal(false));
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        const data: HwcBillCreate = {
          code: '-',
          date: new Date(),
          remark: values.remark,
          clientId: warehouseId,
          type: '',
          ck_num: '',
          operationFee: [],
          shippingFee: 0,
          storageFee: 0,
          deposit: 0,
          balance: 0,
          otherFee: 0
        };
        if (billType === HWC_CLIENT_BILL_TYPE.DAI_FA) {
          data.type = HWC_CLIENT_BILL_TYPE.DAI_FA;
          data.orderId = values.orderId;
          data.ck_num = values.ck_num;
          data.operationFee = [
            { name: '入库费', amount: values.handleFee ? values.handleFee : 0 },
            { name: '清点费', amount: values.skuFee ? values.skuFee : 0 },
            { name: '包装费', amount: values.packingFee ? values.packingFee : 0 }
          ];
          data.shippingFee = values.shippingFee ? values.shippingFee : 0;
          data.storageFee = 0;
          data.deposit = 0;
          data.balance = 0;
          data.otherFee = 0;
        } else if (billType === HWC_CLIENT_BILL_TYPE.SHIPPING) {
          data.type = HWC_CLIENT_BILL_TYPE.SHIPPING;
          data.ck_num = '-';
          data.operationFee = [];
          data.shippingFee = values.shippingFee;
          data.storageFee = 0;
          data.deposit = 0;
          data.balance = 0;
          data.otherFee = 0;
        } else if (billType === HWC_CLIENT_BILL_TYPE.STORAGE) {
          data.type = HWC_CLIENT_BILL_TYPE.STORAGE;
          data.ck_num = values.ck_num;
          data.operationFee = [];
          data.shippingFee = 0;
          data.storageFee = values.storageFee;
          data.deposit = 0;
          data.balance = 0;
          data.otherFee = 0;
        } else if (billType === HWC_CLIENT_BILL_TYPE.OTHER) {
          data.type = HWC_CLIENT_BILL_TYPE.OTHER;
          data.orderId = values.orderId;
          data.ck_num = values.ck_num ? values.ck_num : '-';
          data.operationFee = [];
          data.shippingFee = 0;
          data.storageFee = 0;
          data.deposit = 0;
          data.balance = 0;
          data.otherFee = values.otherFee;
        } else {
          notification.error({ message: 'Bill type is not valid' });
          return;
        }
        dispatch(createHwcClientBillHandler(data));
        form.resetFields();
      })
      .catch(() => {});
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      centered
      closable={false}
      open={showModal}
      okText="添加账单"
      cancelText="取消"
      title="添加账单信息"
      onCancel={cancelClickedHandler}
      onOk={okClickedHandler}
      okButtonProps={{ loading: modalLoading }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="账单类型"
          initialValue={1}
          name="type"
          rules={[{ required: true, message: '请选择账单类型！' }]}
        >
          <Radio.Group name="radiogroup">
            <Radio value={HWC_CLIENT_BILL_TYPE.DAI_FA}>{HWC_CLIENT_BILL_TYPE.DAI_FA}</Radio>
            <Radio value={HWC_CLIENT_BILL_TYPE.SHIPPING}>{HWC_CLIENT_BILL_TYPE.SHIPPING}</Radio>
            <Radio value={HWC_CLIENT_BILL_TYPE.STORAGE}>{HWC_CLIENT_BILL_TYPE.STORAGE}</Radio>
            <Radio value={HWC_CLIENT_BILL_TYPE.OTHER}>{HWC_CLIENT_BILL_TYPE.OTHER}</Radio>
          </Radio.Group>
        </Form.Item>
        {billType === HWC_CLIENT_BILL_TYPE.DAI_FA && (
          <>
            <Form.Item
              label="代发单号"
              name="orderId"
              rules={[{ required: true, message: '代发单号必须填!' }]}
            >
              <Input.TextArea placeholder="代发单号" autoSize />
            </Form.Item>
            <Form.Item
              label="仓库代码"
              name="ck_num"
              rules={[{ required: true, message: '仓库代码必须填!' }]}
            >
              <Input.TextArea placeholder="仓库代码" autoSize />
            </Form.Item>
            <Form.Item
              label="入库费金额($美金)"
              name="handleFee"
              rules={[{ required: true, message: '入库费金额必须填！' }]}
            >
              <InputNumber
                style={{ width: 'auto' }}
                autoFocus
                defaultValue={0}
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
            <Form.Item
              label="清点费金额($美金)"
              name="skuFee"
              rules={[{ required: true, message: '清点费金额必须填！' }]}
            >
              <InputNumber
                style={{ width: 'auto' }}
                autoFocus
                defaultValue={0}
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
            <Form.Item
              label="包装费金额($美金)"
              name="packingFee"
              rules={[{ required: false, message: '包装费金额必须填！' }]}
            >
              <InputNumber
                style={{ width: 'auto' }}
                autoFocus
                defaultValue={0}
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
            <Form.Item
              label="物流费金额($美金)"
              name="shippingFee"
              rules={[{ required: false, message: '物流费金额必须填！' }]}
            >
              <InputNumber
                style={{ width: 'auto' }}
                autoFocus
                defaultValue={0}
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </>
        )}
        {billType === HWC_CLIENT_BILL_TYPE.SHIPPING && (
          <Form.Item
            label="物流费金额($美金)"
            name="shippingFee"
            rules={[{ required: true, message: '物流费金额必须填！' }]}
          >
            <InputNumber
              style={{ width: 'auto' }}
              autoFocus
              defaultValue={0}
              min={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />
          </Form.Item>
        )}
        {billType === HWC_CLIENT_BILL_TYPE.STORAGE && (
          <>
            <Form.Item
              label="仓库代码"
              name="ck_num"
              rules={[{ required: true, message: '仓库代码必须填!' }]}
            >
              <Input.TextArea placeholder="仓库代码" autoSize />
            </Form.Item>
            <Form.Item
              label="仓储费金额($美金)"
              name="storageFee"
              rules={[{ required: true, message: '仓储费金额必须填！' }]}
            >
              <InputNumber
                style={{ width: 'auto' }}
                autoFocus
                defaultValue={0}
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </>
        )}
        {billType === HWC_CLIENT_BILL_TYPE.OTHER && (
          <>
            <Form.Item
              label="仓库代码"
              name="ck_num"
              rules={[{ required: false, message: '仓库代码必须填!' }]}
            >
              <Input.TextArea placeholder="仓库代码" autoSize />
            </Form.Item>
            <Form.Item
              label="其他费用金额($美金)"
              name="otherFee"
              rules={[{ required: true, message: '其他费用金额必须填！' }]}
            >
              <InputNumber
                style={{ width: 'auto' }}
                autoFocus
                defaultValue={0}
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          label="账单说明"
          name="remark"
          rules={[
            { required: billType === HWC_CLIENT_BILL_TYPE.OTHER, message: '账单说明必须填!' }
          ]}
        >
          <Input.TextArea placeholder="账单说明" autoSize />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HwcClientBillCreateModal;
