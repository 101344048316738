import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AppThunk,
  HwcWarehouse,
  HwcWarehouseData,
  HwcWarehouseState,
  RootState
} from '../custom_types/redux-types';
import axios from '../shared/utils/axios.base';
import errorHandler from '../shared/components/errorHandler';
import { SERVER_ROUTES } from '../shared/utils/constants';

const initialState: HwcWarehouseState = {
  hwcWarehouses: [],
  loading: false,
  showCreateHwcWarehouseModal: false,
  showEditHwcWarehouseModal: false,
  hwcWarehouseModalLoading: false
};

export const hwcWarehouseSlice = createSlice({
  name: 'hwcWarehouse',
  initialState,
  reducers: {
    setHwcWarehouses: (state, action: PayloadAction<HwcWarehouse[]>) => {
      state.hwcWarehouses = action.payload;
    },
    setHwcWarehouseLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCreateHwcWarehouseModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateHwcWarehouseModal = action.payload;
    },
    setEditHwcWarehouseModal: (state, action: PayloadAction<boolean>) => {
      state.showEditHwcWarehouseModal = action.payload;
    },
    setHwcWarehouseLoadingModal: (state, action: PayloadAction<boolean>) => {
      state.hwcWarehouseModalLoading = action.payload;
    }
  }
});

export const {
  setHwcWarehouses,
  setHwcWarehouseLoading,
  setCreateHwcWarehouseModal,
  setEditHwcWarehouseModal,
  setHwcWarehouseLoadingModal
} = hwcWarehouseSlice.actions;

export const fetchHwcWarehouseHanlder = (): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseLoading(true));
    try {
      const response = await axios.get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSES}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setHwcWarehouses(response.data));
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setHwcWarehouseLoading(false));
    }
  }
};

export const createHwcWarehouseHandler = (data: HwcWarehouseData): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseLoading(true));
    try {
      await axios.post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSES}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setCreateHwcWarehouseModal(false));
      dispatch(fetchHwcWarehouseHanlder());
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setHwcWarehouseLoading(false));
    }
  }
};

export const updateHwcWarehouseHandler = (id: string, data: HwcWarehouse): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseLoadingModal(true));
    try {
      await axios.put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSES}/${id}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setEditHwcWarehouseModal(false));
      dispatch(fetchHwcWarehouseHanlder());
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setHwcWarehouseLoadingModal(false));
    }
  }
};

export const deleteHwcWarehouseHandler = (id: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseLoadingModal(true));
    try {
      await axios.delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSES}/${id}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(fetchHwcWarehouseHanlder());
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setHwcWarehouseLoadingModal(false));
    }
  }
};

export const selectHwcWarehouses = (state: RootState) => state.hwcWarehouses.hwcWarehouses;
export const selectHwcWarehouseLoading = (state: RootState) => state.hwcWarehouses.loading;
export const selectCreateHwcWareHouseModal = (state: RootState) =>
  state.hwcWarehouses.showCreateHwcWarehouseModal;
export const selectEditHwcWareHouseModal = (state: RootState) =>
  state.hwcWarehouses.showEditHwcWarehouseModal;
export const selectHwcWarehouseModalLoading = (state: RootState) =>
  state.hwcWarehouses.hwcWarehouseModalLoading;

export default hwcWarehouseSlice.reducer;
