import { Menu, Space } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import LocaleButton from './LocaleButton';
import { selectCurUser } from '../../../redux/user/userSlice';

import './HeaderMenu.css';
import AvatarDropdown from './AvatarDropdown';
import Logo from './Logo';

interface HeaderMenuProps {
  title: string;
}

const HeaderMenu = ({ title }: HeaderMenuProps): ReactElement => {
  const curUser = useSelector(selectCurUser);
  const history = useHistory();

  return (
    <Header className="site-header">
      <Logo text={title} isLogin={false} />
      {curUser && (
        <Space className="left">
          <Menu style={{ marginLeft: '20px' }} theme="dark" mode="horizontal">
            <Menu.Item style={{ marginTop: '10px' }} key="1" onClick={() => history.push('/')}>
              <HomeOutlined style={{ fontSize: '25px' }} />
            </Menu.Item>
          </Menu>
        </Space>
      )}
      <Space className="right" style={{ marginRight: '24px' }}>
        <LocaleButton />
        {curUser && <AvatarDropdown name={curUser.name} />}
      </Space>
    </Header>
  );
};

export default HeaderMenu;
