import { Divider, Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  PasswordFormValue,
  UserPasswordUpdate
} from '../../../custom_types/labels/label-profile-types';
import { UserBasicInfo } from '../../../custom_types/profile-page';
import { selectUsersList, updateUserPasswordHandler } from '../../../redux/user/userSlice';
import PasswordPanel from '../../../shared/components/PasswordPanel';
import { UI_ROUTES } from '../../../shared/utils/constants';
import HeaderMenu from '../../Layout/components/HeaderMenu';
import LabelUsersProfilePanel from '../../Labels/LabelUsers/components/LabelUsersProfilePanel';
import UserInfoPanel from '../components/UserInfoPanel';

const { Content } = Layout;

const UserManagePage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const usersList = useSelector(selectUsersList);
  const [selectedUser, setSelectedUser] = useState<UserBasicInfo | undefined>(undefined);

  useEffect(() => {
    const curUser = usersList.find((ele) => ele.id === userId);
    if (curUser) {
      setSelectedUser(curUser);
    } else {
      history.push(UI_ROUTES.USERS);
    }
  }, [history, userId, usersList]);

  const passwordUpdatedHandler = (id: string, values: PasswordFormValue) => {
    const data: UserPasswordUpdate = {
      id,
      ...values
    };
    dispatch(updateUserPasswordHandler(data));
  };

  return (
    <>
      <HeaderMenu title="Parcelspro" />
      <Content
        style={{
          padding: 24,
          margin: '24px 16px 0 16px',
          minHeight: '200px',
          background: '#fff'
        }}
      >
        <PageHeader title="编辑系统用户" onBack={() => history.push(UI_ROUTES.USERS)} />
        <Divider />
        {selectedUser && (
          <LabelUsersProfilePanel
            panels={[
              <UserInfoPanel data={selectedUser} />,
              <PasswordPanel
                isSelf={false}
                onSumbit={(values: PasswordFormValue) =>
                  passwordUpdatedHandler(selectedUser.id, values)
                }
              />
            ]}
          />
        )}
      </Content>
    </>
  );
};

export default UserManagePage;
