import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState, WarehouseState } from '../custom_types/redux-types';
import { CreateWarehouseData, Warehouse } from '../custom_types/wms-page';
import axios from '../shared/utils/axios.base';
import errorHandler from '../shared/components/errorHandler';
import { SERVER_ROUTES } from '../shared/utils/constants';

const initialState: WarehouseState = {
  warehouses: [],
  loading: false,
  createWarehouseModal: false,
  editWarehouseModal: false,
  warehouseModalLoading: false
};

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    setWarehouses: (state, action: PayloadAction<Warehouse[]>) => {
      state.warehouses = action.payload;
    },
    setWarehouseLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCreateWarehouseModal: (state, action: PayloadAction<boolean>) => {
      state.createWarehouseModal = action.payload;
    },
    setEditWarehouseModal: (state, action: PayloadAction<boolean>) => {
      state.editWarehouseModal = action.payload;
    },
    setWarehouseLoadingModal: (state, action: PayloadAction<boolean>) => {
      state.warehouseModalLoading = action.payload;
    }
  }
});

export const {
  setWarehouses,
  setWarehouseLoading,
  setCreateWarehouseModal,
  setEditWarehouseModal,
  setWarehouseLoadingModal
} = warehouseSlice.actions;

export const fetchWarehouseHanlder = (): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setWarehouseLoading(true));
    try {
      const response = await axios.get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.WAREHOUSES}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setWarehouses(response.data));
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setWarehouseLoading(false));
    }
  }
};

export const createWarehouseHandler = (data: CreateWarehouseData): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setWarehouseLoading(true));
    try {
      await axios.post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.WAREHOUSES}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setCreateWarehouseModal(false));
      dispatch(fetchWarehouseHanlder());
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setWarehouseLoading(false));
    }
  }
};

export const updateWarehouseHandler = (id: string, data: Warehouse): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setWarehouseLoadingModal(true));
    try {
      await axios.put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.WAREHOUSES}/${id}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setEditWarehouseModal(false));
      dispatch(fetchWarehouseHanlder());
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setWarehouseLoadingModal(false));
    }
  }
};

export const selectWarehouses = (state: RootState) => state.warehouses.warehouses;
export const selectWarehouseLoading = (state: RootState) => state.warehouses.loading;
export const selectCreateWareHouseModal = (state: RootState) =>
  state.warehouses.createWarehouseModal;
export const selectEditWareHouseModal = (state: RootState) => state.warehouses.editWarehouseModal;
export const selectWarehouseModalLoading = (state: RootState) =>
  state.warehouses.warehouseModalLoading;

export default warehouseSlice.reducer;
