import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, MabangErpStockState, RootState } from '../../custom_types/redux-types';
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import errorHandler from '../../shared/components/errorHandler';
import {
  MabangPushStockRecord,
  ManualPushMabangStockRequest
} from '../../custom_types/mabangErp/mabangErpOrder';

const initialState: MabangErpStockState = {
  mabangStockLoading: false,
  mabangStockPushRecords: []
};

export const mabangErpStockSlice = createSlice({
  name: 'mabangErpStock',
  initialState,
  reducers: {
    setMabangStockLoading: (state, action: PayloadAction<boolean>) => {
      state.mabangStockLoading = action.payload;
    },
    setMabangStockPushRecords: (state, action: PayloadAction<MabangPushStockRecord[]>) => {
      state.mabangStockPushRecords = action.payload;
    }
  }
});

export const { setMabangStockLoading, setMabangStockPushRecords } = mabangErpStockSlice.actions;

export const manualPushMabangStockHandler = (data: ManualPushMabangStockRequest): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setMabangStockLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.MA_BANG_ERP}/manual-push-stock`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        console.log(response.data);
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setMabangStockLoading(false)));
  }
};

export const fetchMabangStockPushRecordsHandler = (data: {
  clientId: string;
  clientWarehouseCode: string;
}): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setMabangStockLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.MA_BANG_ERP}/push-stock-records`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(setMabangStockPushRecords(response.data));
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setMabangStockLoading(false)));
  }
};

export const selectMabangStockLoading = (state: RootState) =>
  state.mabangErpStock.mabangStockLoading;
export const selectMabangStockPushRecords = (state: RootState) =>
  state.mabangErpStock.mabangStockPushRecords;

export default mabangErpStockSlice.reducer;
