import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AppThunk, HwcStockSyncState, RootState } from '../../custom_types/redux-types';
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import errorHandler from '../../shared/components/errorHandler';

const inintalState: HwcStockSyncState = {
  loading: false,
  stockSyncRecords: []
};

const hwcStockSyncSlice = createSlice({
  name: 'hwcStockSync',
  initialState: inintalState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStockSyncRecords: (state, action) => {
      state.stockSyncRecords = action.payload;
    }
  }
});

export const { setLoading, setStockSyncRecords } = hwcStockSyncSlice.actions;

export const manualSyncPartnerStocks = (hwcWarehouseId: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_STOCKS_SYNC}/${hwcWarehouseId}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        notification.success({ message: '库存同步成功', description: '库存同步成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const fetchStockSyncRecordsHandler = (hwcWarehouseRef: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_STOCKS_SYNC}/records/${hwcWarehouseRef}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        dispatch(setStockSyncRecords(response.data));
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const selectHwcStockSyncLoading = (state: RootState) => state.hwcStockSync.loading;
export const selectHwcStockSyncRecords = (state: RootState) => state.hwcStockSync.stockSyncRecords;

export default hwcStockSyncSlice.reducer;
