import { Table } from 'antd';
import React, { ReactElement } from 'react';
import { HwcWarehouse } from '../../../../custom_types/redux-types';

interface HwcWarehouseERPPanelProps {
  hwcWarehouse: HwcWarehouse;
}

const HwcWarehouseERPPanel = ({ hwcWarehouse }: HwcWarehouseERPPanelProps): ReactElement => {
  const erpData = [
    { name: '马帮', appKey: hwcWarehouse.mabangAppKey ? hwcWarehouse.mabangAppKey : '-' }
  ];

  const columns = [
    {
      title: 'ERP 平台',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'appKey',
      key: 'appKey',
      dataIndex: 'appKey'
    }
  ];

  return (
    <div>
      <Table<{ name: string; appKey: string }>
        rowKey={(record) => record.name}
        columns={columns}
        dataSource={erpData}
      />
    </div>
  );
};

export default HwcWarehouseERPPanel;
