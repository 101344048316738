import { PlusOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, Popconfirm, Space, Table } from 'antd';
import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HwcClientWarehouse, HwcWarehouseFeeData } from '../../../../custom_types/redux-types';
import { FEE_ITEM_DESCRIPTIONS, FEE_ITEM_NAMES } from '../../../../shared/utils/constants';
import {
  selectHwcClientWarehouseModalLoading,
  selectShowHwcClientWarehouseFeeModal,
  setShowHwcClientWarehouseFeeModal,
  syncHwcClientWarehouseFeeHandler,
  updateHwcClientWarehouseHandler
} from '../../../../redux/hwc/hwcClientWarehouseSlice';
import HwcClientWarehouseFeeEditModal from './HwcClientWarehouseFeeEditModal';

interface HwcClientWarehouseFeePanelProp {
  hwcClientWarehouse: HwcClientWarehouse;
}

const HwcClientWarehouseFeePanel = ({
  hwcClientWarehouse
}: HwcClientWarehouseFeePanelProp): ReactElement => {
  const dispatch = useDispatch();
  const loading = useSelector(selectHwcClientWarehouseModalLoading);
  const showEditModal = useSelector(selectShowHwcClientWarehouseFeeModal);
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const buildDataRecords = (item: HwcWarehouseFeeData) => {
    const dataRecords: Record<string, any>[] = [];
    dataRecords.push({
      name: FEE_ITEM_NAMES.baseFee,
      fee: item.baseFee,
      description: FEE_ITEM_DESCRIPTIONS.baseFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.countingFee,
      fee: item.countingFee,
      description: FEE_ITEM_DESCRIPTIONS.countingFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.skuFee,
      fee: item.skuFee,
      description: FEE_ITEM_DESCRIPTIONS.skuFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.packingFee,
      fee: item.packingFee,
      description: FEE_ITEM_DESCRIPTIONS.packingFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.labelFee,
      fee: item.labelFee,
      description: FEE_ITEM_DESCRIPTIONS.labelFee
    });
    dataRecords.push({
      name: FEE_ITEM_NAMES.returnFee,
      fee: item.returnFee,
      description: FEE_ITEM_DESCRIPTIONS.returnFee
    });
    for (let i = 0; i < item.storageFee.length; i += 1) {
      const sf = item.storageFee[i];
      dataRecords.push({
        name: `${FEE_ITEM_NAMES.storageFee}(${sf.volumnRange})`,
        fee: sf.fee,
        description: FEE_ITEM_DESCRIPTIONS.storageFee
      });
    }
    if (item.otherFees) {
      for (let i = 0; i < item.otherFees.length; i += 1) {
        const of = item.otherFees[i];
        dataRecords.push({
          name: `${FEE_ITEM_NAMES.otherFees}(${of.type})`,
          fee: of.fee,
          description: of.value
        });
      }
    }
    return dataRecords;
  };

  const colums = [
    {
      title: '费用类目',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '单价/USD',
      key: 'fee',
      dataIndex: 'fee'
    },
    {
      title: '备注',
      key: 'description',
      dataIndex: 'description'
    }
  ];

  const deleteHwcClientWarehouseFeeHandler = (index: number) => {
    const newFeeList = hwcClientWarehouse.warehouseFeeds!.filter((_, i) => i !== index);
    const updateData = {
      ...hwcClientWarehouse,
      warehouseFeeds: newFeeList
    };
    dispatch(updateHwcClientWarehouseHandler(hwcClientWarehouse.id, updateData));
  };

  const editClickedHandler = (index: number) => {
    dispatch(setShowHwcClientWarehouseFeeModal(true));
    setSelectedIndex(index);
  };

  const modalCancelHandler = () => {
    dispatch(setShowHwcClientWarehouseFeeModal(false));
    setSelectedIndex(undefined);
  };

  return (
    <div>
      {showEditModal && selectedIndex !== undefined && (
        <HwcClientWarehouseFeeEditModal
          hwcClientWarehouse={hwcClientWarehouse}
          index={selectedIndex}
          onCancel={modalCancelHandler}
        />
      )}
      <PageHeader
        title="仓库操作费"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            loading={loading}
            onClick={() =>
              dispatch(
                syncHwcClientWarehouseFeeHandler(
                  hwcClientWarehouse.id,
                  hwcClientWarehouse.hwcClientRef
                )
              )
            }
          >
            使用模板价格
          </Button>
        ]}
      />
      {hwcClientWarehouse.warehouseFeeds &&
        hwcClientWarehouse.warehouseFeeds.map((item, index) => {
          const dataRecords = buildDataRecords(item);
          return (
            <>
              <Divider />
              <Table<Record<string, any>>
                title={() => (
                  <Space size="large">
                    {`重量${item.weightRange}${item.weightUnit}`}
                    <Button
                      key="编辑"
                      size="small"
                      type="link"
                      onClick={() => editClickedHandler(index)}
                    >
                      编辑
                    </Button>
                    <Popconfirm
                      key="确认删除该仓库"
                      title="确认删除该仓库?"
                      placement="topRight"
                      onConfirm={() => deleteHwcClientWarehouseFeeHandler(index)}
                      okText="确定"
                      cancelText="取消"
                    >
                      <Button key="删除" size="small" type="link">
                        删除
                      </Button>
                    </Popconfirm>
                  </Space>
                )}
                columns={colums}
                dataSource={dataRecords}
              />
            </>
          );
        })}
    </div>
  );
};

export default HwcClientWarehouseFeePanel;
