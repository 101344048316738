import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NoData from '../../../../shared/components/NoData';
import { OverseaWarehouse } from '../../../../custom_types/wms-page';
import columns from '../components/OverseaWarehouseColumn';
import {
  fetchOverseaWarehouseHanlder,
  selectCreateOverseaWarehouseModal,
  selectEditOverseaWareHouseModal,
  selectLoading,
  selectOverseaWarehouses,
  setCreateOverseaWarehouseModal,
  setEditOverseaWarehouseModal
} from '../../../../redux/overseaWarehouseSlice';
import { selectWarehouses } from '../../../../redux/warehouseSlice';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import CreateOverseaWarehouseModal from '../components/CreateOverseaWarehouseModal';
import EditOverseaWarehouseModal from '../components/EditOverseaWarehouseModal';
import { fetchHwcWarehouseHanlder } from '../../../../redux/hwcWarehouseSlice';

const OverseaWarehousePage = (): ReactElement => {
  const dispatch = useDispatch();
  const overseaWarehouseList = useSelector(selectOverseaWarehouses);
  const loading = useSelector(selectLoading);
  const showCreateModal = useSelector(selectCreateOverseaWarehouseModal);
  const showEditModal = useSelector(selectEditOverseaWareHouseModal);
  const [selectedOverseaWarehouse, setSelectedOverseaWarehouse] = React.useState<
    OverseaWarehouse | undefined
  >();

  useEffect(() => {
    dispatch(fetchOverseaWarehouseHanlder());
    dispatch(fetchHwcWarehouseHanlder());
  }, [dispatch]);

  const handleEditWarehouseClicked = (record: OverseaWarehouse) => {
    dispatch(setEditOverseaWarehouseModal(true));
    setSelectedOverseaWarehouse(record);
  };

  const handleEditWarehouseModalClose = () => {
    dispatch(setEditOverseaWarehouseModal(false));
    setSelectedOverseaWarehouse(undefined);
  };

  return (
    <div>
      {showCreateModal && <CreateOverseaWarehouseModal />}
      {showEditModal && selectedOverseaWarehouse && (
        <EditOverseaWarehouseModal
          record={selectedOverseaWarehouse}
          onCancel={handleEditWarehouseModalClose}
        />
      )}
      <PageHeader
        title="海外仓客户列表"
        subTitle="管理海外仓客户"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(setCreateOverseaWarehouseModal(true))}
          >
            添加客户
          </Button>
        ]}
      />
      <Divider />
      <Table<OverseaWarehouse>
        rowKey={(record) => record.id}
        columns={[
          ...columns,
          {
            title: '操作',
            key: 'operations',
            fixed: 'right',
            render: (text: string, record: OverseaWarehouse): ReactElement => {
              return (
                <>
                  <Link to={`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES}/${record.id}`}>
                    详情
                  </Link>
                  <span> | </span>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => handleEditWarehouseClicked(record)}
                  >
                    编辑
                  </Button>
                </>
              );
            }
          }
        ]}
        dataSource={overseaWarehouseList}
        loading={loading}
        locale={{
          emptyText: <NoData />
        }}
      />
    </div>
  );
};

export default OverseaWarehousePage;
