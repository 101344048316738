import React from 'react';
import { PageHeader } from '@ant-design/pro-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Descriptions, Tag } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import { HwcClientWarehouse } from '../../../../custom_types/redux-types';
import { selectHwcWarehouses } from '../../../../redux/hwcWarehouseSlice';
import {
  fetchHwcClientWarehouseHandler,
  mabangAuthUrlHandler,
  selectHwcClientWarehouseLodaing
} from '../../../../redux/hwc/hwcClientWarehouseSlice';

interface MabangErpPanelProps {
  hwcClientWarehouse: HwcClientWarehouse;
}

const MabangErpPanel = ({ hwcClientWarehouse }: MabangErpPanelProps) => {
  const dispatch = useDispatch();
  const warehouses = useSelector(selectHwcWarehouses);
  const loading = useSelector(selectHwcClientWarehouseLodaing);

  const mabangStatus = () => {
    const w = warehouses.find((warehouse) => warehouse.code === hwcClientWarehouse.code);
    if (hwcClientWarehouse.mabangAccesstoken) {
      // 已授权
      return (
        <Tag style={{ fontSize: '20px' }} color="green">
          已授权
        </Tag>
      );
    }
    if (hwcClientWarehouse.mabangAuthUrl) {
      // 授权中
      return (
        <Tag style={{ fontSize: '20px' }} color="blue">
          授权中
        </Tag>
      );
    }
    if (w && w.mabangAppKey) {
      // 未授权
      return (
        <Tag style={{ fontSize: '20px' }} color="gold">
          未授权
        </Tag>
      );
    }
    // 不可用
    return (
      <Tag style={{ fontSize: '20px' }} color="red">
        不可用
      </Tag>
    );
  };

  const displayAccessToken = (token: string | undefined) => {
    const lastChars = token ? token.slice(-10) : '';
    const startChars = token ? token.slice(0, 10) : '';
    const result = token
      ? `${startChars}*********************************************${lastChars}`
      : '秘钥为空！';
    return result;
  };

  return (
    <div>
      <PageHeader
        title={
          <>
            {'马帮ERP授权  '}
            {mabangStatus()}
          </>
        }
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            loading={loading}
            key="0"
            onClick={() =>
              dispatch(fetchHwcClientWarehouseHandler(hwcClientWarehouse.hwcClientRef))
            }
          />,
          <Button
            key="1"
            type="primary"
            loading={loading}
            icon={<PlusOutlined />}
            onClick={() => dispatch(mabangAuthUrlHandler(hwcClientWarehouse))}
          >
            马帮授权
          </Button>
        ]}
      />

      <Descriptions bordered>
        <Descriptions.Item label="马帮ERP授权链接" span={3}>
          {hwcClientWarehouse.mabangAuthUrl}
        </Descriptions.Item>
        <Descriptions.Item label="马帮ERP访问令牌" span={3}>
          <Paragraph copyable={{ text: hwcClientWarehouse.mabangAccesstoken, tooltips: '复制' }}>
            {displayAccessToken(hwcClientWarehouse.mabangAccesstoken)}
          </Paragraph>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default MabangErpPanel;
