import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Divider, Layout, Popconfirm, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NoData from '../../../../shared/components/NoData';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import { HwcWarehouse } from '../../../../custom_types/redux-types';
import {
  deleteHwcWarehouseHandler,
  fetchHwcWarehouseHanlder,
  selectCreateHwcWareHouseModal,
  selectEditHwcWareHouseModal,
  selectHwcWarehouseLoading,
  selectHwcWarehouses,
  setCreateHwcWarehouseModal,
  setEditHwcWarehouseModal
} from '../../../../redux/hwcWarehouseSlice';
import columns from '../components/HwcWarehouseTableColumns';
import { fetchHwcPartnerHanlder } from '../../../../redux/hwcPartnerSlice';
import { fetchAllHwcPartnerWarehousesHandler } from '../../../../redux/hwcPartnerWarehouseSlice';
import HwcWarehouseCreateModal from '../components/HwcWarehouseCreateModal';
import HwcWarehouseEditModal from '../components/HwcWarehouseEditModal';

const { Content } = Layout;

const HwcWarehousePage = (): ReactElement => {
  const dispatch = useDispatch();
  const hwcWarehouseList = useSelector(selectHwcWarehouses);
  const loading = useSelector(selectHwcWarehouseLoading);
  const showCreateModal = useSelector(selectCreateHwcWareHouseModal);
  const showEditModal = useSelector(selectEditHwcWareHouseModal);
  const [selectedHwcWarehouse, setSelectedHwcWarehouse] = React.useState<HwcWarehouse | undefined>(
    undefined
  );

  useEffect(() => {
    dispatch(fetchHwcWarehouseHanlder());
    dispatch(fetchHwcPartnerHanlder());
    dispatch(fetchAllHwcPartnerWarehousesHandler());
  }, [dispatch]);

  const handleEditHwcWarehouseClicked = (record: HwcWarehouse) => {
    dispatch(setEditHwcWarehouseModal(true));
    setSelectedHwcWarehouse(record);
  };

  const handleEditHwcWarehouseModalClose = () => {
    dispatch(setEditHwcWarehouseModal(false));
    setSelectedHwcWarehouse(undefined);
  };

  return (
    <div>
      {showCreateModal && <HwcWarehouseCreateModal />}
      {showEditModal && selectedHwcWarehouse && (
        <HwcWarehouseEditModal
          record={selectedHwcWarehouse}
          onCancel={handleEditHwcWarehouseModalClose}
        />
      )}
      <PageHeader
        title="仓库列表"
        subTitle="管理美联发仓库"
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() => dispatch(fetchHwcWarehouseHanlder())}
          />,
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(setCreateHwcWarehouseModal(true))}
          >
            添加仓库
          </Button>
        ]}
      />
      <Divider />
      <Table<HwcWarehouse>
        rowKey={(record) => record.id}
        columns={[
          ...columns,
          {
            title: '操作',
            key: 'operations',
            fixed: 'right',
            render: (text: string, record: HwcWarehouse): ReactElement => {
              return (
                <>
                  <Link to={`${UI_ROUTES.WMS}${UI_ROUTES.HWC_WAREHOUSES}/${record.id}`}>详情</Link>
                  <span> | </span>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => handleEditHwcWarehouseClicked(record)}
                  >
                    编辑
                  </Button>
                  <span> | </span>
                  <Popconfirm
                    key="确认删除该仓库"
                    title="确认删除该仓库?"
                    placement="topRight"
                    onConfirm={() => dispatch(deleteHwcWarehouseHandler(record.id))}
                    okText="确定"
                    cancelText="取消"
                  >
                    <Button key="删除" size="small" type="link">
                      删除
                    </Button>
                  </Popconfirm>
                </>
              );
            }
          }
        ]}
        dataSource={hwcWarehouseList}
        loading={loading}
        locale={{
          emptyText: <NoData />
        }}
      />
    </div>
  );
};

export default HwcWarehousePage;
