import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AppThunk, HwcFulfillOrderSyncState, RootState } from '../../custom_types/redux-types';
import axios from '../../shared/utils/axios.base';
import errorHandler from '../../shared/components/errorHandler';
import { SERVER_ROUTES } from '../../shared/utils/constants';

const initialState: HwcFulfillOrderSyncState = {
  loading: false,
  fulfillOrderSyncRecords: []
};

const hwcFulfillOrderSyncSlice = createSlice({
  name: 'hwcFulfillOrderSync',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFulfillOrderSyncRecords: (state, action) => {
      state.fulfillOrderSyncRecords = action.payload;
    }
  }
});

export const { setLoading, setFulfillOrderSyncRecords } = hwcFulfillOrderSyncSlice.actions;

export const manualSyncPartnerFulfillOrders = (hwcWarehouseId: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_FULFILL_ORDER_SYNC}/${hwcWarehouseId}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then((response: any) => {
        notification.success({ message: '订单同步成功', description: '订单同步成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const fetchFulfillOrderSyncRecordsHandler = (hwcWarehouseRef: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_FULFILL_ORDER_SYNC}/records/${hwcWarehouseRef}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        dispatch(setFulfillOrderSyncRecords(response.data));
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const selectHwcFulfillOrderSyncLoading = (state: any) => state.hwcFulfillOrderSync.loading;
export const selectFulfillOrderSyncRecords = (state: any) =>
  state.hwcFulfillOrderSync.fulfillOrderSyncRecords;

export default hwcFulfillOrderSyncSlice.reducer;
