import { PayloadAction, createSlice, Dispatch } from '@reduxjs/toolkit';
import { AppThunk, HwcClientCarrierState, RootState } from '../../custom_types/redux-types';
import axios from '../../shared/utils/axios.base';
import errorHandler from '../../shared/components/errorHandler';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import {
  HwcClientCarrier,
  HwcClientCarrierData
} from '../../custom_types/overseawarehouse/hwc-carrier';
import { ResponseError } from '../../custom_types/common';

const initialState: HwcClientCarrierState = {
  hwcClientCarriers: [],
  loading: false,
  showHwcClientCarrierModal: false,
  hwcClientCarrierModalLoading: false
};

export const hwcClientCarriersSlice = createSlice({
  name: 'hwcClientCarriers',
  initialState,
  reducers: {
    setHwcClientCarriers: (state, action: PayloadAction<HwcClientCarrier[]>) => {
      state.hwcClientCarriers = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowHwcClientCarrierModal: (state, action: PayloadAction<boolean>) => {
      state.showHwcClientCarrierModal = action.payload;
    },
    setHwcClientCarrierModalLoading: (state, action: PayloadAction<boolean>) => {
      state.hwcClientCarrierModalLoading = action.payload;
    }
  }
});

export const {
  setHwcClientCarriers,
  setLoading,
  setShowHwcClientCarrierModal,
  setHwcClientCarrierModalLoading
} = hwcClientCarriersSlice.actions;

export const fetchHwcClientCarriersHandler = (overseawarehouseId: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_CARRIERS}/${overseawarehouseId}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(setHwcClientCarriers(response.data));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setLoading(false));
    }
  }
};

export const createHwcClientCarrierHandler = (data: HwcClientCarrierData): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcClientCarrierModalLoading(true));
    try {
      const response = await axios.post(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_CARRIERS}`,
        data,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchHwcClientCarriersHandler(data.clientRef));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setHwcClientCarrierModalLoading(false));
      dispatch(setShowHwcClientCarrierModal(false));
    }
  }
};

export const updateHwcClientCarrierHandler = (data: HwcClientCarrier): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcClientCarrierModalLoading(true));
    try {
      const response = await axios.put(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_CARRIERS}`,
        data,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchHwcClientCarriersHandler(data.clientRef));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setHwcClientCarrierModalLoading(false));
      dispatch(setShowHwcClientCarrierModal(false));
    }
  }
};

export const deleteHwcClientCarrierHandler = (
  id: string,
  overseawarehouseId: string
): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_CLIENT_CARRIERS}/${id}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchHwcClientCarriersHandler(overseawarehouseId));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setLoading(false));
    }
  }
};

export const selectHwcClientCarriers = (state: RootState) =>
  state.hwcClientCarriers.hwcClientCarriers;
export const selectHwcClientCarriersLoading = (state: RootState) => state.hwcClientCarriers.loading;
export const selectShowHwcClientCarrierModal = (state: RootState) =>
  state.hwcClientCarriers.showHwcClientCarrierModal;
export const selectHwcClientCarrierModalLoading = (state: RootState) =>
  state.hwcClientCarriers.hwcClientCarrierModalLoading;

export default hwcClientCarriersSlice.reducer;
