import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk, InventoryState, RootState } from '../custom_types/redux-types';
import { CreateInventoryData, Inventory } from '../custom_types/wms-page';
import axios from '../shared/utils/axios.base';
import { SERVER_ROUTES } from '../shared/utils/constants';
import errorHandler from '../shared/components/errorHandler';

const initialState: InventoryState = {
  inventories: [],
  loading: false,
  inventoryModal: false,
  inventoryModalLoading: false
};

export const InventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInventories: (state, action: PayloadAction<Inventory[]>) => {
      state.inventories = action.payload;
    },
    setInventoryLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setInventoryModal: (state, action: PayloadAction<boolean>) => {
      state.inventoryModal = action.payload;
    },
    setInventoryLoadingModal: (state, action: PayloadAction<boolean>) => {
      state.inventoryModalLoading = action.payload;
    }
  }
});

export const {
  setInventories,
  setInventoryLoading,
  setInventoryModal,
  setInventoryLoadingModal
} = InventorySlice.actions;

export const fetchInventoryHanlder = (): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setInventoryLoading(true));
    try {
      const response = await axios.get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.INVENTORY}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setInventories(response.data));
    } catch (error) {
      errorHandler(error, dispatch);
    } finally {
      dispatch(setInventoryLoading(false));
    }
  }
};

export const fetchInventoryForWarehouseHandler = (warehouseId: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setInventoryLoading(true));
    try {
      const response = await axios.get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.INVENTORY}${SERVER_ROUTES.WAREHOUSES}/${warehouseId}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(setInventories(response.data));
    } catch (error) {
      errorHandler(error, dispatch);
    } finally {
      dispatch(setInventoryLoading(false));
    }
  }
};

export const createInventoryHandler = (data: CreateInventoryData): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setInventoryLoadingModal(true));
    try {
      const response = await axios.post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.INVENTORY}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(fetchInventoryForWarehouseHandler(data.warehouseRef));
      dispatch(setInventoryModal(false));
    } catch (error) {
      errorHandler(error, dispatch);
    } finally {
      dispatch(setInventoryLoadingModal(false));
    }
  }
};

export const editInventoryHandler = (data: Inventory): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setInventoryLoadingModal(true));
    try {
      const response = await axios.put(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.INVENTORY}/${data.id}`,
        data,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchInventoryForWarehouseHandler(data.warehouseRef));
      dispatch(setInventoryModal(false));
    } catch (error) {
      errorHandler(error, dispatch);
    } finally {
      dispatch(setInventoryLoadingModal(false));
    }
  }
};

export const deleteInventoryHandler = (id: string, warehouseId: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setInventoryLoading(true));
    try {
      const response = await axios.delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.INVENTORY}/${id}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(fetchInventoryForWarehouseHandler(warehouseId));
    } catch (error) {
      errorHandler(error, dispatch);
    } finally {
      dispatch(setInventoryLoading(false));
    }
  }
};

export const selectInventories = (state: RootState) => state.inventory.inventories;
export const selectInventoryLoading = (state: RootState) => state.inventory.loading;
export const selectInventoryModal = (state: RootState) => state.inventory.inventoryModal;
export const selectInventoryLoadingModal = (state: RootState) =>
  state.inventory.inventoryModalLoading;

export default InventorySlice.reducer;
