import React, { ReactElement, useEffect } from 'react';
import { Modal, Form, Input, Space, Divider, InputNumber, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CarrierRateType, Currency, RATE_BASES } from '../../../../shared/utils/constants';
import {
  HwcClientWarehouse,
  HwcClientWarehouseChannel
} from '../../../../custom_types/redux-types';
import {
  selectHwcClientWarehouseModalLoading,
  selectShowEditHwcClientWarehouseModal,
  setShowEditHwcClientWarehouseModal,
  updateHwcClientWarehouseHandler
} from '../../../../redux/hwc/hwcClientWarehouseSlice';

interface HwcClientWarehouseEditModalProps {
  hwcClientWarehouse: HwcClientWarehouse;
}

const HwcClientWarehouseEditModal = ({
  hwcClientWarehouse
}: HwcClientWarehouseEditModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const showModal = useSelector(selectShowEditHwcClientWarehouseModal);
  const modalLoading = useSelector(selectHwcClientWarehouseModalLoading);

  useEffect(() => {
    const values: any = {};
    for (let i = 0; i < hwcClientWarehouse.channels.length; i += 1) {
      const channel = hwcClientWarehouse.channels[i];
      values[`${channel.name}-ratebase`] = channel.fee.ratebase;
      values[`${channel.name}-currency`] = channel.fee.currency;
      values[`${channel.name}-rate`] = channel.fee.rate;
      values[`${channel.name}-ratetype`] = channel.fee.ratetype;
    }
    form.setFieldsValue(values);
  }, [form, hwcClientWarehouse]);

  const cancelClickedHandler = () => {
    form.resetFields();
    dispatch(setShowEditHwcClientWarehouseModal(false));
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        const clist = hwcClientWarehouse.channels.map(
          (channel): HwcClientWarehouseChannel => {
            const ratebase = values[`${channel.name}-ratebase`];
            const currency = values[`${channel.name}-currency`];
            const rate = values[`${channel.name}-rate`];
            const ratetype = values[`${channel.name}-ratetype`];
            return {
              name: channel.name,
              fee: {
                ratebase,
                currency,
                rate,
                ratetype
              }
            };
          }
        );

        const data: HwcClientWarehouse = {
          ...hwcClientWarehouse,
          channels: clist
        };
        dispatch(updateHwcClientWarehouseHandler(hwcClientWarehouse.id, data));
        form.resetFields();
      })
      .catch(() => {});
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      centered
      closable={false}
      open={showModal}
      okText="编辑仓库授权"
      cancelText="取消"
      title="编辑授权仓库信息"
      onCancel={cancelClickedHandler}
      onOk={okClickedHandler}
      okButtonProps={{ loading: modalLoading }}
    >
      <Form form={form} layout="vertical">
        <>
          <Divider>渠道费率</Divider>
          {hwcClientWarehouse.channels.map((channel) => {
            return (
              <>
                <div>
                  <strong>渠道名称： {`${channel.name}`}</strong>
                </div>
                <Space size="large" align="baseline" style={{ width: '100%' }}>
                  <Form.Item
                    name={`${channel.name}-ratebase`}
                    initialValue={RATE_BASES.ORDER}
                    rules={[{ required: true, message: '收费基准必须选' }]}
                  >
                    <Input style={{ width: '180px' }} type="text" disabled />
                  </Form.Item>
                  <Form.Item
                    name={`${channel.name}-currency`}
                    initialValue={Currency.USD}
                    rules={[{ required: true, message: '货币种类必须填' }]}
                  >
                    <Input style={{ width: '80px' }} type="text" disabled />
                  </Form.Item>
                </Space>
                <Space size="large" align="baseline" style={{ width: '100%' }}>
                  <Form.Item
                    name={`${channel.name}-rate`}
                    rules={[{ required: true, message: '费率必须填！' }]}
                  >
                    <InputNumber
                      placeholder="费率"
                      min={0}
                      style={{ width: '100px' }}
                      step="0.01"
                      precision={2}
                    />
                  </Form.Item>
                  <Form.Item
                    name={`${channel.name}-ratetype`}
                    rules={[{ required: true, message: '请选择费率模式' }]}
                  >
                    <Radio.Group>
                      {Object.values(CarrierRateType).map((item) => {
                        return (
                          <Radio key={item} value={item}>
                            {item}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </Form.Item>
                </Space>
                <Divider />
              </>
            );
          })}
        </>
      </Form>
    </Modal>
  );
};

export default HwcClientWarehouseEditModal;
