import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import axios from '../../shared/utils/axios.base';
import {
  LabelUserCarrier,
  LabelUserCarrierCreate,
  LabelUserCarrierState,
  LabelUserCarrierUpdate
} from '../../custom_types/labels/label-user-carrier-types';
import { AppThunk, RootState } from '../../custom_types/redux-types';
import errorHandler from '../../shared/components/errorHandler';
import { SERVER_ROUTES } from '../../shared/utils/constants';

const initialState: LabelUserCarrierState = {
  labelUserCarriers: [],
  loading: false,
  showModal: false,
  modalLoading: false
};

export const labelUserCarrierSlice = createSlice({
  name: 'labelUserCarriers',
  initialState,
  reducers: {
    setLabelUserCarriers: (
      state,
      action: PayloadAction<LabelUserCarrier[]>
    ) => {
      state.labelUserCarriers = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setModalLoading: (state, action: PayloadAction<boolean>) => {
      state.modalLoading = action.payload;
    }
  }
});

export const {
  setLabelUserCarriers,
  setLoading,
  setShowModal,
  setModalLoading
} = labelUserCarrierSlice.actions;

export const fetchLabelUserCarriersHandler = (userId: string): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USER_CARRIERS}/${userId}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response) => dispatch(setLabelUserCarriers(response.data)))
      .catch((error) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const createLabelUserCarrierHandler = (
  data: LabelUserCarrierCreate
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setModalLoading(true));
    axios
      .post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USER_CARRIERS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => dispatch(fetchLabelUserCarriersHandler(data.userRef)))
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => {
        dispatch(setModalLoading(false));
        dispatch(setShowModal(false));
      });
  }
};

export const updateLabelUserCarrierHandler = (
  data: LabelUserCarrierUpdate,
  userId: string
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setModalLoading(true));
    axios
      .put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USER_CARRIERS}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => dispatch(fetchLabelUserCarriersHandler(userId)))
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => {
        dispatch(setModalLoading(false));
        dispatch(setShowModal(false));
      });
  }
};

export const deleteLabelUserCarrierHandler = (
  id: string,
  userId: string
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .delete(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.LABEL_USER_CARRIERS}/${id}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then(() => dispatch(fetchLabelUserCarriersHandler(userId)))
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => dispatch(setLoading(false)));
  }
};

export const selectLabelUserCarriers = (state: RootState): LabelUserCarrier[] =>
  state.labelUserCarriers.labelUserCarriers;
export const selectLabelUserCarriersLoading = (state: RootState): boolean =>
  state.labelUserCarriers.loading;
export const selectLabelUserCarriersModal = (state: RootState): boolean =>
  state.labelUserCarriers.showModal;

export default labelUserCarrierSlice.reducer;
