import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AppThunk, HwcProductState, RootState } from '../../custom_types/redux-types';
import { HwcProduct, HwcProductCreateObj } from '../../custom_types/overseawarehouse/hwc-product';
import axios from '../../shared/utils/axios.base';
import { SERVER_ROUTES } from '../../shared/utils/constants';
import errorHandler from '../../shared/components/errorHandler';

const initialState: HwcProductState = {
  hwcProducts: [],
  loading: false,
  hwcProductModalLoading: false,
  showCreateHwcProductModal: false
};

export const hwcClientProductSlice = createSlice({
  name: 'hwcProduct',
  initialState,
  reducers: {
    setHwcProducts: (state, action: PayloadAction<HwcProduct[]>) => {
      state.hwcProducts = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setHwcProductModalLoading: (state, action: PayloadAction<boolean>) => {
      state.hwcProductModalLoading = action.payload;
    },
    setShowCreateHwcProductModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateHwcProductModal = action.payload;
    }
  }
});

export const {
  setHwcProducts,
  setLoading,
  setHwcProductModalLoading,
  setShowCreateHwcProductModal
} = hwcClientProductSlice.actions;

export const fetchHwcProductsHandler = (
  hwcClientId: string,
  hwcClientWarehouseId: string
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PRODUCTS}/fetch/${hwcClientId}/${hwcClientWarehouseId}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then((response: any) => {
        dispatch(setHwcProducts(response.data));
        notification.success({ message: '产品获取成功', description: '产品获取成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const createHwcProductHandler = (data: HwcProductCreateObj, sync: boolean): AppThunk => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcProductModalLoading(true));
    axios
      .post(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PRODUCTS}/create`,
        { ...data, sync },
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then(() => {
        dispatch(fetchHwcProductsHandler(data.clientRef, data.clientWarehouseRef));
        notification.success({ message: '产品创建成功', description: '产品创建成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => {
        dispatch(setHwcProductModalLoading(false));
        dispatch(setShowCreateHwcProductModal(false));
      });
  }
};

export const syncHwcProductsHandler = (
  hwcClientId: string,
  hwcClientWarehouseId: string
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PRODUCTS}/sync/${hwcClientId}/${hwcClientWarehouseId}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      )
      .then(() => {
        dispatch(fetchHwcProductsHandler(hwcClientId, hwcClientWarehouseId));
        notification.success({ message: '产品同步成功', description: '产品同步成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const deleteHwcProductHandler = (
  hwcClientId: string,
  hwcClientWarehouseId: string,
  hwcProductId: string
): AppThunk => (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    axios
      .delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_PRODUCTS}/${hwcProductId}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      })
      .then(() => {
        dispatch(fetchHwcProductsHandler(hwcClientId, hwcClientWarehouseId));
        notification.success({ message: '产品删除成功', description: '产品删除成功' });
      })
      .catch((error: any) => errorHandler(error, dispatch))
      .finally(() => dispatch(setLoading(false)));
  }
};

export const selectHwcProducts = (state: RootState): HwcProduct[] => state.hwcProducts.hwcProducts;
export const selectLoading = (state: RootState): boolean => state.hwcProducts.loading;
export const selectHwcProductModalLoading = (state: RootState): boolean =>
  state.hwcProducts.hwcProductModalLoading;
export const selectShowCreateHwcProductModal = (state: RootState): boolean =>
  state.hwcProducts.showCreateHwcProductModal;

export default hwcClientProductSlice.reducer;
