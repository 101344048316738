import { Layout } from 'antd';
import React, { ReactElement } from 'react';
import FooterComponent from '../../../shared/components/FooterComponent';
import HeaderMenu from '../../Layout/components/HeaderMenu';
import LabelMenuList from '../components/LabelMenuList';
import LabelRoutes from '../components/LabelRoutes';

const { Content, Sider } = Layout;

const LabelManagementPage = (): ReactElement => {
  return (
    <>
      <HeaderMenu title="面单系统" />
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible breakpoint="xl" theme="light">
          <LabelMenuList />
        </Sider>
        <Layout>
          <Content
            style={{
              padding: 24,
              margin: '24px 16px 0 16px',
              minHeight: '200px',
              background: '#fff'
            }}
          >
            {/* <LabelRoutes /> */}
          </Content>
          <FooterComponent />
        </Layout>
      </Layout>
    </>
  );
};

export default LabelManagementPage;
