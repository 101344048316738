import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';

import { create } from 'domain';
import {
  createInventoryHandler,
  editInventoryHandler,
  selectInventoryModal,
  setInventoryModal
} from '../../../../redux/inventorySlice';
import { CreateInventoryData, Inventory } from '../../../../custom_types/wms-page';

interface InventoryEditModalProps {
  warehouseId: string;
  inventory: Inventory | undefined;
}

const { Option } = Select;

const InventoryEditModal = ({ warehouseId, inventory }: InventoryEditModalProps) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const showModal = useSelector(selectInventoryModal);

  useEffect(() => {
    form.resetFields();
    if (inventory) {
      form.setFieldsValue(inventory);
    }
  }, [inventory, form]);

  const closeClickedHandler = () => {
    form.resetFields();
    dispatch(setInventoryModal(false));
  };

  const updateOKClickedHandler = () => {
    if (inventory) {
      form.validateFields().then((values) => {
        console.log(values);
        const updateInventoryData: Inventory = {
          id: inventory.id,
          ...values,
          profit: values.salePrice - values.cost,
          margin: (values.salePrice - values.cost) / values.salePrice,
          preferredVendor: values.preferredVendor ? values.preferredVendor : '',
          warehouseRef: warehouseId
        };
        console.log(updateInventoryData);
        dispatch(editInventoryHandler(updateInventoryData));
        form.resetFields();
      });
    }
  };

  const createOKClickedHandler = () => {
    form.validateFields().then((values) => {
      const createInventoryData: CreateInventoryData = {
        ...values,
        profit: values.salePrice - values.cost,
        margin: (values.salePrice - values.cost) / values.salePrice,
        preferredVendor: values.preferredVendor ? values.preferredVendor : '',
        unitesInPurchase: 0,
        warehouseRef: warehouseId
      };
      dispatch(createInventoryHandler(createInventoryData));
      form.resetFields();
    });
  };

  return (
    <Modal
      closable={false}
      title={inventory ? '编辑库存' : '添加库存'}
      cancelText="取消"
      onCancel={closeClickedHandler}
      okText="保存"
      onOk={inventory ? updateOKClickedHandler : createOKClickedHandler}
      visible={showModal}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="item"
          label="Item"
          rules={[{ required: true, message: 'Item is required' }]}
        >
          <Input placeholder="Item" />
        </Form.Item>
        <Form.Item name="sku" label="SKU" rules={[{ required: true, message: 'SKU is required' }]}>
          <Input placeholder="SKU" />
        </Form.Item>
        <Form.Item name="category" label="Category">
          <Input placeholder="Category" />
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Input placeholder="Type" />
        </Form.Item>
        <Form.Item name="weight" label="Weight(KG)">
          <InputNumber placeholder="Weight" min={0.5} step={0.5} />
        </Form.Item>
        <Form.Item
          name="onHand"
          label="On Hand"
          rules={[{ required: true, message: 'On Hand is required' }]}
        >
          <Input placeholder="On Hand" />
        </Form.Item>
        <Form.Item
          name="minimumStock"
          label="Minimum Stock"
          rules={[{ required: true, message: 'Minimum Stock is required' }]}
        >
          <Input placeholder="Minimum Stock" />
        </Form.Item>
        <Form.Item name="salePrice" label="Sale Price">
          <Input placeholder="Sale Price" />
        </Form.Item>
        <Form.Item name="cost" label="Cost">
          <Input placeholder="Cost" />
        </Form.Item>
        <Form.Item name="preferredVendor" label="Preferred Vendor">
          <Input placeholder="Preferred Vendor" />
        </Form.Item>
        <Form.Item name="notes" label="Notes">
          <Input.TextArea placeholder="Notes" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InventoryEditModal;
