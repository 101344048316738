import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Layout, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Warehouse } from '../../../../custom_types/wms-page';
import NoData from '../../../../shared/components/NoData';
import columns from '../components/WarehouseTableColumns';
import {
  fetchWarehouseHanlder,
  selectCreateWareHouseModal,
  selectEditWareHouseModal,
  selectWarehouseLoading,
  selectWarehouses,
  setCreateWarehouseModal,
  setEditWarehouseModal
} from '../../../../redux/warehouseSlice';
import CreateWarehouseModal from '../components/CreateWarehouseModal';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import EditWarehouseModal from '../components/EditWarehouseModal';

const { Content } = Layout;

const WarehousePage = (): ReactElement => {
  const dispatch = useDispatch();
  const warehouseList = useSelector(selectWarehouses);
  const loading = useSelector(selectWarehouseLoading);
  const showCreateModal = useSelector(selectCreateWareHouseModal);
  const showEditModal = useSelector(selectEditWareHouseModal);
  const [selectedWarehouse, setSelectedWarehouse] = React.useState<Warehouse | undefined>(
    undefined
  );

  useEffect(() => {
    dispatch(fetchWarehouseHanlder());
  }, [dispatch]);

  const handleEditWarehouseClicked = (record: Warehouse) => {
    dispatch(setEditWarehouseModal(true));
    setSelectedWarehouse(record);
  };

  const handleEditWarehouseModalClose = () => {
    dispatch(setEditWarehouseModal(false));
    setSelectedWarehouse(undefined);
  };

  return (
    <div>
      {showCreateModal && <CreateWarehouseModal />}
      {showEditModal && selectedWarehouse && (
        <EditWarehouseModal record={selectedWarehouse} onCancel={handleEditWarehouseModalClose} />
      )}
      <PageHeader
        title="仓库列表"
        subTitle="管理Eksborder仓库"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(setCreateWarehouseModal(true))}
          >
            添加仓库
          </Button>
        ]}
      />
      <Divider />
      <Table<Warehouse>
        rowKey={(record) => record.id}
        columns={[
          ...columns,
          {
            title: '操作',
            key: 'operations',
            fixed: 'right',
            render: (text: string, record: Warehouse): ReactElement => {
              return (
                <>
                  <Link to={`${UI_ROUTES.WMS}${UI_ROUTES.WAREHOUSES}/${record.id}`}>详情</Link>
                  <span> | </span>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => handleEditWarehouseClicked(record)}
                  >
                    编辑
                  </Button>
                </>
              );
            }
          }
        ]}
        dataSource={warehouseList}
        loading={loading}
        locale={{
          emptyText: <NoData />
        }}
      />
    </div>
  );
};

export default WarehousePage;
