import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Tag, Row, Col, Descriptions, Statistic, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { OverseaWarehouse } from '../../../../custom_types/wms-page';
import { UI_ROUTES } from '../../../../shared/utils/constants';

interface OverseaWarehouseHeaderProps {
  overseaWarehouse: OverseaWarehouse | undefined;
  footer: ReactElement;
}

const OverseaWarehouseHeader = ({ overseaWarehouse, footer }: OverseaWarehouseHeaderProps) => {
  const history = useHistory();
  return (
    <PageHeader
      title="客户详情"
      tags={
        overseaWarehouse && (
          <Tag color={overseaWarehouse.active ? 'blue' : 'default'}>
            {overseaWarehouse.active ? '启用' : '停用'}
          </Tag>
        )
      }
      footer={footer}
      onBack={() => history.push(`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES}`)}
    >
      {overseaWarehouse && (
        <Row>
          <Col span={12}>
            <Descriptions size="small" column={3}>
              <Descriptions.Item label="客户名称">{overseaWarehouse.name}</Descriptions.Item>
              {overseaWarehouse.company && (
                <Descriptions.Item label="公司名称">{overseaWarehouse.company}</Descriptions.Item>
              )}
              <Descriptions.Item label="电话">{overseaWarehouse.phone || '-'}</Descriptions.Item>
              <Descriptions.Item label="邮箱">{overseaWarehouse.email}</Descriptions.Item>
              <Descriptions.Item label="地址">{`${overseaWarehouse.address1}, ${
                overseaWarehouse.address2 ? overseaWarehouse.address2 : ''
              } ${overseaWarehouse.city} ${overseaWarehouse.state} ${overseaWarehouse.country} ${
                overseaWarehouse.zip
              }`}</Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={12}>
            <Space size="large">
              <Statistic title="可用余额 (USD)" value={overseaWarehouse.balance} precision={2} />
              <Statistic title="用户押金 (USD)" value={overseaWarehouse.deposit} precision={2} />
            </Space>
          </Col>
        </Row>
      )}
    </PageHeader>
  );
};

export default OverseaWarehouseHeader;
