import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '@ant-design/pro-components';
import { Button, Table, Tag } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  fetchMabangStockPushRecordsHandler,
  selectMabangStockLoading,
  selectMabangStockPushRecords
} from '../../../../../redux/mabangErp/mabangErpStockSlice';
import { MabangPushStockRecord } from '../../../../../custom_types/mabangErp/mabangErpOrder';

interface HwcMabangStockPushRecordsTabProps {
  clientId: string;
  clientWarehouseCode: string;
}

const HwcMabangStockPushRecordsTab = ({
  clientId,
  clientWarehouseCode
}: HwcMabangStockPushRecordsTabProps) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectMabangStockLoading);
  const records = useSelector(selectMabangStockPushRecords);

  useEffect(() => {
    dispatch(fetchMabangStockPushRecordsHandler({ clientId, clientWarehouseCode }));
  }, [dispatch, clientId, clientWarehouseCode]);

  const columns = [
    {
      title: '日期',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (date: Date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      title: '任务类型',
      key: 'recordType',
      dataIndex: 'recordType'
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (status: number) => {
        if (status === 0) return <Tag color="green">成功</Tag>;
        if (status === 2) return <Tag color="orange">部分成功</Tag>;
        return <Tag color="red">失败</Tag>;
      }
    },
    {
      title: '任务用时',
      key: 'processTime',
      dataIndex: 'processTime'
    },
    {
      title: '任务信息',
      key: 'message',
      dataIndex: 'message'
    }
  ];

  return (
    <div>
      <PageHeader
        title=""
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() =>
              dispatch(fetchMabangStockPushRecordsHandler({ clientId, clientWarehouseCode }))
            }
          />
        ]}
      />
      <Table<MabangPushStockRecord>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={records}
        loading={loading}
      />
    </div>
  );
};

export default HwcMabangStockPushRecordsTab;
