import React, { ReactElement } from 'react';
import { title } from 'process';
import { render } from '@testing-library/react';
import { HwcWarehouse } from '../../../../custom_types/redux-types';

const HwcWarehouseTableColumns: any[] = [
  {
    title: '仓库名称',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: '仓库代码',
    key: 'code',
    dataIndex: 'code'
  },
  {
    title: '仓库类型',
    key: 'thirdParty',
    dataIndex: 'thirdParty',
    render: (thirdParty: boolean) => {
      return thirdParty ? '合作仓库' : '自营仓库';
    }
  },
  {
    title: '合作海外仓',
    key: 'thirdPartyParnter',
    dataIndex: 'thirdPartyParnter'
  },
  {
    title: '关联仓库',
    key: 'thirdPartyCode',
    dataIndex: 'thirdPartyCode'
  },
  {
    title: '仓库地址',
    key: 'address',
    dataIndex: 'address',
    render: (_: any, record: HwcWarehouse): ReactElement => {
      return (
        <>
          <div>{record.contact}</div>
          {record.phone && <div>{record.phone}</div>}
          {record.email && <div>{record.email}</div>}
          <div>{record.address1}</div>
          {record.address2 && <div>{record.address2}</div>}
          <div>
            {record.city}, {record.state} {record.zip} {record.country}
          </div>
        </>
      );
    }
  },
  {
    title: 'EPR 设置',
    key: 'epr',
    dataIndex: 'epr',
    render: (_: any, record: HwcWarehouse): ReactElement => {
      return <>{record.mabangAppKey && <div>- 马帮</div>}</>;
    }
  }
];

export default HwcWarehouseTableColumns;
