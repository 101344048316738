import { PayloadAction, createSlice, Dispatch } from '@reduxjs/toolkit';
import { AppThunk, HwcWarehouseCarrierState, RootState } from '../custom_types/redux-types';
import axios from '../shared/utils/axios.base';
import errorHandler from '../shared/components/errorHandler';
import { SERVER_ROUTES } from '../shared/utils/constants';
import { ResponseError } from '../custom_types/common';
import {
  HwcWarehouseCarrier,
  HwcWarehouseCarrierData
} from '../custom_types/overseawarehouse/hwc-carrier';

const initialState: HwcWarehouseCarrierState = {
  hwcWarehouseCarriers: [],
  loading: false,
  showHwcWarehouseCarrierModal: false,
  hwcWarehouseCarrierModalLoading: false
};

export const hwcWarehouseCarriersSlice = createSlice({
  name: 'hwcWarehouseCarriers',
  initialState,
  reducers: {
    setHwcWarehouseCarriers: (state, action: PayloadAction<HwcWarehouseCarrier[]>) => {
      state.hwcWarehouseCarriers = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setShowHwcWarehouseCarrierModal: (state, action: PayloadAction<boolean>) => {
      state.showHwcWarehouseCarrierModal = action.payload;
    },
    setHwcWarehouseCarrierModalLoading: (state, action: PayloadAction<boolean>) => {
      state.hwcWarehouseCarrierModalLoading = action.payload;
    }
  }
});

export const {
  setHwcWarehouseCarriers,
  setLoading,
  setShowHwcWarehouseCarrierModal,
  setHwcWarehouseCarrierModalLoading
} = hwcWarehouseCarriersSlice.actions;

export const fetchHwcWarehouseCarriersHandler = (hwcWarehouseId: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSE_CARRIERS}/${hwcWarehouseId}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(setHwcWarehouseCarriers(response.data));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setLoading(false));
    }
  }
};

export const createHwcWarehouseCarrierHandler = (data: HwcWarehouseCarrierData): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseCarrierModalLoading(true));
    try {
      const response = await axios.post(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSE_CARRIERS}`,
        data,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchHwcWarehouseCarriersHandler(data.hwcWarehouseRef));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setHwcWarehouseCarrierModalLoading(false));
      dispatch(setShowHwcWarehouseCarrierModal(false));
    }
  }
};

export const updateHwcWarehouseCarrierHandler = (data: HwcWarehouseCarrier): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseCarrierModalLoading(true));
    try {
      const response = await axios.put(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSE_CARRIERS}`,
        data,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchHwcWarehouseCarriersHandler(data.hwcWarehouseRef));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setHwcWarehouseCarrierModalLoading(false));
      dispatch(setShowHwcWarehouseCarrierModal(false));
    }
  }
};

export const deleteHwcWarehouseCarrierHandler = (
  id: string,
  overseawarehouseId: string
): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSE_CARRIERS}/${id}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchHwcWarehouseCarriersHandler(overseawarehouseId));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setLoading(false));
    }
  }
};

export const selectHwcWarehouseCarriers = (state: RootState) =>
  state.hwcWarehouseCarriers.hwcWarehouseCarriers;
export const selectHwcWarehouseCarriersLoading = (state: RootState) =>
  state.hwcWarehouseCarriers.loading;
export const selectShowHwcWarehouseCarrierModal = (state: RootState) =>
  state.hwcWarehouseCarriers.showHwcWarehouseCarrierModal;
export const selectHwcWarehouseCarrierModalLoading = (state: RootState) =>
  state.hwcWarehouseCarriers.hwcWarehouseCarrierModalLoading;

export default hwcWarehouseCarriersSlice.reducer;
