import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, Tabs } from 'antd';
import { selectHwcClientWarehouses } from '../../../../redux/hwc/hwcClientWarehouseSlice';
import { HwcClientWarehouse } from '../../../../custom_types/redux-types';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import HwcClientWarehouseDetailHeader from './HwcClientWarehouseDetailHeader';
import { HWC_CLIENT_WAREHOUSE_OPERATIONS } from '../../../../shared/utils/wms/wms.constants';
import HwcClientWarehouseFeePanel from './HwcClientWarehouseFeesPanel';
import { selectHwcWarehouses } from '../../../../redux/hwcWarehouseSlice';
import MabangErpPanel from './MabangErpPanel';

const HwcClientWarehouseDetailPage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, clientId, clientName } = useParams<{
    id: string;
    clientId: string;
    clientName: string;
  }>();
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const hwcWarehouseList = useSelector(selectHwcWarehouses);
  const [selectedHwcClientWarehouse, setSelectedHwcClientWarehouse] = useState<
    HwcClientWarehouse | undefined
  >();
  const [curClientName, setCurClientName] = useState<string>();

  useEffect(() => {
    const curHwcClientWarehouse = hwcClientWarehouseList.find(
      (hwcClientWarehouse) => hwcClientWarehouse.id === id
    );
    if (curHwcClientWarehouse) {
      setSelectedHwcClientWarehouse(curHwcClientWarehouse);
      setCurClientName(clientName);
    } else {
      history.push(`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES}/${clientId}`);
    }
  }, [dispatch, history, id, clientName, clientId, hwcClientWarehouseList]);

  const checkMamangErp = () => {
    const warehouse = hwcWarehouseList.find(
      (hwcWarehouse) => hwcWarehouse.code === selectedHwcClientWarehouse?.code
    );
    if (warehouse && warehouse.mabangAppKey) {
      return false;
    }
    return true;
  };

  return (
    <>
      <HwcClientWarehouseDetailHeader
        hwcClientWarehouse={selectedHwcClientWarehouse}
        overseaWarehouseName={curClientName || ''}
        footer={
          <>
            <Divider />
            {selectedHwcClientWarehouse && (
              <Tabs defaultActiveKey={HWC_CLIENT_WAREHOUSE_OPERATIONS.OPERATIONAL_FEES}>
                <Tabs.TabPane
                  tab={HWC_CLIENT_WAREHOUSE_OPERATIONS.OPERATIONAL_FEES}
                  key={HWC_CLIENT_WAREHOUSE_OPERATIONS.OPERATIONAL_FEES}
                >
                  <HwcClientWarehouseFeePanel hwcClientWarehouse={selectedHwcClientWarehouse} />
                </Tabs.TabPane>
                <Tabs.TabPane
                  disabled={checkMamangErp()}
                  tab={HWC_CLIENT_WAREHOUSE_OPERATIONS.MABANG_ERP}
                  key={HWC_CLIENT_WAREHOUSE_OPERATIONS.MABANG_ERP}
                >
                  <MabangErpPanel hwcClientWarehouse={selectedHwcClientWarehouse} />
                </Tabs.TabPane>
              </Tabs>
            )}
          </>
        }
      />
    </>
  );
};

export default HwcClientWarehouseDetailPage;
