import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Modal, Switch } from 'antd';
import { LabelUser, UserRegisterData } from '../../../../custom_types/labels/label-profile-types';
import {
  createLabelUserHandler,
  createOverseaWarehouseUserHandler,
  selectLoading,
  selectShowUserModal,
  setShowUserModal,
  updateLabelUserHandler
} from '../../../../redux/Labels/LabelUserSlice';
import { Label } from '../../../../custom_types/order-page';
import PasswordFormItems from '../../../../shared/components/PasswordFormItems';

interface CreateWarehouseUserModalProps {
  warehouseId: string;
  warehouseUser: LabelUser | undefined;
  isOverseaWarehouse: boolean;
}

const CreateWarehouseUserModal = ({
  warehouseId,
  warehouseUser,
  isOverseaWarehouse
}: CreateWarehouseUserModalProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const loading = useSelector(selectLoading);
  const showModal = useSelector(selectShowUserModal);
  const [isActive, setIsActive] = React.useState<boolean>(true);

  useEffect(() => {
    if (warehouseUser) {
      form.setFieldsValue(warehouseUser);
      setIsActive(warehouseUser.active);
    }
  }, [warehouseUser, form]);

  const closeClickedHandler = () => {
    form.resetFields();
    dispatch(setShowUserModal(false));
  };

  const createOKClickedHandler = () => {
    form.validateFields().then((values) => {
      const createWarehouseUserData: UserRegisterData = {
        ...values,
        password: values['new-password'],
        confirmPassword: values['confirm-password'],
        warehouseRef: warehouseId
      };
      if (isOverseaWarehouse) {
        dispatch(createOverseaWarehouseUserHandler(createWarehouseUserData));
      } else {
        dispatch(createLabelUserHandler(createWarehouseUserData));
      }

      form.resetFields();
    });
  };

  const updateOKClickedHandler = () => {
    if (warehouseUser) {
      form.validateFields().then((values) => {
        const updateWarehouseUserData: LabelUser = {
          id: warehouseUser.id,
          ...values,
          active: isActive,
          warehouseRef: warehouseId
        };
        dispatch(updateLabelUserHandler(updateWarehouseUserData));
        form.resetFields();
      });
    }
  };

  return (
    <Modal
      closable={false}
      title="添加用户"
      cancelText="取消"
      onCancel={closeClickedHandler}
      okText="保存"
      onOk={warehouseUser ? updateOKClickedHandler : createOKClickedHandler}
      visible={showModal}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        {!warehouseUser && <PasswordFormItems label="密码" showDescription />}
        <Form.Item name="phone" label="Phone">
          <Input placeholder="Phone" />
        </Form.Item>
        {warehouseUser && (
          <Form.Item>
            <Switch
              checkedChildren="启用"
              unCheckedChildren="停用"
              checked={isActive}
              onClick={() => setIsActive(!isActive)}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CreateWarehouseUserModal;
