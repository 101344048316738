import React, { useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Space, Tabs } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { HwcWarehouse } from '../../../../custom_types/redux-types';
import {
  createScheduleJobHandler,
  searchSechduleJobHandler,
  selectSecduleJob,
  selectSecduleJobLoading,
  selectShowSchecudleJobModal,
  setShowSchecudleJobModal
} from '../../../../redux/scheduleJobSlice';
import HwcScheduleJobElement from './HwcScheduleJobElement';
import {
  manualSyncPartnerFulfillOrders,
  selectHwcFulfillOrderSyncLoading
} from '../../../../redux/hwc/hwcFulfillOrderSyncSlice';
import HwcFulfillOrderSyncRecordTab from './HwcFulfillOrderSyncRecordTab';
import HwcScheduleJobModal from './HwcScheduleJobModal';
import { ScheduleJob } from '../../../../custom_types/scheduleJob';

interface HwcFulfillOrderSyncPanelProps {
  hwcWarehouse: HwcWarehouse;
}

const HwcFulfillOrderSyncPanel = ({ hwcWarehouse }: HwcFulfillOrderSyncPanelProps) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectHwcFulfillOrderSyncLoading);
  const scheduleJobs = useSelector(selectSecduleJob);
  const scheduleJobLoading = useSelector(selectSecduleJobLoading);
  const showSchecudleJobModal = useSelector(selectShowSchecudleJobModal);
  const [name, code, account] = hwcWarehouse.thirdPartyParnter.split('_');

  useEffect(() => {
    // Search HwcWarehouse FulfillOrder Sync Schedule Job
    dispatch(searchSechduleJobHandler({ hwcWarehouseId: hwcWarehouse.id, jobType: 'ordersync' }));
  }, [dispatch, hwcWarehouse.id]);

  const showCreateScheduleJobModal = () => {
    dispatch(setShowSchecudleJobModal(true));
  };

  const createFulfillOrderSyncScheduleJob = (values: any) => {
    console.log(values);
    const data: ScheduleJob = {
      id: '',
      jobType: 'ordersync',
      autoStart: values.autoStart,
      jobName: `ordersync:${hwcWarehouse.code}:${values.jobName}`,
      jobHandler: '',
      params: { hwcWarehouseId: hwcWarehouse.id },
      description: `Auto Fulfill Order Sync Job for ${hwcWarehouse.code}`,
      status: 0,
      createBy: '',
      updatedBy: '',
      createTime: dayjs().toDate(),
      updateTime: dayjs().toDate(),
      startTime: { hour: values['start-hour'], minute: values['start-minute'] },
      repeatTimes: values.repeatTimes,
      repeatInterval: {
        hour: values.repeatTimes === 1 ? 0 : values['repeat-hour'],
        minute: values.repeatTimes === 1 ? 0 : values['repeat-minute']
      },
      tz: values.tz,
      hwcWarehouseId: hwcWarehouse.id
    };
    dispatch(
      createScheduleJobHandler(data, { hwcWarehouseId: hwcWarehouse.id, jobType: 'ordersync' })
    );
  };

  const closeModalHandler = () => {
    dispatch(setShowSchecudleJobModal(false));
  };

  return (
    <div>
      {showSchecudleJobModal && (
        <HwcScheduleJobModal
          onOk={createFulfillOrderSyncScheduleJob}
          onCancel={closeModalHandler}
        />
      )}
      <PageHeader
        title={`同步${name}(${code}), ${account}, ${hwcWarehouse.thirdPartyCode}的订单`}
      />
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="订单同步任务" key="1">
          <>
            <Divider orientation="left">手动同步订单</Divider>
            <Space
              size="large"
              direction="horizontal"
              style={{ border: '1px solid #e8e8e8', padding: '20px', width: '100%' }}
            >
              <Button
                type="primary"
                loading={loading}
                onClick={() => dispatch(manualSyncPartnerFulfillOrders(hwcWarehouse.id))}
                icon={<SyncOutlined />}
              >
                开始同步订单
              </Button>
            </Space>
            <Divider orientation="left">自动同步订单设置</Divider>
            <Space
              size="large"
              direction="horizontal"
              style={{ border: '1px solid #e8e8e8', padding: '20px', width: '100%' }}
            >
              <Button
                type="primary"
                loading={scheduleJobLoading}
                onClick={showCreateScheduleJobModal}
                icon={<PlusOutlined />}
              >
                添加自动同步订单任务
              </Button>
            </Space>
            {scheduleJobs &&
              scheduleJobs.map((job) => (
                <HwcScheduleJobElement
                  job={job}
                  searchQuery={{ hwcWarehouseId: hwcWarehouse.id, jobType: 'ordersync' }}
                />
              ))}
          </>
        </Tabs.TabPane>
        <Tabs.TabPane tab="订单同步记录" key="2">
          <HwcFulfillOrderSyncRecordTab hwcWarehouseId={hwcWarehouse.id} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default HwcFulfillOrderSyncPanel;
