import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Space, Table, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Inventory } from '../../../../custom_types/wms-page';
import {
  deleteInventoryHandler,
  fetchInventoryForWarehouseHandler,
  selectInventories,
  selectInventoryLoading,
  selectInventoryModal,
  setInventoryModal
} from '../../../../redux/inventorySlice';
import InventoryEditModal from './InventoryEditModal';

interface InventoryPanelProps {
  warehouseId: string;
}

const InventoryPanel = ({ warehouseId }: InventoryPanelProps) => {
  const dispatch = useDispatch();
  const inventories = useSelector(selectInventories);
  const loading = useSelector(selectInventoryLoading);
  const inventoryModalVisible = useSelector(selectInventoryModal);
  const [curInventory, setCurInventory] = useState<Inventory | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchInventoryForWarehouseHandler(warehouseId));
  }, [dispatch, warehouseId]);

  const addInventoryHandler = () => {
    setCurInventory(undefined);
    dispatch(setInventoryModal(true));
  };

  const editInventoryClickedHandler = (record: Inventory) => {
    setCurInventory(record);
    dispatch(setInventoryModal(true));
  };

  const columns = [
    {
      title: 'ITEM',
      key: 'item',
      dataIndex: 'item'
    },
    {
      title: 'SKU',
      key: 'sku',
      dataIndex: 'sku'
    },
    {
      title: 'CATEGORY',
      key: 'category',
      dataIndex: 'category'
    },
    {
      title: 'TYPE',
      key: 'type',
      dataIndex: 'type'
    },
    {
      title: 'WEIGHT(KG)',
      key: 'weight',
      dataIndex: 'weight'
    },
    {
      title: 'ON HAND',
      key: 'onHand',
      dataIndex: 'onHand'
    },
    {
      title: 'MINIMUM STOCK',
      key: 'minimumStock',
      dataIndex: 'minimumStock'
    },
    {
      title: 'STATUS',
      key: 'status',
      render: (text: string, record: Inventory) => {
        return (
          <Tag color={record.onHand > record.minimumStock ? 'green' : 'red'}>
            {record.onHand > record.minimumStock ? 'In Stock' : 'Low Stock'}
          </Tag>
        );
      }
    },
    {
      title: 'SALE PRICE',
      key: 'salePrice',
      dataIndex: 'salePrice'
    },
    {
      title: 'COST',
      key: 'cost',
      dataIndex: 'cost'
    },
    {
      title: 'PROFIT',
      key: 'profit',
      dataIndex: 'profit'
    },
    {
      title: 'MARGIN',
      key: 'margin',
      dataIndex: 'margin',
      render: (text: string, record: Inventory) => {
        return `${(record.margin * 100).toFixed(2)}%`;
      }
    },
    {
      title: 'PREFERRED VENDOR',
      key: 'preferredVendor',
      dataIndex: 'preferredVendor'
    },
    {
      title: 'UNITES IN PURCHASE',
      key: 'unitesInPurchase',
      dataIndex: 'unitesInPurchase'
    },
    {
      title: 'NOTES',
      key: 'notes',
      dataIndex: 'notes'
    },
    {
      title: '操作',
      key: 'action',
      render: (text: string, record: Inventory) => (
        <Space size={0} split={<Divider type="vertical" />}>
          <Button size="small" type="link" onClick={() => editInventoryClickedHandler(record)}>
            编辑
          </Button>
          <Popconfirm
            title="确认删除该库存?"
            placement="topRight"
            onConfirm={() => dispatch(deleteInventoryHandler(record.id, warehouseId))}
            okText="确定"
            cancelText="取消"
          >
            <Button size="small" type="link">
              删除
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <div>
      <InventoryEditModal warehouseId={warehouseId} inventory={curInventory} />
      <PageHeader
        title=""
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() => dispatch(fetchInventoryForWarehouseHandler(warehouseId))}
          />,
          <Button key="1" type="primary" icon={<PlusOutlined />} onClick={addInventoryHandler}>
            添加库存
          </Button>
        ]}
      />
      <Table<Inventory>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={inventories}
        pagination={false}
        loading={loading}
      />
    </div>
  );
};

export default InventoryPanel;
