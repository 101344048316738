import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Layout, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserBasicInfo } from '../../../custom_types/profile-page';
import {
  fetchUserHandler,
  selectUsersList,
  selectUsersPageLoading,
  setUserModal
} from '../../../redux/user/userSlice';
import FooterComponent from '../../../shared/components/FooterComponent';
import NoData from '../../../shared/components/NoData';
import HeaderMenu from '../../Layout/components/HeaderMenu';
import AddUserModal from '../components/AddUserModal';
import columns from '../components/UsersTableColumns';

const { Content } = Layout;

const UsersPage = (): ReactElement => {
  const dispatch = useDispatch();
  const usersList = useSelector(selectUsersList);
  const loading = useSelector(selectUsersPageLoading);

  useEffect(() => {
    dispatch(fetchUserHandler());
  }, [dispatch]);
  return (
    <>
      <AddUserModal />
      <HeaderMenu title="Parcelspro" />
      <Content
        style={{
          padding: 24,
          margin: '24px 16px 0 16px',
          minHeight: '200px',
          background: '#fff'
        }}
      >
        <PageHeader
          title="系统用户"
          subTitle="管理系统用户账号"
          extra={[
            <Button
              key="1"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => dispatch(setUserModal(true))}
            >
              添加管理员
            </Button>
          ]}
        />
        <Divider />
        <Table<UserBasicInfo>
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={usersList}
          pagination={false}
          loading={loading}
          locale={{
            emptyText: <NoData />
          }}
        />
      </Content>
      <FooterComponent />
    </>
  );
};

export default UsersPage;
