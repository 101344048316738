import { Button, Popconfirm, Switch, Table, Tag } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-components';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  HwcClientWarehouse,
  HwcClientWarehouseChannel
} from '../../../../custom_types/redux-types';
import { selectHwcWarehouses } from '../../../../redux/hwcWarehouseSlice';
import { displayRate } from '../../../../shared/utils/helpers';
import {
  deleteHwcClientWarehouseHandler,
  fetchHwcClientWarehouseHandler,
  selectHwcClientWarehouseLodaing,
  selectHwcClientWarehouses,
  selectShowCreateHwcClientWarehouseModal,
  selectShowEditHwcClientWarehouseModal,
  setShowCreateHwcClientWarehouseModal,
  setShowEditHwcClientWarehouseModal,
  updateHwcClientWarehouseHandler
} from '../../../../redux/hwc/hwcClientWarehouseSlice';
import HwcClientWarehouseCreateModal from './HwcClientWarehouseCreateModal';
import HwcClientWarehouseEditModal from './HwcClientWarehouseEditModal';
import { UI_ROUTES } from '../../../../shared/utils/constants';

interface HwcClientWarehousePanelProps {
  hwcClientId: string;
  hwcClientName: string;
}

const HwcClientWarehousePanel = ({
  hwcClientId,
  hwcClientName
}: HwcClientWarehousePanelProps): ReactElement => {
  const dispatch = useDispatch();
  const warehouses = useSelector(selectHwcWarehouses);
  const loading = useSelector(selectHwcClientWarehouseLodaing);
  const showModal = useSelector(selectShowCreateHwcClientWarehouseModal);
  const showEditModal = useSelector(selectShowEditHwcClientWarehouseModal);
  const clientWarehouses = useSelector(selectHwcClientWarehouses);
  const [curClientWarehouse, setCurClientWarehouse] = React.useState<HwcClientWarehouse>();

  useEffect(() => {
    dispatch(fetchHwcClientWarehouseHandler(hwcClientId));
  }, [dispatch, hwcClientId]);

  const statusChangeHandler = async (active: boolean, value: HwcClientWarehouse) => {
    const data = { ...value, active };
    dispatch(updateHwcClientWarehouseHandler(value.id, data));
  };

  const editCarrierClickedHandler = (record: HwcClientWarehouse) => {
    setCurClientWarehouse(record);
    dispatch(setShowEditHwcClientWarehouseModal(true));
  };

  const columns = [
    {
      title: '仓库名称',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '仓库代码',
      key: 'code',
      dataIndex: 'code'
    },
    {
      title: '状态',
      dataIndex: 'active',
      key: 'active',
      render: (value: boolean, record: HwcClientWarehouse) => (
        <Switch
          checkedChildren="启用"
          unCheckedChildren="停用"
          defaultChecked
          checked={value}
          onChange={() => statusChangeHandler(!value, record)}
        />
      )
    },
    {
      title: '物流渠道',
      key: 'channels',
      render: (channels: HwcClientWarehouseChannel[], record: HwcClientWarehouse) => {
        return record.channels.map((channel) => (
          <div>
            {channel.name}:{'  '}
            {displayRate(channel.fee)}
          </div>
        ));
      }
    },
    {
      title: 'ERP 授权',
      key: 'erp_authorized',
      render: (_: any, record: HwcClientWarehouse) => {
        let status: ReactElement = <></>;
        const warehouse = warehouses.find((item) => item.code === record.code);
        if (record.mabangAccesstoken) {
          // 已授权
          status = <Tag color="green">已授权</Tag>;
        } else if (record.mabangAuthUrl) {
          // 授权中
          status = <Tag color="blue">授权中</Tag>;
        } else if (warehouse && warehouse.mabangAppKey) {
          // 未授权
          status = <Tag color="gold">未授权</Tag>;
        } else {
          // 不可用
          status = <Tag color="red">不可用</Tag>;
        }
        return <>马帮: {status}</>;
      }
    },
    {
      title: '操作',
      key: 'operations',
      render: (text: string, record: HwcClientWarehouse): ReactElement => {
        return (
          <>
            <Link
              to={`${UI_ROUTES.WMS}${UI_ROUTES.OVERSEA_WAREHOUSES_CK}/${record.id}/${hwcClientId}/${hwcClientName}`}
            >
              详情
            </Link>
            <span> | </span>
            <Button size="small" type="link" onClick={() => editCarrierClickedHandler(record)}>
              编辑
            </Button>
            <span> | </span>
            <Popconfirm
              key="确认删除该仓库"
              title="确认删除该仓库?"
              placement="topRight"
              onConfirm={() =>
                dispatch(deleteHwcClientWarehouseHandler(record.hwcClientRef, record.id))
              }
              okText="确定"
              cancelText="取消"
            >
              <Button key="删除" size="small" type="link">
                删除
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  return (
    <div>
      {showModal && <HwcClientWarehouseCreateModal hwcClientId={hwcClientId} />}
      {showEditModal && curClientWarehouse && (
        <HwcClientWarehouseEditModal hwcClientWarehouse={curClientWarehouse} />
      )}
      <PageHeader
        title=""
        extra={[
          <Button
            icon={<SyncOutlined spin={loading} />}
            onClick={() => dispatch(fetchHwcClientWarehouseHandler(hwcClientId))}
          />,
          <Button
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(setShowCreateHwcClientWarehouseModal(true))}
          >
            授权仓库
          </Button>
        ]}
      />
      <Table<HwcClientWarehouse>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={clientWarehouses}
        loading={loading}
      />
    </div>
  );
};

export default HwcClientWarehousePanel;
