import { Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateWmsUserData } from '../../../custom_types/profile-page';
import {
  createUserHandler,
  selectUserModal,
  setUserModal
} from '../../../redux/user/userSlice';
import PasswordFormItems from '../../../shared/components/PasswordFormItems';
import { USER_ROLES, USER_ROLES_TEXT } from '../../../shared/utils/constants';

const AddUserModal = (): ReactElement => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const showModal = useSelector(selectUserModal);

  const { Option } = Select;

  const onCancelClickedHandler = () => {
    form.resetFields();
    dispatch(setUserModal(false));
  };

  const onOkClickedHandler = () => {
    form.validateFields().then((values: any) => {
      const data: CreateWmsUserData = {
        name: values.name,
        company: values.company,
        phone: values.phone,
        email: values.email,
        password: values['new-password'],
        confirmPassword: values['confirm-password'],
        role: values.role
      };
      dispatch(createUserHandler(data));
      form.resetFields();
    });
  };

  return (
    <Modal
      closable={false}
      title="添加系统用户"
      cancelText="取消"
      onCancel={onCancelClickedHandler}
      okText="添加用户"
      onOk={onOkClickedHandler}
      visible={showModal}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="用户姓名"
          name="name"
          rules={[{ required: true, message: '用户姓名必须填' }]}
        >
          <Input placeholder="用户姓名" />
        </Form.Item>
        <Form.Item label="公司名称" name="company">
          <Input placeholder="公司名称" />
        </Form.Item>
        <Form.Item
          label="用户邮箱"
          name="email"
          rules={[
            { type: 'email', message: '邮箱格式不正确!' },
            { required: true, message: '请输入用户邮箱!' }
          ]}
        >
          <Input placeholder="用户邮箱" />
        </Form.Item>
        <Form.Item label="用户手机" name="phone">
          <Input placeholder="用户手机" />
        </Form.Item>
        <Form.Item
          label="用户权限"
          name="role"
          rules={[{ required: true, message: '用户权限必须填' }]}
        >
          <Select>
            {Object.values(USER_ROLES).map((ele) => (
              <Option key={ele} value={ele}>
                {USER_ROLES_TEXT[ele]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <PasswordFormItems label="密码" showDescription />
      </Form>
    </Modal>
  );
};

export default AddUserModal;
