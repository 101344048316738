import { BarcodeOutlined, TeamOutlined } from '@ant-design/icons';
import { Layout, Card, Row, Col } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { UI_ROUTES } from '../../../shared/utils/constants';
import HeaderMenu from '../components/HeaderMenu';
import FooterComponent from '../../../shared/components/FooterComponent';
import { fetchCarriersHandler } from '../../../redux/carriersSlice';

const { Content } = Layout;
const { Meta } = Card;

const SuperAdminPage = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCarriersHandler());
  }, [dispatch]);

  const gridStyle = {
    width: '100%',
    margin: '2%'
  };

  const iconStyle = { fontSize: '150px', margin: '30px' };

  return (
    <>
      <HeaderMenu title="Parcelspro" />
      <Content style={{ margin: '16px' }}>
        <Card style={{ width: '70%', margin: 'auto', textAlign: 'center' }}>
          <Row gutter={8}>
            <Col span={12}>
              <Link style={{ color: 'inherit' }} to={UI_ROUTES.LABELS}>
                <Card.Grid style={gridStyle} hoverable>
                  <div>
                    <BarcodeOutlined style={iconStyle} />
                  </div>
                  <Meta
                    title={<h2>Parcelspro 面单服务</h2>}
                    description="管理Parcelspro面单服务的相关业务"
                  />
                </Card.Grid>
              </Link>
            </Col>
            <Col span={12}>
              <Link style={{ color: 'inherit' }} to={UI_ROUTES.WMS}>
                <Card.Grid style={gridStyle} hoverable>
                  <div>
                    <FontAwesomeIcon icon={faWarehouse} style={iconStyle} />
                  </div>
                  <Meta title={<h2>Parcelspro WMS</h2>} description="Parcelspro WMS 仓储管理系统" />
                </Card.Grid>
              </Link>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Link style={{ color: 'inherit' }} to={UI_ROUTES.CARRIERS}>
                <Card.Grid style={gridStyle} hoverable>
                  <div />
                  <FontAwesomeIcon icon={faFileInvoice} style={iconStyle} />
                  <Meta
                    title={<h2>物流账号</h2>}
                    description="系统基础物流账号，将提供给打单和仓储服务使用"
                  />
                </Card.Grid>
              </Link>
            </Col>
            <Col span={12}>
              <Link style={{ color: 'inherit' }} to={UI_ROUTES.USERS}>
                <Card.Grid style={gridStyle} hoverable>
                  <div>
                    <TeamOutlined style={iconStyle} />
                  </div>
                  <Meta title={<h2>系统用户</h2>} description="添加，删除，修改系统管理员" />
                </Card.Grid>
              </Link>
            </Col>
          </Row>
        </Card>
      </Content>
      <FooterComponent />
    </>
  );
};

export default SuperAdminPage;
