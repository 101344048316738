import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Descriptions } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import { HwcPartner } from '../../../../custom_types/overseawarehouse/hwc-partner';

interface HwcPartnerHeaderProps {
  hwcPartner: HwcPartner | undefined;
  footer: ReactElement;
}

const HwcPartnerHeader = ({ hwcPartner, footer }: HwcPartnerHeaderProps) => {
  const history = useHistory();
  return (
    <PageHeader
      title="合作海外仓详情"
      footer={footer}
      onBack={() => history.push(`${UI_ROUTES.WMS}${UI_ROUTES.HWC_PARTNERS}`)}
    >
      {hwcPartner && (
        <Row>
          <Col span={12}>
            <Descriptions size="small" column={3}>
              <Descriptions.Item label="名称">{hwcPartner.name}</Descriptions.Item>
              <Descriptions.Item label="代码">{hwcPartner.code}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      )}
    </PageHeader>
  );
};

export default HwcPartnerHeader;
