import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Table, Space, Divider, Switch, Popconfirm, Layout } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CarriersList from '../components/CarriersLists';
import {
  selectCarriers,
  fetchCarriersHandler,
  selectCarriersLoading,
  setRedirectToCarriersPage,
  deleteCarrierHandler,
  updateCarrierHandler
} from '../../../redux/carriersSlice';
import { Carrier } from '../../../custom_types/carrier-page';
import { getCarrierLogo } from '../../../shared/utils/logo.helper';
import HeaderMenu from '../../Layout/components/HeaderMenu';
import { CARRIERS, UI_ROUTES } from '../../../shared/utils/constants';
import NoData from '../../../shared/components/NoData';
import FooterComponent from '../../../shared/components/FooterComponent';

const { Content } = Layout;

const CarriersPage = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const carriers = useSelector(selectCarriers);
  const carriersLoading = useSelector(selectCarriersLoading);
  const [showCarriersList, setShowCarriersList] = useState(false);

  useEffect(() => {
    dispatch(fetchCarriersHandler());
    dispatch(setRedirectToCarriersPage(false));
    setShowCarriersList(false);
  }, [dispatch]);

  const statusChangeHandler = async (active: boolean, value: Carrier) => {
    const data = { ...value, isActive: active };
    dispatch(updateCarrierHandler(data));
  };

  const showCarriersListHandler = () => setShowCarriersList(true);
  const hideCarriersListHandler = () => setShowCarriersList(false);

  const showAddCarrierModalHandler = (carrier: string) => {
    setShowCarriersList(false);
    history.push(`${UI_ROUTES.CARRIERS}${UI_ROUTES.EDIT}/${carrier}`);
  };

  const columns = [
    {
      title: '',
      key: 'logo',
      render: (text: string, record: Carrier) => {
        const logo = getCarrierLogo(record.carrierName);
        return (
          <img style={{ width: '60px', height: '60px' }} src={logo} alt={record.carrierName} />
        );
      }
    },
    {
      title: '物流公司',
      dataIndex: 'carrierName',
      key: 'carrierName'
    },
    {
      title: '账号名称',
      dataIndex: 'accountName',
      key: 'accountName'
    },
    {
      title: '账号说明',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '物流服务',
      dataIndex: 'servies',
      key: 'services',
      render: (text: string, record: Carrier): string => {
        if (record.carrierName === CARRIERS.RUI_YUN) {
          return record.services.map((ele) => ele.name).join(', ');
        }
        if (record.carrierName === CARRIERS.USPS3) {
          return record.services.map((ele) => `${ele.key}-${ele.name}`).join(', ');
        }
        return record.services.map((ele) => ele.key).join(', ');
      }
    },
    {
      title: '服务范围',
      dataIndex: 'regions',
      key: 'regions',
      render: (text: string, record: Carrier): string => {
        return record.regions.join(', ');
      }
    },
    {
      title: '状态',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (value: boolean, record: Carrier) => (
        <Switch
          checkedChildren="启用"
          unCheckedChildren="停用"
          defaultChecked
          checked={value}
          onChange={() => statusChangeHandler(!value, record)}
        />
      )
    },
    {
      title: '操作',
      key: 'action',
      render: (text: string, record: Carrier) => (
        <Space size={0} split={<Divider type="vertical" />}>
          <Button
            size="small"
            type="link"
            onClick={() => history.push(`${UI_ROUTES.CARRIERS}${UI_ROUTES.EDIT}/${record.id}`)}
          >
            编辑
          </Button>
          <Popconfirm
            title="确认删除该账号?"
            placement="topRight"
            onConfirm={() => dispatch(deleteCarrierHandler(record.id))}
            okText="确定"
            cancelText="取消"
          >
            <Button size="small" type="link">
              删除
            </Button>
          </Popconfirm>
        </Space>
      )
    }
  ];

  return (
    <>
      <HeaderMenu title="Parcelspro" />
      <Content
        style={{
          padding: 24,
          margin: '24px 16px 0 16px',
          minHeight: '200px',
          background: '#fff'
        }}
      >
        <CarriersList
          visible={showCarriersList}
          handleCancel={hideCarriersListHandler}
          imageClicked={showAddCarrierModalHandler}
        />
        <PageHeader
          title="物流账号"
          subTitle="管理系统基础物流账号，供面单服务和仓储服务使用"
          extra={[
            <Button
              key="1"
              type="primary"
              icon={<PlusOutlined />}
              onClick={showCarriersListHandler}
            >
              添加账号
            </Button>
          ]}
        />
        <Divider />
        <Table<Carrier>
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={carriers}
          pagination={false}
          loading={carriersLoading}
          locale={{
            emptyText: <NoData />
          }}
        />
      </Content>
      <FooterComponent />
    </>
  );
};

export default CarriersPage;
