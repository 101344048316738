import React, { ReactElement } from 'react';
import { Modal, Button, Row, Image, Space, Divider } from 'antd';
import { CARRIERS } from '../../../shared/utils/constants';
import dhleCommerceLogo from '../../../assets/images/carriers/dhl-ecommerce-logo.svg';
import fedexLogo from '../../../assets/images/carriers/fedex-logo.svg';
import upsLogo from '../../../assets/images/carriers/ups-logo.svg';
import uspsLogo from '../../../assets/images/carriers/usps-logo.svg';
import pbLogo from '../../../assets/images/carriers/pitney-bowes-logo.png';
import ruiyunLogo from '../../../assets/images/carriers/ruiyun-logo.png';
import usps3Logo from '../../../assets/images/carriers/3usps-logo.png';

interface CarriersListProps {
  visible: boolean;
  handleCancel: () => void;
  imageClicked: (carrier: string) => void;
}

const CarriersList = ({ visible, handleCancel, imageClicked }: CarriersListProps): ReactElement => {
  return (
    <Modal
      width={575}
      styles={{ body: { minHeight: '300px', maxHeight: '480px' } }}
      centered
      title="添加物流账号"
      open={visible}
      closable={false}
      footer={<Button onClick={handleCancel}>取消</Button>}
      transitionName=""
      maskTransitionName=""
    >
      <Row>
        <Space size="middle">
          <Image
            onClick={() => imageClicked(CARRIERS.DHL_ECOM)}
            style={{ cursor: 'pointer' }}
            width={120}
            height={73.84}
            preview={false}
            src={dhleCommerceLogo}
            alt={CARRIERS.DHL_ECOM}
          />
          <Image
            onClick={() => imageClicked(CARRIERS.FEDEX)}
            style={{ cursor: 'pointer' }}
            width={120}
            height={73.84}
            preview={false}
            src={fedexLogo}
            alt={CARRIERS.FEDEX}
          />
          <Image
            onClick={() => imageClicked(CARRIERS.UPS)}
            style={{ cursor: 'pointer' }}
            width={120}
            height={73.84}
            preview={false}
            src={upsLogo}
            alt={CARRIERS.UPS}
          />
          <Image
            onClick={() => imageClicked(CARRIERS.USPS)}
            style={{ cursor: 'pointer' }}
            width={120}
            height={73.84}
            preview={false}
            src={uspsLogo}
            alt={CARRIERS.USPS}
          />
        </Space>
      </Row>
      <Divider />
      <Row>
        <Space size="middle">
          <Image
            // onClick={() => imageClicked(CARRIERS.PITNEY_BOWES)}
            style={{ cursor: 'pointer' }}
            width={120}
            height={73.84}
            preview={false}
            src={pbLogo}
            alt={CARRIERS.PITNEY_BOWES}
          />
          <Image
            onClick={() => imageClicked(CARRIERS.RUI_YUN)}
            style={{ cursor: 'pointer' }}
            width={120}
            height={73.84}
            preview={false}
            src={ruiyunLogo}
            alt={CARRIERS.RUI_YUN}
          />
          <Image
            onClick={() => imageClicked(CARRIERS.USPS3)}
            style={{ cursor: 'pointer' }}
            width={120}
            height={73.84}
            preview={false}
            src={usps3Logo}
            alt={CARRIERS.USPS3}
          />
        </Space>
      </Row>
    </Modal>
  );
};

export default CarriersList;
