import { Dispatch, createSlice } from '@reduxjs/toolkit';
import { AppThunk, OverseaWarehouseState, RootState } from '../custom_types/redux-types';
import axios from '../shared/utils/axios.base';
import { SERVER_ROUTES } from '../shared/utils/constants';
import errorHandler from '../shared/components/errorHandler';
import { CreateOverseaWarehouseData, OverseaWarehouse } from '../custom_types/wms-page';
import { ResponseError } from '../custom_types/common';

const initialState: OverseaWarehouseState = {
  overseaWarehouses: [],
  loading: false,
  createOverseaWarehouseModal: false,
  editOverseaWarehouseModal: false,
  overseaWarehouseModalLoading: false
};

export const overseaWarehouseSlice = createSlice({
  name: 'overseaWarehouse',
  initialState,
  reducers: {
    setOverseaWarehouses: (state, action) => {
      state.overseaWarehouses = action.payload;
    },
    setOverseaWarehouseLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreateOverseaWarehouseModal: (state, action) => {
      state.createOverseaWarehouseModal = action.payload;
    },
    setEditOverseaWarehouseModal: (state, action) => {
      state.editOverseaWarehouseModal = action.payload;
    },
    setOverseaWarehouseModalLoading: (state, action) => {
      state.overseaWarehouseModalLoading = action.payload;
    }
  }
});

export const {
  setOverseaWarehouses,
  setOverseaWarehouseLoading,
  setCreateOverseaWarehouseModal,
  setEditOverseaWarehouseModal,
  setOverseaWarehouseModalLoading
} = overseaWarehouseSlice.actions;

export const fetchOverseaWarehouseHanlder = (): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setOverseaWarehouseLoading(true));
    try {
      const response = await axios.get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.OVERSEA_WAREHOUSES}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setOverseaWarehouses(response.data));
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setOverseaWarehouseLoading(false));
    }
  }
};

export const createOverseaWarehouseHandler = (data: CreateOverseaWarehouseData): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setOverseaWarehouseLoading(true));
    try {
      await axios.post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.OVERSEA_WAREHOUSES}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setCreateOverseaWarehouseModal(false));
      dispatch(fetchOverseaWarehouseHanlder());
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setOverseaWarehouseLoading(false));
    }
  }
};

export const updateOverseaWarehouseHandler = (
  id: string,
  data: OverseaWarehouse
): AppThunk => async (dispatch: Dispatch, getState: () => RootState) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setOverseaWarehouseModalLoading(true));
    try {
      await axios.put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.OVERSEA_WAREHOUSES}/${id}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setEditOverseaWarehouseModal(false));
      dispatch(fetchOverseaWarehouseHanlder());
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setOverseaWarehouseModalLoading(false));
    }
  }
};

export const createAPITokenHandler = (id: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setOverseaWarehouseModalLoading(true));
    try {
      await axios.get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.OVERSEA_WAREHOUSES}${SERVER_ROUTES.API}/refresh/${id}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
      dispatch(fetchOverseaWarehouseHanlder());
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setOverseaWarehouseModalLoading(false));
    }
  }
};

export const deleteAPITokenHandler = (id: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setOverseaWarehouseModalLoading(true));
    try {
      await axios.get(
        `${SERVER_ROUTES.WMS}${SERVER_ROUTES.OVERSEA_WAREHOUSES}${SERVER_ROUTES.API}/revoke/${id}`,
        {
          headers: {
            Authorization: `${user.token_type} ${user.token}`
          }
        }
      );
    } catch (error) {
      errorHandler(error as ResponseError<any>, dispatch);
    } finally {
      dispatch(setOverseaWarehouseModalLoading(false));
    }
  }
};

export const selectLoading = (state: RootState): boolean => state.overseaWarehouse.loading;
export const selectOverseaWarehouses = (state: RootState): OverseaWarehouse[] =>
  state.overseaWarehouse.overseaWarehouses;
export const selectEditOverseaWareHouseModal = (state: RootState): boolean =>
  state.overseaWarehouse.editOverseaWarehouseModal;
export const selectCreateOverseaWarehouseModal = (state: RootState): boolean =>
  state.overseaWarehouse.createOverseaWarehouseModal;
export const selectOverseaWarehouseModalLoading = (state: RootState): boolean =>
  state.overseaWarehouse.overseaWarehouseModalLoading;

export default overseaWarehouseSlice.reducer;
