import { Divider, Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { UI_ROUTES } from '../../../../shared/utils/constants';
import { selectHwcWarehouses } from '../../../../redux/hwcWarehouseSlice';
import { HwcWarehouse } from '../../../../custom_types/redux-types';
import HwcWarehouseHeader from '../components/HwcWarehouseHeader';
import { HWC_WAREHOUSES_OPERATIONS } from '../../../../shared/utils/wms/wms.constants';
import HwcWarehouseERPPanel from '../components/HwcWarehouseERPPanel';
import HwcWarehouseCarrierPanel from '../components/HwcWarehouseCarriersPanel';
import HwcStockSyncPanel from '../components/HwcStockSyncPanel';
import HwcFulfillOrderSyncPanel from '../components/HwcFulfillOrderSyncPanel';

const { TabPane } = Tabs;

const HwcWarehouseDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const hwcWarehouseList = useSelector(selectHwcWarehouses);
  const [selectedHwcWarehouse, setSelectedHwcWarehouse] = React.useState<HwcWarehouse | undefined>(
    undefined
  );

  useEffect(() => {
    const curHwcWarehouse = hwcWarehouseList.find((ele: HwcWarehouse) => ele.id === id);
    if (curHwcWarehouse) {
      setSelectedHwcWarehouse(curHwcWarehouse);
    } else {
      history.push(`${UI_ROUTES.WMS}${UI_ROUTES.HWC_WAREHOUSES}`);
    }
  }, [history, id, hwcWarehouseList, dispatch]);

  return (
    <>
      <HwcWarehouseHeader
        hwcWarehouse={selectedHwcWarehouse}
        footer={
          <>
            <Divider />
            {selectedHwcWarehouse && (
              <Tabs defaultActiveKey={HWC_WAREHOUSES_OPERATIONS.ERP}>
                <TabPane tab={HWC_WAREHOUSES_OPERATIONS.ERP} key={HWC_WAREHOUSES_OPERATIONS.ERP}>
                  <HwcWarehouseERPPanel hwcWarehouse={selectedHwcWarehouse} />
                </TabPane>
                <TabPane
                  tab={HWC_WAREHOUSES_OPERATIONS.CARRIERS}
                  key={HWC_WAREHOUSES_OPERATIONS.CARRIERS}
                >
                  <HwcWarehouseCarrierPanel hwcWarehouseId={selectedHwcWarehouse.id} />
                </TabPane>
                <TabPane
                  tab={HWC_WAREHOUSES_OPERATIONS.STOCKS}
                  key={HWC_WAREHOUSES_OPERATIONS.STOCKS}
                >
                  <HwcStockSyncPanel hwcWarehouse={selectedHwcWarehouse} />
                </TabPane>
                <TabPane
                  tab={HWC_WAREHOUSES_OPERATIONS.ORDERS}
                  key={HWC_WAREHOUSES_OPERATIONS.ORDERS}
                >
                  <HwcFulfillOrderSyncPanel hwcWarehouse={selectedHwcWarehouse} />
                </TabPane>
              </Tabs>
            )}
          </>
        }
      />
    </>
  );
};

export default HwcWarehouseDetailPage;
