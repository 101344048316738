import React, { ReactElement, useEffect } from 'react';
import { Modal, Form, Input, Space, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Country, COUNTRY_NAMES } from '../../../../shared/utils/constants';
import {
  createHwcPartnerWarehouseHandler,
  selectModalLoading,
  selectShowModal,
  setShowModal
} from '../../../../redux/hwcPartnerWarehouseSlice';
import { HwcPartnerWarehouseData } from '../../../../custom_types/redux-types';

interface HwcPartnerWarehouseCreateModalProps {
  hwcPartnerId: string;
}

const { Option } = Select;

const HwcPartnerWarehouseCreateModal = ({
  hwcPartnerId
}: HwcPartnerWarehouseCreateModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const showModal = useSelector(selectShowModal);
  const modalLoading = useSelector(selectModalLoading);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const cancelClickedHandler = () => {
    form.resetFields();
    dispatch(setShowModal(false));
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        const data: HwcPartnerWarehouseData = {
          name: values.name,
          code: values.code,
          contact: values.contact,
          phone: values.phone,
          email: values.email,
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          zip: values.zip,
          country: values.country,
          ztqd: values.ztqd,
          hwcPartnerRef: hwcPartnerId
        };
        dispatch(createHwcPartnerWarehouseHandler(data));
        form.resetFields();
      })
      .catch(() => {});
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      centered
      closable={false}
      open={showModal}
      okText="添加仓库"
      cancelText="取消"
      title="添加仓库信息"
      onCancel={cancelClickedHandler}
      onOk={okClickedHandler}
      okButtonProps={{ loading: modalLoading }}
    >
      <Form form={form} layout="vertical">
        <Space size="large">
          <Form.Item
            label="仓库名称"
            name="name"
            rules={[{ required: true, message: '仓库名称必须填' }]}
          >
            <Input placeholder="仓库名称" autoFocus />
          </Form.Item>
          <Form.Item
            label="仓库代码"
            name="code"
            rules={[{ required: true, message: '仓库代码必须填' }]}
          >
            <Input placeholder="仓库代码" />
          </Form.Item>
        </Space>
        <Space size="large">
          <Form.Item
            label="联系人"
            name="contact"
            rules={[{ required: true, message: '联系人必须填' }]}
          >
            <Input placeholder="联系人" />
          </Form.Item>
          <Form.Item label="电话" name="phone">
            <Input placeholder="电话" />
          </Form.Item>
        </Space>
        <Form.Item
          label="自提渠道"
          name="ztqd"
          rules={[{ required: true, message: '自提渠道必须填' }]}
        >
          <Input placeholder="自提渠道" />
        </Form.Item>
        <Form.Item label="邮箱" name="email">
          <Input placeholder="邮箱" />
        </Form.Item>
        <Form.Item
          label="地址1"
          name="address1"
          rules={[{ required: true, message: '地址1必须填' }]}
        >
          <Input placeholder="地址1" />
        </Form.Item>
        <Form.Item label="地址2" name="address2">
          <Input placeholder="地址2" />
        </Form.Item>
        <Space size="large">
          <Form.Item label="城市" name="city" rules={[{ required: true, message: '城市必须填' }]}>
            <Input placeholder="城市" />
          </Form.Item>
          <Form.Item label="州" name="state" rules={[{ required: true, message: '州必须填' }]}>
            <Input placeholder="州" />
          </Form.Item>
        </Space>
        <Space size="large">
          <Form.Item label="邮编" name="zip" rules={[{ required: true, message: '邮编必须填' }]}>
            <Input placeholder="邮编" />
          </Form.Item>
          <Form.Item
            label="国家"
            name="country"
            rules={[{ required: true, message: '国家必须填' }]}
          >
            <Select placeholder="国家" style={{ width: 180 }}>
              <Option value={Country.USA}>{COUNTRY_NAMES[Country.USA]}</Option>
              <Option value={Country.CHINA}>{COUNTRY_NAMES[Country.CHINA]}</Option>
            </Select>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default HwcPartnerWarehouseCreateModal;
