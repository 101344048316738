import { Checkbox, Form, Input, Modal, Select, Space } from 'antd';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createHwcProductHandler,
  selectHwcProductModalLoading,
  selectShowCreateHwcProductModal,
  setShowCreateHwcProductModal
} from '../../../../../redux/hwc/hwcProductSlice';
import { HwcProductCreateObj } from '../../../../../custom_types/overseawarehouse/hwc-product';

interface HwcProductCreateModalProps {
  clientId: string;
  clientWarehouseId: string;
}

const HwcProductCreateModal = ({
  clientId,
  clientWarehouseId
}: HwcProductCreateModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const showModal = useSelector(selectShowCreateHwcProductModal);
  const modalLoading = useSelector(selectHwcProductModalLoading);
  const [sync, setSync] = React.useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const cancelClickedHandler = () => {
    form.resetFields();
    dispatch(setShowCreateHwcProductModal(false));
  };

  const okClickedHandler = () => {
    form.validateFields().then((values) => {
      console.log(values);
      const data: HwcProductCreateObj = {
        ck_sku: values.ck_sku,
        pt_sku: values.pt_sku,
        pname: values.pname,
        pname_en: values.pname_en,
        hsnums: values.hsnums,
        pvalue: values.pvalue,
        country: values.country,
        description: values.description,
        weight: values.weight,
        length: values.length,
        width: values.width,
        height: values.height,
        colour: values.colour,
        img_web: values.img_web,
        dc: values.dc,
        clientWarehouseRef: clientWarehouseId,
        clientRef: clientId
      };
      dispatch(createHwcProductHandler(data, sync));
    });
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      centered
      closable={false}
      open={showModal}
      okText="添加商品"
      cancelText="取消"
      title="添加商品"
      onCancel={cancelClickedHandler}
      onOk={okClickedHandler}
      okButtonProps={{ loading: modalLoading }}
    >
      <Form form={form} layout="vertical">
        <Space size="large">
          <Form.Item label="SKU" name="ck_sku" rules={[{ required: true, message: '请输入SKU' }]}>
            <Input placeholder="请输入SKU" />
          </Form.Item>
          <Form.Item label="平台SKU" name="pt_sku">
            <Input placeholder="请输入平台SKU" />
          </Form.Item>
        </Space>
        <Space size="large">
          <Form.Item
            label="产品名称"
            name="pname"
            rules={[{ required: true, message: '请输入产品名称' }]}
          >
            <Input placeholder="请输入产品名称" />
          </Form.Item>
          <Form.Item
            label="英文名称"
            name="pname_en"
            rules={[{ required: true, message: '请输入英文名称' }]}
          >
            <Input placeholder="请输入英文名称" />
          </Form.Item>
        </Space>
        <Space size="large">
          <Form.Item label="海关编码" name="hsnums">
            <Input placeholder="请输入海关编码" />
          </Form.Item>
          <Form.Item label="申报价值" name="pvalue">
            <Input placeholder="请输入申报价值" />
          </Form.Item>
        </Space>
        <Space size="large">
          <Form.Item label="原产地" name="country">
            <Input placeholder="请输入原产地" />
          </Form.Item>
          <Form.Item label="产品描述" name="description">
            <Input placeholder="请输入产品描述" />
          </Form.Item>
        </Space>
        <Space size="large">
          <Form.Item
            label="重量(lb)"
            name="weight"
            rules={[{ required: true, message: '请输入重量' }]}
          >
            <Input placeholder="重量" />
          </Form.Item>
          <Space size="small">
            <Form.Item
              label="长(in)"
              name="length"
              rules={[{ required: true, message: '请输入长' }]}
            >
              <Input placeholder="长" />
            </Form.Item>
            <Form.Item
              label="宽(in)"
              name="width"
              rules={[{ required: true, message: '请输入宽' }]}
            >
              <Input placeholder="宽" />
            </Form.Item>
            <Form.Item
              label="高(in)"
              name="height"
              rules={[{ required: true, message: '请输入高' }]}
            >
              <Input placeholder="高" />
            </Form.Item>
          </Space>
        </Space>
        <Space size="large">
          <Form.Item label="颜色" name="colour">
            <Input placeholder="请输入颜色" />
          </Form.Item>
          <Form.Item label="含电池" name="dc" initialValue="0">
            <Select
              placeholder="请选择含电池"
              options={[
                { label: '不含电池', value: '0' },
                { label: '含电池', value: '1' }
              ]}
            />
          </Form.Item>
        </Space>
        <Form.Item label="图片网络URL" name="img_web">
          <Input placeholder="图片网络URL" />
        </Form.Item>
        <Form.Item name="sync">
          <Checkbox onChange={(e) => setSync(e.target.checked)}>商品同步到合作仓库系统</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HwcProductCreateModal;
