import React, { ReactElement } from 'react';
import { OverseaWarehouse } from '../../../../custom_types/wms-page';

const OverseaWarehouseColumn: any[] = [
  {
    title: '客户名称',
    key: 'name',
    dataIndex: 'name'
  },
  {
    title: '客户代码',
    key: 'code',
    dataIndex: 'code'
  },
  {
    title: '押金($美金)',
    key: 'deposit',
    dataIndex: 'deposit',
    render(deposit: number): string {
      return deposit.toFixed(2);
    }
  },
  {
    title: '可用余额($美金)',
    key: 'balance',
    dataIndex: 'balance',
    render(balance: number): string {
      return balance.toFixed(2);
    }
  },
  {
    title: '联系方式',
    key: 'contact',
    render: (text: string, record: OverseaWarehouse): ReactElement => {
      return (
        <>
          <div>{record.company}</div>
          <div>{record.phone}</div>
          <div>{record.email}</div>
        </>
      );
    }
  },
  {
    title: '地址',
    key: 'address',
    render: (text: string, record: OverseaWarehouse): ReactElement => {
      return (
        <>
          <div>{record.address1}</div>
          {record.address2 && <div>{record.address2}</div>}
          <div>
            {record.city}, {record.state}
          </div>
          {record.zip && <div>{record.zip}</div>}
          <div>{record.country}</div>
        </>
      );
    }
  },
  {
    title: '状态',
    key: 'active',
    render: (text: string, record: OverseaWarehouse): ReactElement => {
      return <div>{record.active ? '启用' : '禁用'}</div>;
    }
  }
];

export default OverseaWarehouseColumn;
