import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AppThunk,
  HwcWarehouseFee,
  HwcWarehouseFeeData,
  HwcWarehouseFeeState,
  RootState
} from '../custom_types/redux-types';
import axios from '../shared/utils/axios.base';
import errorHandler from '../shared/components/errorHandler';
import { SERVER_ROUTES } from '../shared/utils/constants';

const initialState: HwcWarehouseFeeState = {
  hwcWarehouseFees: [],
  loading: false,
  showCreateHwcWarehouseFeeModal: false,
  showEditHwcWarehouseFeeModal: false,
  hwcWarehouseFeeModalLoading: false
};

export const hwcWarehouseFeeSlice = createSlice({
  name: 'hwcWarehouseFee',
  initialState,
  reducers: {
    setHwcWarehouseFees: (state, action: PayloadAction<HwcWarehouseFee[]>) => {
      state.hwcWarehouseFees = action.payload;
    },
    setHwcWarehouseFeeLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCreateHwcWarehouseFeeModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateHwcWarehouseFeeModal = action.payload;
    },
    setEditHwcWarehouseFeeModal: (state, action: PayloadAction<boolean>) => {
      state.showEditHwcWarehouseFeeModal = action.payload;
    },
    setHwcWarehouseFeeModalLoading: (state, action: PayloadAction<boolean>) => {
      state.hwcWarehouseFeeModalLoading = action.payload;
    }
  }
});

export const {
  setHwcWarehouseFees,
  setHwcWarehouseFeeLoading,
  setCreateHwcWarehouseFeeModal,
  setEditHwcWarehouseFeeModal,
  setHwcWarehouseFeeModalLoading
} = hwcWarehouseFeeSlice.actions;

export const fetchHwcWarehouseFeeHanlder = (): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseFeeLoading(true));
    try {
      const response = await axios.get(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSE_FEE}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setHwcWarehouseFees(response.data));
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setHwcWarehouseFeeLoading(false));
    }
  }
};

export const createHwcWarehouseFeeHandler = (data: HwcWarehouseFeeData): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseFeeLoading(true));
    try {
      await axios.post(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSE_FEE}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setCreateHwcWarehouseFeeModal(false));
      dispatch(fetchHwcWarehouseFeeHanlder());
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setHwcWarehouseFeeLoading(false));
    }
  }
};

export const updateHwcWarehouseFeeHandler = (id: string, data: HwcWarehouseFee): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseFeeModalLoading(true));
    try {
      await axios.put(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSE_FEE}/${id}`, data, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(setEditHwcWarehouseFeeModal(false));
      dispatch(fetchHwcWarehouseFeeHanlder());
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setHwcWarehouseFeeModalLoading(false));
    }
  }
};

export const deleteHwcWarehouseFeeHandler = (id: string): AppThunk => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const user = getState().currentUser.currentUser;
  if (user) {
    dispatch(setHwcWarehouseFeeModalLoading(true));
    try {
      await axios.delete(`${SERVER_ROUTES.WMS}${SERVER_ROUTES.HWC_WAREHOUSE_FEE}/${id}`, {
        headers: {
          Authorization: `${user.token_type} ${user.token}`
        }
      });
      dispatch(fetchHwcWarehouseFeeHanlder());
    } catch (error) {
      errorHandler(error as any, dispatch);
    } finally {
      dispatch(setHwcWarehouseFeeModalLoading(false));
    }
  }
};

export const selectHwcWarehouseFees = (state: RootState) => state.hwcWarehouseFees.hwcWarehouseFees;
export const selectHwcWarehouseFeeLoading = (state: RootState) => state.hwcWarehouseFees.loading;
export const selectCreateHwcWareHouseFeeModal = (state: RootState) =>
  state.hwcWarehouseFees.showCreateHwcWarehouseFeeModal;
export const selectEditHwcWareHouseFeeModal = (state: RootState) =>
  state.hwcWarehouseFees.showEditHwcWarehouseFeeModal;
export const selectHwcWarehouseFeeModalLoading = (state: RootState) =>
  state.hwcWarehouseFees.hwcWarehouseFeeModalLoading;

export default hwcWarehouseFeeSlice.reducer;
