import { Image, Space } from 'antd';
import React, { ReactElement } from 'react';
import logo from '../../../assets/logo.png';

import './Logo.css';

interface LogoProps {
  text: string | undefined;
  isLogin: boolean;
}

const Logo = ({ text, isLogin }: LogoProps): ReactElement => {
  return (
    <Space className={isLogin ? '' : 'left'} style={{ width: isLogin ? '320px' : '188px' }}>
      <Image
        width={isLogin ? '60px' : '40px'}
        height={isLogin ? '60px' : '40px'}
        src={logo}
        alt="ParcelsPro"
        preview={false}
      />
      <div className={isLogin ? 'login-logo-text' : 'logo-text'}>
        <strong>{text}</strong>
      </div>
    </Space>
  );
};

export default Logo;
