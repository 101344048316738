import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select, Table } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { SyncOutlined } from '@ant-design/icons';
import {
  fetchHwcClientWarehouseHandler,
  selectHwcClientWarehouses
} from '../../../../../redux/hwc/hwcClientWarehouseSlice';
import { HwcClientWarehouse } from '../../../../../custom_types/redux-types';
import { HwcStock } from '../../../../../custom_types/overseawarehouse/hwc-stock';
import {
  fetchHwcStocksHandler,
  selectHwcStockLoading,
  selectHwcStocks,
  syncHwcStocksHandler
} from '../../../../../redux/hwc/hwcStockSlice';

interface HwcStocksPanelProps {
  hwcClientId: string;
}

const HwcStocksPanel = ({ hwcClientId }: HwcStocksPanelProps): ReactElement => {
  const dispatch = useDispatch();
  const loading = useSelector(selectHwcStockLoading);
  const clientWarehouseStockList = useSelector(selectHwcStocks);
  const hwcClientWarehouseList = useSelector(selectHwcClientWarehouses);
  const [selectHwcClientWarehouse, setSelectHwcClientWarehouse] = useState<
    HwcClientWarehouse | undefined
  >();

  useEffect(() => {
    if (!hwcClientWarehouseList || hwcClientWarehouseList.length === 0) {
      dispatch(fetchHwcClientWarehouseHandler(hwcClientId));
    }
    if (hwcClientWarehouseList && hwcClientWarehouseList.length > 0) {
      setSelectHwcClientWarehouse(hwcClientWarehouseList[0]);
      dispatch(fetchHwcStocksHandler(hwcClientId, hwcClientWarehouseList[0].code));
    }
  }, [dispatch, hwcClientId, hwcClientWarehouseList]);

  const handleHwcClientWarehouseChange = (clientWarehouseCode: string) => {
    setSelectHwcClientWarehouse(
      hwcClientWarehouseList.find((item) => item.code === clientWarehouseCode)
    );
    dispatch(fetchHwcStocksHandler(hwcClientId, clientWarehouseCode));
  };

  const columns = [
    {
      title: 'SKU',
      key: 'ck_sku',
      dataIndex: 'ck_sku'
    },
    {
      title: '产品名称',
      key: 'pname',
      dataIndex: 'pname'
    },
    {
      title: '现有库存',
      key: 'stock',
      dataIndex: 'stock'
    }
  ];

  return (
    <div>
      <PageHeader
        title={
          selectHwcClientWarehouse && (
            <Select
              defaultValue={selectHwcClientWarehouse.code}
              style={{ width: 200 }}
              options={hwcClientWarehouseList.map((item) => ({
                label: item.name,
                value: item.code
              }))}
              onChange={handleHwcClientWarehouseChange}
            />
          )
        }
        extra={[
          <Button
            type="default"
            disabled={!selectHwcClientWarehouse}
            icon={<SyncOutlined />}
            loading={loading}
            onClick={() =>
              dispatch(fetchHwcStocksHandler(hwcClientId, selectHwcClientWarehouse!.code))
            }
          />,
          <Button
            key="1"
            type="primary"
            disabled={!selectHwcClientWarehouse}
            icon={<SyncOutlined />}
            loading={loading}
            onClick={() =>
              dispatch(syncHwcStocksHandler(hwcClientId, selectHwcClientWarehouse!.code))
            }
          >
            同步库存
          </Button>
        ]}
      />
      <Table<HwcStock>
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={clientWarehouseStockList}
        loading={loading}
      />
    </div>
  );
};

export default HwcStocksPanel;
