import React from 'react';
import { Tabs } from 'antd';
import { OverseaWarehouse } from '../../../../../custom_types/wms-page';
import HwcTouChengPanel from './HwcTouChengPanel';
import HwcTouChengCreatePanel from './HwcTouChengCreatePanel';

interface HwcTouChengPanelProps {
  hwcClient: OverseaWarehouse;
}

const HwcTouCheng = ({ hwcClient }: HwcTouChengPanelProps) => {
  return (
    <Tabs
      defaultActiveKey="1"
      tabPosition="left"
      style={{ height: 220 }}
      items={[
        {
          label: '订单列表',
          key: '1',
          children: <HwcTouChengPanel />
        },
        {
          label: '创建头程',
          key: '2',
          children: <HwcTouChengCreatePanel />
        }
      ]}
    />
  );
};

export default HwcTouCheng;
